let iv: Uint8Array | null = null;
let keyDb: CryptoKey | null = null;

// Fungsi untuk membuka IndexedDB
async function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("cs", 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore("w", { keyPath: "id" });
    };

    request.onsuccess = () => resolve(request.result as IDBDatabase);
    request.onerror = () => reject(request.error);
  });
}

// Fungsi untuk menyimpan kunci ke IndexedDB
async function saveKeyToDB(db: IDBDatabase, key: CryptoKey, iv: Uint8Array): Promise<void> {
  const exportedKey = await crypto.subtle.exportKey("jwk", key);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("w", "readwrite");
    const store = transaction.objectStore("w");
    store.put({ id: "encryptionKey", key: exportedKey, iv: Array.from(iv) });

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
}

// Fungsi untuk memuat kunci dari IndexedDB
async function loadKeyFromDB(db: IDBDatabase): Promise<{ key: CryptoKey; iv: Uint8Array }> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("w", "readonly");
    const store = transaction.objectStore("w");
    const request = store.get("encryptionKey");

    request.onsuccess = async () => {
      if (request.result) {
        const importedKey = await crypto.subtle.importKey(
          "jwk",
          request.result.key,
          { name: "AES-GCM" },
          true,
          ["encrypt", "decrypt"]
        );
        resolve({ key: importedKey, iv: new Uint8Array(request.result.iv) });
      } else {
        reject(new Error("Encryption key not found in the database."));
      }
    };

    request.onerror = () => reject(request.error);
  });
}

export default defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.provide("eO", async () => {
    const db = await openDatabase();

    try {
      const { key, iv: savedIv } = await loadKeyFromDB(db);
      keyDb = key;
      iv = savedIv;
    } catch {
      // Generate a new key and IV
      keyDb = await crypto.subtle.generateKey(
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
      );
      iv = crypto.getRandomValues(new Uint8Array(12));
      await saveKeyToDB(db, keyDb, iv);
    }
  });

  nuxtApp.provide("eE", async (data: string): Promise<string> => {
    if (!keyDb || !iv) {
      await nuxtApp.$eO()
    }

    const encodedData = new TextEncoder().encode(data);
    // @ts-ignore
    const encryptedData = await crypto.subtle.encrypt({ name: "AES-GCM", iv }, keyDb, encodedData);

    return btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
  });

  nuxtApp.provide("eD", async (encryptedData: string): Promise<string> => {
    if (!keyDb || !iv) {
      await nuxtApp.$eO()
    }

    // Convert Base64 to ArrayBuffer
    const encryptedBuffer = Uint8Array.from(atob(encryptedData), (char) => char.charCodeAt(0));
    // @ts-ignore
    const decryptedBuffer = await crypto.subtle.decrypt({ name: "AES-GCM", iv }, keyDb, encryptedBuffer);

    return new TextDecoder().decode(decryptedBuffer);
  });

  nuxtApp.provide("eC", () => {
    keyDb = null;
    iv = null;
  });
});
