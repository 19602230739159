import { CheckIcon, ChevronUpDownIcon, ChevronRightIcon, ChatBubbleLeftIcon, SquaresPlusIcon, ChatBubbleLeftRightIcon, ArrowPathRoundedSquareIcon, ChevronDownIcon, Squares2X2Icon, MapIcon, MapPinIcon, ShieldExclamationIcon, LightBulbIcon, PencilIcon, PencilSquareIcon, PlusIcon, PlusCircleIcon, XMarkIcon, XCircleIcon, SpeakerWaveIcon, SpeakerXMarkIcon, CheckBadgeIcon, CheckCircleIcon, ClockIcon, ArrowTrendingUpIcon, ChevronUpIcon, SparklesIcon, UserGroupIcon, ArrowTrendingDownIcon, CircleStackIcon, BarsArrowUpIcon, EyeIcon, VideoCameraIcon, BookmarkIcon, UserCircleIcon, HeartIcon, ClipboardIcon, CogIcon, CodeBracketIcon, NewspaperIcon, ArrowsUpDownIcon, Cog6ToothIcon, InformationCircleIcon, GiftIcon, ArrowDownIcon, ArrowUpIcon, MinusCircleIcon, MinusIcon, BookmarkSquareIcon, StarIcon, PlayIcon, PauseIcon, CakeIcon, ArrowsRightLeftIcon, Square2StackIcon, ChartBarIcon, PhotoIcon, TrashIcon, LinkIcon, ArrowUpCircleIcon, ArrowDownCircleIcon } from '@heroicons/vue/20/solid'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('TrashIcon', TrashIcon)
  nuxtApp.vueApp.component('ChartBarIcon', ChartBarIcon)
  nuxtApp.vueApp.component('PhotoIcon', PhotoIcon)
  nuxtApp.vueApp.component('LinkIcon', LinkIcon)
  nuxtApp.vueApp.component('CheckIcon', CheckIcon)
  nuxtApp.vueApp.component('Square2StackIcon', Square2StackIcon)
  nuxtApp.vueApp.component('StarIcon', StarIcon)
  nuxtApp.vueApp.component('PlayIcon', PlayIcon)
  nuxtApp.vueApp.component('PauseIcon', PauseIcon)
  nuxtApp.vueApp.component('CakeIcon', CakeIcon)
  nuxtApp.vueApp.component('NewspaperIcon', NewspaperIcon)
  nuxtApp.vueApp.component('BookmarkSquareIcon', BookmarkSquareIcon)
  nuxtApp.vueApp.component('ChevronUpDownIcon', ChevronUpDownIcon)
  nuxtApp.vueApp.component('ChevronRightIcon', ChevronRightIcon)
  nuxtApp.vueApp.component('MapIcon', MapIcon)
  nuxtApp.vueApp.component('MapPinIcon', MapPinIcon)
  nuxtApp.vueApp.component('PencilIcon', PencilIcon)
  nuxtApp.vueApp.component('ArrowsRightLeftIcon', ArrowsRightLeftIcon)
  nuxtApp.vueApp.component('PencilSquareIcon', PencilSquareIcon)
  nuxtApp.vueApp.component('ShieldExclamationIcon', ShieldExclamationIcon)
  nuxtApp.vueApp.component('LightBulbIcon', LightBulbIcon)
  nuxtApp.vueApp.component('XMarkIcon', XMarkIcon)
  nuxtApp.vueApp.component('XCircleIcon', XCircleIcon)
  nuxtApp.vueApp.component('Squares2X2Icon', Squares2X2Icon)
  nuxtApp.vueApp.component('ChevronUpIcon', ChevronUpIcon)
  nuxtApp.vueApp.component('ArrowPathRoundedSquareIcon', ArrowPathRoundedSquareIcon)
  nuxtApp.vueApp.component('ChevronDownIcon', ChevronDownIcon)
  nuxtApp.vueApp.component('ClockIcon', ClockIcon)
  nuxtApp.vueApp.component('ChatBubbleLeftIcon', ChatBubbleLeftIcon)
  nuxtApp.vueApp.component('ChatBubbleLeftRightIcon', ChatBubbleLeftRightIcon)
  nuxtApp.vueApp.component('SquaresPlusIcon', SquaresPlusIcon)
  nuxtApp.vueApp.component('PlusIcon', PlusIcon)
  nuxtApp.vueApp.component('PlusCircleIcon', PlusCircleIcon)
  nuxtApp.vueApp.component('MinusIcon', MinusIcon)
  nuxtApp.vueApp.component('MinusCircleIcon', MinusCircleIcon)

  nuxtApp.vueApp.component('SpeakerWaveIcon', SpeakerWaveIcon)
  nuxtApp.vueApp.component('SpeakerXMarkIcon', SpeakerXMarkIcon)

  nuxtApp.vueApp.component('CheckCircleIcon', CheckCircleIcon)
  nuxtApp.vueApp.component('CheckBadgeIcon', CheckBadgeIcon)
  
  nuxtApp.vueApp.component('SparklesIcon', SparklesIcon)
  nuxtApp.vueApp.component('UserGroupIcon', UserGroupIcon)
  nuxtApp.vueApp.component('ArrowTrendingUpIcon', ArrowTrendingUpIcon)
  nuxtApp.vueApp.component('ArrowTrendingDownIcon', ArrowTrendingDownIcon)
  nuxtApp.vueApp.component('CircleStackIcon', CircleStackIcon)
  nuxtApp.vueApp.component('BarsArrowUpIcon', BarsArrowUpIcon)
  nuxtApp.vueApp.component('EyeIcon', EyeIcon)
  nuxtApp.vueApp.component('VideoCameraIcon', VideoCameraIcon)
  nuxtApp.vueApp.component('BookmarkIcon', BookmarkIcon)
  nuxtApp.vueApp.component('UserCircleIcon', UserCircleIcon)
  nuxtApp.vueApp.component('HeartIcon', HeartIcon)
  nuxtApp.vueApp.component('ClipboardIcon', ClipboardIcon)
  nuxtApp.vueApp.component('CogIcon', CogIcon)
  nuxtApp.vueApp.component('Cog6ToothIcon', Cog6ToothIcon)
  nuxtApp.vueApp.component('CodeBracketIcon', CodeBracketIcon)
  nuxtApp.vueApp.component('ArrowsUpDownIcon', ArrowsUpDownIcon)
  nuxtApp.vueApp.component('InformationCircleIcon', InformationCircleIcon)
  nuxtApp.vueApp.component('GiftIcon', GiftIcon)
  nuxtApp.vueApp.component('ArrowUpIcon', ArrowUpIcon)
  nuxtApp.vueApp.component('ArrowDownIcon', ArrowDownIcon)
  nuxtApp.vueApp.component('ArrowUpCircleIcon', ArrowUpCircleIcon)
  nuxtApp.vueApp.component('ArrowDownCircleIcon', ArrowDownCircleIcon)
})