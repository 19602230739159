export default {
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (savedPosition) {
      return savedPosition
    }
    if (parseInt(to?.query?.chat) || parseInt(to?.query?.post) || parseInt(to?.query?.channel) || parseInt(to?.query?.room) || parseInt(to?.query?.contact) || parseInt(to?.query?.modal)) {
      return false; // Jangan ubah posisi scroll
    }
    return { top: 0, x: 0, y: 0 };
  }
}
