<script setup lang="ts">
const { initLoad }: any = useWarloc()
onMounted(() => {
  setTimeout(() => {
    initLoad.value = false
  }, 2500)
})
</script>

<template>
  <div v-if="initLoad" class="transition-all ease-out fixed top-0 left-0 w-screen h-screen bg-[#fafafa] dark:bg-black flex flex-col items-center justify-center text-black dark:text-white text-center z-[10000000000]">
    <div class="flex flex-col items-center justify-center w-full text-base text-black/90 dark:text-white/90">
      <img src="/warloc-push.png" class="block w-16 h-16 min-w-16 min-h-16 mb-8">
      Warloc, Unlock The Chance!
    </div>
    <div class="fixed bottom-0 left-0 w-screen flex justify-center p-4 text-xs text-black/40 dark:text-white/40">
      - DIBS GLOBAL DIGITAL -
    </div>
  </div>
</template>

<style lang="scss">
</style>