<script setup lang="ts">
// REF : https://tympanus.net/codrops/
const { $prompt }: any = useNuxtApp()
const { auth, authLoading, UsernameCheck, LocationSearch, authInterests, authRegister }: any = useAuth()

const bypassUsername = ref(true)
const hideNextTime = ref(false)
watch(() => hideNextTime.value, (v: boolean) => {
  if (v) {
    localStorage.setItem('splash-hide', '1')
  } else {
    localStorage.setItem('splash-hide', '0')
  }
})

const authPrepared = computed(() => {
  const a = auth?.value || null
  if (a) {
    if (!a.username) {
      // if (!a.username || !parseInt(a.v1) || !parseInt(a.v2) || !parseInt(a.v3) || !parseInt(a.v4)) {
      return true
    }
  }
  return false
})

const PrepareAccount: any = ref({
  name: null,
  username: null,
  gender: 'Male',
  pref_language: 'EN',
  location: null,
  v0: 0,
  v1: 0,
  v2: 0,
  v3: 0,
  v4: 0,
  v5: 0,
  v6: 0
})

const PrepareAccountInterest: any = ref([])

const CheckUsernameResult = ref(1)
const CheckUsername = async () => {
  const a = await UsernameCheck(PrepareAccount.value.username)
  if (a && a.slug) {
    CheckUsernameResult.value = 3
  } else {
    CheckUsernameResult.value = 2
  }
}

let unameCheckTimeout: any = null
const unameCheck = async () => {
  if (unameCheckTimeout) {
    clearTimeout(unameCheckTimeout)
  }
  unameCheckTimeout = setTimeout(async () => {
    await CheckUsername()
  }, 300)
}

watch(() => PrepareAccount.value.username, (v1, v0) => {
  CheckUsernameResult.value = 0
  const valid = /^[a-zA-Z0-9]+$/
  if (v1) {
    if (valid.test(v1)) {
      if (v1.length < 5 || v1.length > 15) {
        CheckUsernameResult.value = 3
      } else {
        PrepareAccount.value.username = v1
        unameCheck()
      }
    } else {
      CheckUsernameResult.value = 3
      PrepareAccount.value.username = v0
      unameCheck()
    }
  } else {
    PrepareAccount.value.username = null
  }
})

const LocationData: any = ref([])
const LocationDataSelected: any = ref(null)
const LoadLocation = async () => {
  const l = await LocationSearch(PrepareAccount.value.location || '')
  if (l && l.data && l.data.length) {
    LocationData.value = l.data || []
  }
}
let LocationSearchTimeout: any = null
watch(() => PrepareAccount.value.location, (v: string) => {
  LocationData.value = []
  if (v) {
    if (LocationSearchTimeout) {
      clearTimeout(LocationSearchTimeout)
    }
    LocationSearchTimeout = setTimeout(() => {
      LoadLocation()
    }, 300)
  }
})

const CompleteRegistration = async () => {
  const locs = LocationDataSelected.value || {}
  const payload = {
    name: PrepareAccount.value.name,
    slug: PrepareAccount.value.username,
    gender: PrepareAccount.value.gender,
    pref_language: PrepareAccount.value.pref_language,
    pref_interest: JSON.stringify(PrepareAccountInterest.value || []),
    location: PrepareAccount.value.location,
    v0: parseInt(locs.idv0) || 0,
    v1: parseInt(locs.idv1) || 0,
    v2: parseInt(locs.idv2) || 0,
    v3: parseInt(locs.idv3) || 0,
    v4: parseInt(locs.id) || 0,
    v5: 0,
    v6: 0
  }
  
  if (!payload.name) {
    await $prompt({
      title: 'Please input your name.',
      form: null,
      act: {
        reverse: true,
        cancel: 'disabled',
        ok: 'Ok'
      }
    })
    return false
  } else if (!payload.slug && !bypassUsername.value) {
    const c = await $prompt({
      title: 'Please input username',
      // description: `Please input username!`,
      form: null,
      act: {
        reverse: true,
        cancel: 'disabled',
        ok: 'Ok'
      }
    })
    if (c && c === 'ok') {
    }
  } else if (CheckUsernameResult.value === 2 || bypassUsername.value) {
    await authRegister(payload)
  } else {
    await $prompt({
      title: 'Username already taken!',
      form: null,
      act: {
        reverse: true,
        cancel: 'disabled',
        ok: 'Ok'
      }
    })
  }
}

</script>

<template>
  <div class="flex items-center justify-center fixed top-0 left-0 w-screen h-screen z-[10000000000] bg-white dark:bg-black overflow-hidden">
    <div :class="[auth ? 'pt-14' : 'pt-[22vh]']" class="w-full h-full flex items-center justify-start pb-8 flex-col text-black dark:text-white text-4xl overflow-y-auto">
      <div class="flex flex-col items-center text-5xl font-thin pb-4">
        <img src="/warloc-icon.png" class="block w-[55px]">
        <span class="block text-warloc-gradient pt-4 pb-0">Warloc</span>
        <!-- <span class="block text-xs text-primary font-medium tracking-widest">Level Up, Unlock the Chance!</span> -->
      </div>
      <div class="text-sm font-thin tracking-widest pt-0 text-black/80 dark:text-white/70 w-full text-center px-4">
        Level Up : Unlock the Chance!
      </div>
      <div v-if="authLoading" class="flex items-center flex-col justify-center pt-14 pb-8 max-w-[80%] w-[400px]">
        <svg class="animate-spin h-8 w-8 text-black/70 dark:text-white/70" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="3"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <div v-else-if="!auth" class="flex items-center flex-col justify-center border-t-[1px] border-gray-100/20 dark:border-gray-100/20 mt-20 pt-4 pb-8 max-w-[80%] w-[400px]">
        <div class="text-center tracking-widest w-full font-thin font-sans text-sm p-4">
          - Enter Warloc -
          <div class="text-xs pt-2 pb-4 text-black/80 dark:text-white/70">
            By clicking the login authentication button below, you have agreed to our <a href="/privacy-policy" target="_blank" class="underline italic">privacy policy</a>, as well as our <a href="/terms-and-conditions" target="_blank" class="underline italic">terms and conditions</a>.
          </div>
        </div>
        <Auth class="rounded-2xl overflow-hidden pb-4" />
      </div>
      <div v-else-if="authPrepared" class="text-base font-thin font-sans flex items-center flex-col justify-center border-t-[1px] border-gray-300 dark:border-gray-400 mt-10 pt-6 pb-8 max-w-[95%] w-[475px]">
        <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="w-full text-lg py-2">
            Prepare your personal account :
          </div>
        </div>
        <!-- <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal pb-1">
            Username
          </div>
          <div class="flex w-full items-center text-white dark:text-white font-medium">
            @
            <input v-model="PrepareAccount.username" spellcheck="false" type="text" class="pl-1 block grow outline-none ml-[2px] bg-transparent font-medium placeholder:font-thin text-white placeholder:text-gray-400" placeholder="Input username">
            <span v-if="PrepareAccount.username">
              <span v-if="CheckUsernameResult === 1" />
              <CheckCircleIcon v-else-if="CheckUsernameResult === 2" class="w-5 fill-blue-500" />
              <svg v-else-if="CheckUsernameResult === 3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 fill-none stroke-red-500">
                <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <svg v-else class="animate-spin h-4 w-4 text-gray-800 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
          </div>
        </div> -->
        <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal">
            Name
          </div>
          <div class="flex w-full items-center pt-1">
            <input v-model="PrepareAccount.name" spellcheck="false" type="text" class="block bg-transparent w-full outline-none cursor-pointer" placeholder="Input yout name">
          </div>
        </div>
        <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal">
            Gender
          </div>
          <div class="flex w-full items-center pt-1">
            <select v-model="PrepareAccount.gender" class="block bg-transparent w-full outline-none cursor-pointer">
              <option value="Male" class="bg-white dark:bg-gray-800">Male</option>
              <option value="Female" class="bg-white dark:bg-gray-800">Female</option>
            </select>
          </div>
        </div>
        <div v-if="false" class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal">
            Interests
          </div>
          <div class="flex flex-wrap w-full items-center pt-2">
            <span
              v-for="(PAIS, iPAIS) in authInterests"
              :key="'interest-' + iPAIS"
              :class="[PrepareAccountInterest.includes(PAIS) ? 'border-blue-500 bg-blue-500 text-white' : 'border-gray-400']"
              class="px-4 py-[4px] rounded-full border text-sm mr-2 my-1 cursor-pointer"
              @click.prevent="PrepareAccountInterest.includes(PAIS) ? PrepareAccountInterest.splice(PrepareAccountInterest.findIndex((x: any) => x === PAIS), 1) : PrepareAccountInterest.push(PAIS)"
            >
              {{ PAIS }}
            </span>
          </div>
        </div>
        <!-- <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal">
            Sub-District Location (Desa/Kelurahan)
          </div>
          <div class="flex w-full items-center pt-1 relative">
            <input v-model="PrepareAccount.location" spellcheck="false" type="text" class="block bg-transparent w-full outline-none ml-[2px] text-white placeholder:text-gray-400" placeholder="Search location" @click="PrepareAccount.location = null, LocationDataSelected = null">
            <div
              v-if="!(LocationDataSelected && PrepareAccount.location === LocationDataSelected.name_full) && LocationData.length"
              class="absolute left-0 top-[34px] w-full z-10 bg-black px-4 py-2 max-h-[200px] overflow-y-auto rounded border rounded-b-lg shadow-lg cursor-pointer"
            >
              <div v-for="(ll, iLl) in LocationData" :key="'location-kelurahan' + iLl" class="w-full py-1 text-sm text-white" @click="LocationDataSelected = ll, PrepareAccount.location = ll.name_full">
                {{ ll.name_full }}
              </div>
            </div>
          </div>
        </div> -->
        <div class="flex flex-col w-[325px] md:w-[300px] max-w-[90%] py-2">
          <div class="text-xs font-normal">
            Language
          </div>
          <div class="flex w-full items-center pt-1">
            <select v-model="PrepareAccount.pref_language" class="block bg-transparent w-full outline-none cursor-pointer text-white placeholder:text-gray-400">
              <option value="EN" class="bg-black text-gray-200">English</option>
              <option value="ID" class="bg-black text-gray-200">Bahasa Indonesia</option>
            </select>
          </div>
        </div>
        <div class="flex justify-center items-center flex-col w-[325px] md:w-[300px] max-w-[90%] pt-8">
          <div class="text-xs font-bold text-left w-full pb-8">
            By continuing the registration process, you have understood and agreed to our <NuxtLink to="/terms-and-conditions" target="_blank" class="text-blue-400">terms conditions</NuxtLink> and <NuxtLink to="/privacy-policy" target="_blank" class="text-blue-400">privacy policy</NuxtLink>.
          </div>
          <span class="px-6 py-2 text-sm font-medium rounded-full bg-warloc-profile text-white dark:bg-white dark:text-black cursor-pointer" @click="CompleteRegistration()">
            Complete Registration
          </span>
          <span class="mt-8 text-sm cursor-pointer text-red-400" @click.prevent="$emit('logout')">
            Logout
          </span>
        </div>
      </div>
    </div>
    <span class="nm-1 fixed bottom-20 left-20 -rotate-3 w-40 h-40 rounded-full -z-[1]" />
    <span class="nm-2 fixed -top-10 -right-20 rotate-12 w-52 h-52 rounded-full -z-[1]" />
    <span class="nm-3 fixed bottom-[37vh] left-[15vw] -rotate-6 w-72 h-72 rounded-full -z-[1]" />
  </div>
</template>

<style lang="scss">
</style>