export const useNotification = () => {
  const notificationData = useState<any>('notificationData', () => {
    return {
      loading: null,
      data: []
    }
  })
  const NotificationGet = async (page: number = 0, limit: number = 25) => {
    const req = await useApiGet(`/notification?page=${page}&limit=${limit}`)
    if (req && req.status && req.data) {
      return req.data || []
    }
    return []
  }

  const notificationReadLast = useState<any>('notificationReadLast', () => null)
  const NotificationRead = async () => {
    const req = await useApiGet('/notification/read')
    if (req && req.status && req.data) {
      notificationReadLast.value = req.data || null
    }
    return []
  }
  const loadNotifications = async (isLoadMore = false, page: number = 0, limit: number = 25) => {
    const d = await NotificationGet(page, limit)
    if (d && d.data && d.data.length) {
      if (isLoadMore) {
        notificationData.value.data.concat(d.data || [])
        notificationReadLast.value = d.lastRead || null
      } else {
        notificationData.value.data = d.data
        notificationReadLast.value = d.lastRead || null
      }
    }
    notificationData.value.loading = false
  }

  const notificationUnread = computed(() => {
    if (notificationReadLast.value) {
      return (notificationData.value.data || []).filter((r: any) => {
        const dtt = new Date(r.updated)
        const dttt = notificationReadLast.value ? (new Date(notificationReadLast.value)) : null
        if (!dttt || (dtt > dttt)) {
          return true
        }
        return false
      }).length
    }
    return 0
  })

  return {
    notificationUnread,
    notificationData,
    loadNotifications,
    NotificationRead,
    notificationReadLast
  }
}
