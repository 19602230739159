import Dexie, { type EntityTable } from 'dexie';

const db: any = new Dexie('wrlcencmsg')
export default defineNuxtPlugin(async (nuxtApp: any) => {
  const { messages }: any = useChat()
  const { auth }: any = useAuth()

  const enc = async (s: any) => {
    if (!s) {
      return s
    }
    if (typeof s === 'string') {
      return await nuxtApp.$eE(s)
    } else {
      try {
        const ss = JSON.stringify(s)
        return await nuxtApp.$eE(ss)
      } catch (error) {
        console.log('err', error)
        return s
      }
    }
  }
  const dec = async (s: any) => {
    if (!s) {
      return null
    }
    if (typeof s === 'string') {
      const sss = await nuxtApp.$eD(s)
      try {
        const ss = JSON.parse(sss)
        return ss
      } catch (error) {
        console.log('err', error)
        return s
      }
    } else {
      return s
    }
  }
  
  // const c = await $eE('xyz')
  // console.log(c)
  // const d = await $eD(c)
  // console.log(d)

  nuxtApp.provide('dbm', {
    connect: async (version: number) => {
      try {
        const dbs = {
          r: '++id, d, dt, dtread, a', // d: data encrypted, a: action (D/null), dt is last date, dtn is last message name, dtm is last message
          m: '++id, r, d, dt, a, [r+dt]', // r: roomid, d: data encrypted, a: action (D/null) D is DELETED, dt is Date
          e: 'e'
        }
        return {
          status: true,
          data: db.version(version || 1).stores(dbs).upgrade((tx: any) => {
            // Will only be executed if a version below 2 was installed.
            return tx.table("m").toCollection().modify((d: any) => {
              d.a = ''
              return d
            })
          })
        }
      } catch {
        return {
          status: false,
          data: null
        }
      }
    },
    close: async () => {
      db.close()
    },
    get: async (key: string) => {
      return await db[key]
        .toArray()
    },
    clear: async () => {
      return await db.delete().then(() => {
        console.log('Clear DB Success, browser will reload!')
      }).catch((err: any) => {
        console.warn('Clear Offline DB Error!')
      }).finally(() => {
        location.reload()
      })
    },
    getMessagesInRoom: async (chatroomid: string, page: number = 0, limit: number = 50) => {
      try {
        page = parseInt(page + '') || 0
        const offset = page * limit
        const result = await db['m']
          .where('[r+dt]')
          .between(
            [chatroomid, Dexie.minKey],
            [chatroomid, Dexie.maxKey])
          .reverse()
          .offset(offset)
          .limit(limit)
          .toArray()

        for (let d = 0; d < result.length; d++) {
          await (new Promise(async (resolve) => {
            if (result[d].d) {
              result[d].d = await dec(result[d].d)
            }
            resolve(true)
          }))
        }
        return result
        // return result.sort((a: any, b: any) => (a.dt > b.dt) ? 1 : ((b.dt > a.dt) ? -1 : 0))
      } catch (error) {
        console.log('err: ', error)
        return []
      }
    },
    LastMessageInRoom: async (roomid: any) => {
      // LAST LOAD MESSAGE ID
      try {
        const result = await db['m']
          .where('[r+dt]')
          .between(
            [roomid, Dexie.minKey],
            [roomid, Dexie.maxKey])
          .reverse()
          .limit(1)
          .offset(0)
          .toArray()
        for (let d = 0; d < result.length; d++) {
          await (new Promise(async (resolve) => {
            if (result[d].d) {
              result[d].d = await dec(result[d].d)
            }
            resolve(true)
          }))
        }
        return result.length ? result[0] : { dt: new Date('2000-01-01') }
      } catch {
        return { dt: new Date('2000-01-01') }
      }
    },
    save: async (key: string, data: any) => {
      return new Promise(async (resolve) => {
        if (key === 'm') {
          data.d = await enc(data.d)
        }
        try {
          const x = await db[key].put(data)
          resolve({
            status: true,
            data: x
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    saveBulk: async (key: string, data: any) => {
      if (key === 'm') {
        for (let d = 0; d < data.length; d++) {
          await (new Promise(async (resolve) => {
            data[d].d = await enc(data[d].d)
            resolve(true)
          }))
        }
      }
      return new Promise(async (resolve) => {
        try {
          resolve({
            status: true,
            data: await db[key].bulkPut(data)
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    update: async (key: string, data: any) => {
      return new Promise(async (resolve) => {
        try {
          const x = await db[key].update(data.id, data)
          resolve({
            status: true,
            data: x
          })
        } catch {
          resolve({
            status: false,
            data: null
          })
        }
      })
    },
    updateRoomLastRead: async (data: any) => {
      if (data.chatroom_id && data.chatroom_member_last_read) {
        const oldRoom = await db.r.get(data.chatroom_id)
        if (oldRoom && oldRoom.d) {
          oldRoom.d.chatroom_member_last_read = data.chatroom_member_last_read
          oldRoom.dtread = new Date(data.chatroom_member_last_read)
          await db.r.update(oldRoom.id, oldRoom)
        }
      }
    },
    updateRoomWithLatestMessage: async (newDataMessage: any) => {
      const oldRoom = await db.r.get(newDataMessage.chatroom_id)
      if (oldRoom && oldRoom.d) {
        oldRoom.d.last_message = newDataMessage.message
        oldRoom.d.last_message_created = newDataMessage.created ? new Date(newDataMessage.created) : null
        oldRoom.d.last_message_user_id = newDataMessage.account_id
        oldRoom.d.last_message_user_image = newDataMessage.account_img
        oldRoom.d.last_message_user_name = newDataMessage.account_name
        await db.r.update(oldRoom.id, oldRoom)
        if (newDataMessage.created) {
          localStorage.setItem('s', newDataMessage.created)
        }
      }
    },
    delete: async (data: any, isRoom: boolean) => {
      // data => { id: ID ROOM/MESSAGE }
      // await db[isRoom ? 'r' : 'm'].update(data.id, { a: 'D' }).then((updated: any) => {
      //   const m = nuxtApp.$JsonRaw(messages.value)
      //   if (m && m.length && m[data.r]) {
      //     console.log(2)
      //   }
      // })
      if (isRoom) {
        // data => { id: ID ROOM }
      } else {
        // data => { id: ID MESSAGE, r: chatroom_id }
        const roomid = data.r
        if (data.id && roomid) {
          const oldMessage = await db.m.get(data.id)
          if (oldMessage && oldMessage.id && oldMessage.r) {
            // oldMessage.a = 'D'
            // oldMessage.d.message_a_type = 'DELETED'
            // await db.m.update(oldMessage.id, oldMessage)
            await db.m.delete(oldMessage.id)
          }
        }
      }
    },
    searchMesage: async (
      chatroomid: string,
      searchText: string = '',
      beforeDate = null,
      sortField = "dt",
      sortAsc = true,
      limit = 30
    ) => {
      try {
        // Query dasar
        let query = db.m.where('r').equalsIgnoreCase(chatroomid).orderBy(sortField).reverse(!sortAsc);

        // Filter berdasarkan tanggal jika diberikan
        if (beforeDate) {
          query = query.filter((item: any) => item.dt < beforeDate);
        }
    
        // Filter berdasarkan teks jika diberikan
        if (searchText) {
          const searchLower = searchText.toLowerCase(); // Case-insensitive
          query = query.filter((item: any) => 
            (item?.d?.message || '').toLowerCase().includes(searchLower)
          );
        }
    
        // Sorting dan limit
        const data = await query
          .limit(limit) // Batas jumlah data
          .toArray(); // Mengambil data dalam bentuk array
    
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    }
  })
})
