<script setup lang="ts">
const { auth, SubscribePush }: any = useAuth()
const { setWindowSize }: any = useWindowSize()

const urlBase64ToUint8Array = (base64String: any) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const subscribeUserToPush = async (registration: ServiceWorkerRegistration) => {
  const publicKey = 'BMXRN4BOlw6v8kmvxUBujg8FMwWYEajroGyL1wxeioZZrAUe2AkS6wspvNjS8R1zgFyFBSC42XlmxNfw-1Dw0PY';

  try {
    // Cek apakah sudah ada subscription aktif
    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      // Jika sudah ada, perbarui ke server agar selalu up-to-date
      await SubscribePush(existingSubscription);
    } else {
      // Jika belum ada subscription, buat baru
      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey),
      });
      // Kirim subscription baru ke server backend
      await SubscribePush(newSubscription);
    }
  } catch (error) {
    console.error('Error during push subscription', error);
  }
}

// Fungsi untuk memeriksa status izin push notification
function checkPushNotificationPermission() {
  const permissionStatus = Notification.permission; // default, granted, or denied
  if (permissionStatus === 'granted') {
    console.log('Push notification permission granted.');
  } else if (permissionStatus === 'denied') {
    console.log('Push notification permission denied.');
  } else {
    console.log('Push notification permission is in default state.');
  }
}

// Fungsi untuk meminta izin notifikasi jika diperlukan
async function requestPushNotificationPermission() {
  if (Notification.permission === 'default') {
    try {
      const result = await Notification.requestPermission();
      if (result === 'granted') {
        console.log('Push notification permission granted.');
      } else {
        console.log('Push notification permission not granted.');
      }
    } catch (error) {
      console.error('Error requesting push notification permission:', error);
    }
  }
}

const InitSubscribeFirstLoad = ref(true)
const InitSubscribe = async () => {
  if (navigator.serviceWorker) {
    if ('PushManager' in window) {
      // Memeriksa dan meminta izin jika perlu

      if (InitSubscribeFirstLoad.value) {
        checkPushNotificationPermission();
        await requestPushNotificationPermission();
        navigator.serviceWorker.ready.then(async (registration: any) => {
          return await subscribeUserToPush(registration);
        });
        InitSubscribeFirstLoad.value = false
      }
    }
  }
}

watch(() => auth.value, (v: any) => {
  if (v) {
    InitSubscribe()
  }
}, { immediate: true, deep: true })

onMounted(() => {
  window.addEventListener('resize', () => {
    setWindowSize({
      width: screen.width,
      height: screen.height
    })
  }, true)

  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload(); // Reload halaman ketika Service Worker baru aktif
    });

    navigator.serviceWorker.register('/sw.js').then((reg) => {
      reg.onupdatefound = () => {
        const newWorker: any = reg.installing;
        newWorker.onstatechange = () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            // Notifikasi ke user bahwa ada versi baru
            alert('A new version of the app is available. The page will reload to update.');
            newWorker.postMessage({ type: 'SKIP_WAITING' }); // Kirim pesan ke service worker untuk skip waiting
          }
        }
      }
    })
  }
})

document.addEventListener("visibilitychange", (e: any) => {
  document.documentElement.removeAttribute('class')
  const theme: any = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('class', theme === 'dark' ? 'dark' : 'light')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('class', 'dark')
  } else {
    document.documentElement.setAttribute('class', 'light')
  }
}, false)
</script>

<template>
  <NuxtLayout :name="auth ? 'default' : 'lp'" />
</template>

<style lang="scss">
html {
  background-color: #ffffff!important;
  >body {
    background-color: #ffffff!important;
  }
  &.dark {
    background-color: #000000!important;
    >body {
      background-color: #ffffff!important;
    }
  }
}
</style>