<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <defs>
      <linearGradient id="boxGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color:#6A91FE"/>
        <stop offset="100%" style="stop-color:#FF6D6D"/>
      </linearGradient>
      <mask id="boxMask">
        <rect x="10" y="10" width="40" height="40" fill="white" rx="5"/>
        <rect x="60" y="10" width="40" height="40" fill="white" rx="5"/>
        <rect x="110" y="10" width="40" height="40" fill="white" rx="5"/>
        <rect x="160" y="10" width="40" height="40" fill="white" rx="5"/>
        
        <rect x="10" y="60" width="40" height="40" fill="white" rx="5"/>
        <rect x="60" y="60" width="40" height="40" fill="white" rx="5"/>
        <rect x="110" y="60" width="40" height="40" fill="white" rx="5"/>
        <rect x="160" y="60" width="40" height="40" fill="white" rx="5"/>
        
        <rect x="10" y="110" width="40" height="40" fill="white" rx="5"/>
        <rect x="60" y="110" width="40" height="40" fill="white" rx="5"/>
        <rect x="110" y="110" width="40" height="40" fill="white" rx="5"/>
        <rect x="160" y="110" width="40" height="40" fill="white" rx="5"/>
        
        <rect x="10" y="160" width="40" height="40" fill="white" rx="5"/>
        <rect x="60" y="160" width="40" height="40" fill="white" rx="5"/>
        <rect x="110" y="160" width="40" height="40" fill="white" rx="5"/>
        <rect x="160" y="160" width="40" height="40" fill="white" rx="5"/>
      </mask>
    </defs>
    
    <!-- Single rectangle with gradient that covers the entire area -->
    <rect x="0" y="0" width="200" height="200" fill="url(#boxGradient)" mask="url(#boxMask)"/>
  </svg>
</template>