export const useCoin = () => {
  const coin = useState<any>('coin', () => 0)
  const coinLevel = useState<any>('coinLevel', () => {
    return {}
  })
  const setCoin = (t: any) => {
    coin.value = parseFloat(t.coin) || 0
    coinLevel.value = t.lvl || {}
  }

  const cake = useState<any>('cake', () => 0)
  const cakeActive = useState<any>('cakeActive', () => 0)
  const cakeLevel = useState<any>('cakeLevel', () => {
    return {}
  })
  const setCake = (t: any) => {
    cake.value = parseFloat(t.cake) || 0
    cakeLevel.value = t.lvl || {}
  }

  const coinModal = useState<any>('coinModal', () => {
    return {
      show: false
    }
  })
  const setCoinModal = (t?: any) => {
    coinModal.value.show = t?.show ? true : false
  }

  const coinBuy = async (coin: number) => {
    const balance = await useApiPost(`/trx/coin/buy`, { coin })
    coinReload()
    coinHistory()
  }
  
  const coinReload = async () => {
    const balance = await useApiGet(`/trx/coin`)
    setCoin({ coin: 0, lvl: {} })
    if (balance && balance.status && balance.data && balance.data.response) {
      setCoin({ coin: balance.data.coin, lvl: balance.data.lvl })
    }
  }
  
  const coinHistoryData = useState<any>('coinHistoryData', () => {
    return {
      loading: false,
      total: 0,
      data: []
    }
  })
  const coinHistory = async () => {
    coinHistoryData.value.loading = true
    const ch = await useApiGet(`/trx/coin/history`)
    if (ch && ch.status && ch.data) {
      coinHistoryData.value.data = ch.data || []
    }
    coinHistoryData.value.loading = false
    return ch
  }
  
  const cakeReload = async () => {
    const balance = await useApiGet(`/trx/cake`)
    setCake({ cake: 0, lvl: {} })
    if (balance && balance.status && balance.data && balance.data.response) {
      setCake({ cake: balance.data.cake, lvl: balance.data.lvl })
      cakeActive.value = parseInt(balance.data.cakeActive) || 0
    }
  }
  
  const cakeHistory = async () => {
    return await useApiGet(`/trx/cake/history`)
  }
  
  return {
    coin,
    coinLevel,
    setCoinModal,
    coinModal,
    coinReload,
    coinBuy,
    coinHistory,
    coinHistoryData,

    cake,
    cakeActive,
    cakeLevel,
    cakeReload,
    cakeHistory
  }
}
