import { default as index0wZrjTLn2DMeta } from "/opt/buildhome/repo/pages/[username]/index.vue?macro=true";
import { default as _91chatid_933x01XJlHQOMeta } from "/opt/buildhome/repo/pages/chat/[chatid].vue?macro=true";
import { default as chatcdcDIyUuf8Meta } from "/opt/buildhome/repo/pages/chat.vue?macro=true";
import { default as explorerYI361piByMeta } from "/opt/buildhome/repo/pages/explore.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as mapsGFhSPoh41JMeta } from "/opt/buildhome/repo/pages/maps.vue?macro=true";
import { default as notificationqzXbaWb8jkMeta } from "/opt/buildhome/repo/pages/notification.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as scheduleVfpgDdTWXkMeta } from "/opt/buildhome/repo/pages/schedule.vue?macro=true";
import { default as settingy5EQf90uLhMeta } from "/opt/buildhome/repo/pages/setting.vue?macro=true";
import { default as _91thread_93UCTaSaNGHVMeta } from "/opt/buildhome/repo/pages/snapshoot/[thread].vue?macro=true";
import { default as index729C4SaBzhMeta } from "/opt/buildhome/repo/pages/snapshoot/index.vue?macro=true";
import { default as terms_45and_45conditionsPFDsB8eCTMMeta } from "/opt/buildhome/repo/pages/terms-and-conditions.vue?macro=true";
import { default as _91thread_93R1ggzvRtTwMeta } from "/opt/buildhome/repo/pages/thread/[thread].vue?macro=true";
import { default as indexutuYSpibFlMeta } from "/opt/buildhome/repo/pages/thread/index.vue?macro=true";
import { default as _91id_932nt7D8QWCYMeta } from "/opt/buildhome/repo/pages/timeline/[id].vue?macro=true";
import { default as indexYrCOLbJkHTMeta } from "/opt/buildhome/repo/pages/timeline/index.vue?macro=true";
import { default as trendRd2guf4ng4Meta } from "/opt/buildhome/repo/pages/trend.vue?macro=true";
import { default as _91featureid_93fB1W9xFVXgMeta } from "/opt/buildhome/repo/pages/w/[channelid]/[nav]/[featureid].vue?macro=true";
import { default as indexG06pDcjxDoMeta } from "/opt/buildhome/repo/pages/w/[channelid]/[nav]/index.vue?macro=true";
import { default as indexZ3eQm2bGmgMeta } from "/opt/buildhome/repo/pages/w/[channelid]/index.vue?macro=true";
import { default as indexM4uGukhGiGMeta } from "/opt/buildhome/repo/pages/w/index.vue?macro=true";
export default [
  {
    name: index0wZrjTLn2DMeta?.name ?? "username",
    path: index0wZrjTLn2DMeta?.path ?? "/:username()",
    meta: index0wZrjTLn2DMeta || {},
    alias: index0wZrjTLn2DMeta?.alias || [],
    redirect: index0wZrjTLn2DMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/[username]/index.vue").then(m => m.default || m)
  },
  {
    name: chatcdcDIyUuf8Meta?.name ?? "chat",
    path: chatcdcDIyUuf8Meta?.path ?? "/chat",
    meta: chatcdcDIyUuf8Meta || {},
    alias: chatcdcDIyUuf8Meta?.alias || [],
    redirect: chatcdcDIyUuf8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/chat.vue").then(m => m.default || m),
    children: [
  {
    name: _91chatid_933x01XJlHQOMeta?.name ?? "chat-chatid",
    path: _91chatid_933x01XJlHQOMeta?.path ?? ":chatid()",
    meta: _91chatid_933x01XJlHQOMeta || {},
    alias: _91chatid_933x01XJlHQOMeta?.alias || [],
    redirect: _91chatid_933x01XJlHQOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/chat/[chatid].vue").then(m => m.default || m)
  }
]
  },
  {
    name: explorerYI361piByMeta?.name ?? "explore",
    path: explorerYI361piByMeta?.path ?? "/explore",
    meta: explorerYI361piByMeta || {},
    alias: explorerYI361piByMeta?.alias || [],
    redirect: explorerYI361piByMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mapsGFhSPoh41JMeta?.name ?? "maps",
    path: mapsGFhSPoh41JMeta?.path ?? "/maps",
    meta: mapsGFhSPoh41JMeta || {},
    alias: mapsGFhSPoh41JMeta?.alias || [],
    redirect: mapsGFhSPoh41JMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/maps.vue").then(m => m.default || m)
  },
  {
    name: notificationqzXbaWb8jkMeta?.name ?? "notification",
    path: notificationqzXbaWb8jkMeta?.path ?? "/notification",
    meta: notificationqzXbaWb8jkMeta || {},
    alias: notificationqzXbaWb8jkMeta?.alias || [],
    redirect: notificationqzXbaWb8jkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/notification.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policypnUDKF1OY5Meta?.name ?? "privacy-policy",
    path: privacy_45policypnUDKF1OY5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policypnUDKF1OY5Meta || {},
    alias: privacy_45policypnUDKF1OY5Meta?.alias || [],
    redirect: privacy_45policypnUDKF1OY5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: scheduleVfpgDdTWXkMeta?.name ?? "schedule",
    path: scheduleVfpgDdTWXkMeta?.path ?? "/schedule",
    meta: scheduleVfpgDdTWXkMeta || {},
    alias: scheduleVfpgDdTWXkMeta?.alias || [],
    redirect: scheduleVfpgDdTWXkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: settingy5EQf90uLhMeta?.name ?? "setting",
    path: settingy5EQf90uLhMeta?.path ?? "/setting",
    meta: settingy5EQf90uLhMeta || {},
    alias: settingy5EQf90uLhMeta?.alias || [],
    redirect: settingy5EQf90uLhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: _91thread_93UCTaSaNGHVMeta?.name ?? "snapshoot-thread",
    path: _91thread_93UCTaSaNGHVMeta?.path ?? "/snapshoot/:thread()",
    meta: _91thread_93UCTaSaNGHVMeta || {},
    alias: _91thread_93UCTaSaNGHVMeta?.alias || [],
    redirect: _91thread_93UCTaSaNGHVMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/snapshoot/[thread].vue").then(m => m.default || m)
  },
  {
    name: index729C4SaBzhMeta?.name ?? "snapshoot",
    path: index729C4SaBzhMeta?.path ?? "/snapshoot",
    meta: index729C4SaBzhMeta || {},
    alias: index729C4SaBzhMeta?.alias || [],
    redirect: index729C4SaBzhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/snapshoot/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsPFDsB8eCTMMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsPFDsB8eCTMMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsPFDsB8eCTMMeta || {},
    alias: terms_45and_45conditionsPFDsB8eCTMMeta?.alias || [],
    redirect: terms_45and_45conditionsPFDsB8eCTMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91thread_93R1ggzvRtTwMeta?.name ?? "thread-thread",
    path: _91thread_93R1ggzvRtTwMeta?.path ?? "/thread/:thread()",
    meta: _91thread_93R1ggzvRtTwMeta || {},
    alias: _91thread_93R1ggzvRtTwMeta?.alias || [],
    redirect: _91thread_93R1ggzvRtTwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/thread/[thread].vue").then(m => m.default || m)
  },
  {
    name: indexutuYSpibFlMeta?.name ?? "thread",
    path: indexutuYSpibFlMeta?.path ?? "/thread",
    meta: indexutuYSpibFlMeta || {},
    alias: indexutuYSpibFlMeta?.alias || [],
    redirect: indexutuYSpibFlMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/thread/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932nt7D8QWCYMeta?.name ?? "timeline-id",
    path: _91id_932nt7D8QWCYMeta?.path ?? "/timeline/:id()",
    meta: _91id_932nt7D8QWCYMeta || {},
    alias: _91id_932nt7D8QWCYMeta?.alias || [],
    redirect: _91id_932nt7D8QWCYMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/timeline/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYrCOLbJkHTMeta?.name ?? "timeline",
    path: indexYrCOLbJkHTMeta?.path ?? "/timeline",
    meta: indexYrCOLbJkHTMeta || {},
    alias: indexYrCOLbJkHTMeta?.alias || [],
    redirect: indexYrCOLbJkHTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: trendRd2guf4ng4Meta?.name ?? "trend",
    path: trendRd2guf4ng4Meta?.path ?? "/trend",
    meta: trendRd2guf4ng4Meta || {},
    alias: trendRd2guf4ng4Meta?.alias || [],
    redirect: trendRd2guf4ng4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/trend.vue").then(m => m.default || m)
  },
  {
    name: _91featureid_93fB1W9xFVXgMeta?.name ?? "w-channelid-nav-featureid",
    path: _91featureid_93fB1W9xFVXgMeta?.path ?? "/w/:channelid()/:nav()/:featureid()",
    meta: _91featureid_93fB1W9xFVXgMeta || {},
    alias: _91featureid_93fB1W9xFVXgMeta?.alias || [],
    redirect: _91featureid_93fB1W9xFVXgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/w/[channelid]/[nav]/[featureid].vue").then(m => m.default || m)
  },
  {
    name: indexG06pDcjxDoMeta?.name ?? "w-channelid-nav",
    path: indexG06pDcjxDoMeta?.path ?? "/w/:channelid()/:nav()",
    meta: indexG06pDcjxDoMeta || {},
    alias: indexG06pDcjxDoMeta?.alias || [],
    redirect: indexG06pDcjxDoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/w/[channelid]/[nav]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ3eQm2bGmgMeta?.name ?? "w-channelid",
    path: indexZ3eQm2bGmgMeta?.path ?? "/w/:channelid()",
    meta: indexZ3eQm2bGmgMeta || {},
    alias: indexZ3eQm2bGmgMeta?.alias || [],
    redirect: indexZ3eQm2bGmgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/w/[channelid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4uGukhGiGMeta?.name ?? "w",
    path: indexM4uGukhGiGMeta?.path ?? "/w",
    meta: indexM4uGukhGiGMeta || {},
    alias: indexM4uGukhGiGMeta?.alias || [],
    redirect: indexM4uGukhGiGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/w/index.vue").then(m => m.default || m)
  }
]