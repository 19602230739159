export const useAccount = () => {
  const exploreAccount = async (q: string) => {
    const req = await useApiGet(`/account/search?q=${q}`)
    if (req && req.status && req.data) {
      return (req.data.data || []).map((r: any) => {
        r.username_at = `@${r.username}`
        return r
      })
    }
  }

  const authAccess = useState<any>('authAccess', () => null)
  const authAccessRequest = async () => {
    const access = await useApiGet(`/account/access`)
    const ad = access?.data || []
    if (access && access.status && access.data) {
      const d: any = []
      for (let x = 0; x < ad.length; x++) {
        if (!d.find((r: any) => r.channel === ad[x].channel)) {
          d.push(ad[x])
        }
      }
      authAccess.value = d
    }
  }

  const AccountFollow = async (id: string) => {
    return await useApiPost(`/account/${id}/follow`)
  }
  const AccountUnfollow = async (id: string) => {
    return await useApiDelete(`/account/${id}/unfollow`)
  }

  
  return {
    exploreAccount,
    authAccess,
    authAccessRequest,
    AccountFollow,
    AccountUnfollow
  }
}
