import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/opt/buildhome/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_m51yvfcbqo from "/opt/buildhome/repo/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import leaflet_runtime_mlkDTJ7gZI from "/opt/buildhome/repo/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/opt/buildhome/repo/plugins/axios.ts";
import chat_EH14ieAFAN from "/opt/buildhome/repo/plugins/chat.ts";
import db_dexie_client_6KaAvNxdjF from "/opt/buildhome/repo/plugins/db_dexie.client.ts";
import directives_client_23ySUe5jkO from "/opt/buildhome/repo/plugins/directives.client.ts";
import enc_5NYhecSVOc from "/opt/buildhome/repo/plugins/enc.ts";
import global_2XP5sjpyNs from "/opt/buildhome/repo/plugins/global.ts";
import google_auth_client_0OZXXzlBev from "/opt/buildhome/repo/plugins/google-auth.client.ts";
import headlessui_1kHUcAGdY8 from "/opt/buildhome/repo/plugins/headlessui.ts";
import icons_sncVX745v6 from "/opt/buildhome/repo/plugins/icons.ts";
import image_modify_client_BGKuQ98xNe from "/opt/buildhome/repo/plugins/image-modify.client.ts";
import leaflet_client_7dEOJA4hbk from "/opt/buildhome/repo/plugins/leaflet.client.ts";
import markdown_nljPFoZ1ix from "/opt/buildhome/repo/plugins/markdown.ts";
import vue_flow_wMgjIQWsmh from "/opt/buildhome/repo/plugins/vue-flow.ts";
import vuetify_7h9QAQEssH from "/opt/buildhome/repo/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_m51yvfcbqo,
  leaflet_runtime_mlkDTJ7gZI,
  chunk_reload_client_UciE0i6zes,
  axios_QMFgzss1s4,
  chat_EH14ieAFAN,
  db_dexie_client_6KaAvNxdjF,
  directives_client_23ySUe5jkO,
  enc_5NYhecSVOc,
  global_2XP5sjpyNs,
  google_auth_client_0OZXXzlBev,
  headlessui_1kHUcAGdY8,
  icons_sncVX745v6,
  image_modify_client_BGKuQ98xNe,
  leaflet_client_7dEOJA4hbk,
  markdown_nljPFoZ1ix,
  vue_flow_wMgjIQWsmh,
  vuetify_7h9QAQEssH
]