export default defineNuxtPlugin((nuxtApp) => {
  const JsonParse = (str: string) => {
    const isObjOrArr = (str || '').substring(0, 1) === '[' || (str || '').substring(0, 1) === '{'
    if (!isObjOrArr) {
      return []
    }
    const isArray = (str || '').substring(0, 1) === '['
    let StrParsed: any = isArray ? [] : {}
    try {
      StrParsed = JSON.parse(str)
    } catch {
      StrParsed = isArray ? [] : {}
    }
    return StrParsed
  }
  const JsonStringify = (obj: object, space?: number) => {
    let stringify: any = null
    try {
      stringify = JSON.stringify(obj, undefined, parseInt(space + '') || 0)
    } catch {
      stringify = null
    }
    return stringify
  }
  const groupBy = (xs: any, f: any, key: string) => {
    return xs.filter((r: any) => r[key]).reduce((r: any, v: any, i: any, a: any, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})
  }

  nuxtApp.provide('ArrayDuplicate', (array: any, key: any) => {
    const seen = new Set();
    return array.filter((item: any) => {
      const uniqueKey = item[key]; // Gunakan properti sebagai kunci unik
      if (seen.has(uniqueKey)) {
        return false; // Abaikan item duplikat
      }
      seen.add(uniqueKey); // Tambahkan kunci ke dalam set
      return true; // Pertahankan item unik
    });
  })

  nuxtApp.provide('GoogleMapsRoute', (origin: any, dest: any) => {
    return `https://www.google.com/maps/dir/?api=1&origin=${origin[0]},${origin[1]}&destination=[${dest[0]}],${dest[1]}]`
  })

  const BASE62_CHARSET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const BASE62 = {
    encode: (buffer: any) => {
      let num = BigInt('0x' + Array.from(buffer).map((b: any) => b.toString(16).padStart(2, '0')).join(''));
      let encoded = '';
      while (num > 0n) {
        encoded = BASE62_CHARSET[Number(num % 62n)] + encoded;  // Konversi bigint ke Number
        num = num / 62n;
      }
      return encoded;
    },
    decode: (encoded: any) => {
      let num = BigInt(0);
      for (let char of encoded) {
        num = num * 62n + BigInt(BASE62_CHARSET.indexOf(char));
      }
      const hex = num.toString(16);
      const paddedHex: any = hex.length % 2 === 0 ? hex : '0' + hex;
      const byteArray = paddedHex.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16));
      return new Uint8Array(byteArray);
    }
  };

  nuxtApp.provide('CombineIds', (uid1: string, uid2: string) => {
    const x = [uid1, uid2].sort((a, b) => a.localeCompare(b))
    return x[0] + ':' + x[1]
  })
  
  nuxtApp.provide('LinkRoom', (uid: string, authID: string) => {
    function roomidEnc (combined: string) {
      const encoder = new TextEncoder();
      const buffer = encoder.encode(combined);
      return BASE62.encode(buffer);
    }

    function roomidDec (encoded: string) {
      const decoder = new TextDecoder();
      const buffer = BASE62.decode(encoded);
      return decoder.decode(buffer);
    }

    if (uid && uid.split('-').length >= 5) {
      let genID = `/chat/`
      let gen = ''
      const sortedUUID = [uid, authID || ''].sort((a, b) => a.localeCompare(b))
      if (sortedUUID.length === 2) {
        gen = roomidEnc('4:' + sortedUUID[0] + ':' + sortedUUID[1])
      }
      return genID + gen
    } else {
      const a = roomidDec(uid)
      const x = (a || '').split(':')
      if (x.length === 3 && parseInt(x[0]) === 4) {
        if (x[1] === authID) {
          return {
            roomid: a,
            account: x[2]
          }
        }
        return {
          roomid: a,
          account: x[1]
        }
      }
    }
    return null
  })

  nuxtApp.provide('roomidEnc', (combined: string) => {
    const encoder = new TextEncoder();
    const buffer = encoder.encode(combined);
    return BASE62.encode(buffer);
  })

  nuxtApp.provide('roomidDec', (encoded: string) => {
    const decoder = new TextDecoder();
    const buffer = BASE62.decode(encoded);
    return decoder.decode(buffer);
  })

  nuxtApp.provide('nf', (num: number = 0, digits: number = 1, locale?: string) => {
    if (!parseInt(num + '')) {
      return '~'
    }
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: locale === 'id' ? 'rb' : 'k' },
      { value: 1e6, symbol: locale === 'id' ? 'jt' : 'M' },
      { value: 1e9, symbol: locale === 'id' ? 'M' : 'B' },
      { value: 1e12, symbol: locale === 'id' ? 'T' : 'T' },
      { value: 1e15, symbol: locale === 'id' ? '~' : 'P' },
      { value: 1e18, symbol: locale === 'id' ? '~~' : 'E' }
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
  })

  nuxtApp.provide('JsonParse', JsonParse)
  nuxtApp.provide('JsonRaw', (ProxyValue: any) => {
    if (typeof ProxyValue === 'object') {
      return JsonParse(JsonStringify(ProxyValue))
    }
    return ProxyValue
  })

  async function iK (kk: any) {
    const key = await crypto.subtle.importKey(
      'jwk',
      kk,
      {
        name: 'AES-GCM'
      },
      true,
      ['encrypt', 'decrypt']
    );

    return key
  }

  async function iKk (ed: ArrayBufferLike, i: ArrayBufferLike, kk: any) {
    try {
      const k: CryptoKey = await iK(kk)
      const decrypted = await crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: new Uint8Array(i)
        },
        k,
        new Uint8Array(ed)
      )
      const x = new TextDecoder().decode(decrypted)
      return JSON.parse(x)
    } catch {
      return null
    }
  }

  nuxtApp.provide('sde', async (ee: any) => {
    const kLength = ee.split(':A:34:|1')
    const e = kLength[0]
    const k = parseInt(kLength[1])
    let dc = ''
    for (let i = 0; i < e.length; i++) {
      let cc = e.charCodeAt(i);
      let o = cc - k;
      dc += String.fromCharCode(o);
    }
    const jK = JSON.parse(dc)
    const rr = await iKk(jK.e.e, jK.e.i, jK.x)
    return rr
  })

  nuxtApp.provide('JsonStringify', JsonStringify)
  nuxtApp.provide('ArrayMoveIndex', (arr: any, old_index: number, new_index: number) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  })
  nuxtApp.provide('Variants', (arr: any) => {
    const result: any = {
      type: groupBy(arr, (v: any) => v.variant_type, 'variant_type'),
      size: groupBy(arr, (v: any) => v.variant_size, 'variant_size'),
      color: groupBy(arr, (v: any) => v.variant_color, 'variant_color')
    }
    return result
  })
  nuxtApp.provide('NumberOnly', (e: any) => {
    const key = e.keyCode ? e.keyCode : e.which
    const isNaNValueData: any = String.fromCharCode(key)
    const isNaNValue = isNaN(isNaNValueData)
    if (isNaNValue && key !== 8 && key !== 46 && key !== 37 && key !== 39 && key !== 45) {
      e.preventDefault()
      return false
    }
  })
  
  const second = 1e3
  const minute = 6e4
  const hour = 36e5
  const day = 864e5
  const week = 6048e5

  nuxtApp.provide('timeAgo', (timestamp: any, locale: string = 'id') => {
    if (timestamp instanceof Date) {
      timestamp = timestamp.getTime()
    }
  
    if (typeof timestamp === 'string') {
      timestamp = new Date(timestamp).getTime()
    }
  
    const diff = Math.abs(timestamp - Date.now())
  
    if (diff <= second) {
      return '1s'
    } else if (diff < minute) {
      return Math.floor(diff / 1000) + (locale === 'id' ? 'detik' : 's')
    } else if (diff < hour) {
      return Math.floor(diff / 1000 / 60) + 'm'
    } else if (diff < day) {
      return Math.floor(diff / 1000 / 3600) + (locale === 'id' ? 'jam' : 'h')
    } else {
      if (diff < week) {
        return Math.floor(diff / 1000 / 86400) + (locale === 'id' ? 'hari' : 'd')
      } else {
        const d = new Date(timestamp)
        return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear()
      }
    }
  })
  nuxtApp.provide('SecondsAgo', (dTime: any, tTime?: any) => {
    const dt: any = tTime ? new Date(tTime) : new Date()
    return Math.floor((dt - (typeof dTime === 'string' ? new Date(dTime) : dTime)) / 1000);
  })

  nuxtApp.provide('ChannelCoverage', (s: any) => {
    switch (parseInt(s)) {
      case 0:
        return 'National'
      case 1:
        return 'Province'
      case 2:
        return 'City'
      case 3:
        return 'District'
      case 4:
        return 'Sub-District'
      case 5:
        return 'RW'
      case 6:
        return 'RT'
      case 7:
        return 'Family'
      default:
        return 'None'
    }
  })

  nuxtApp.provide('GenID', (length: number) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  })
  nuxtApp.provide('ArraySort', (arr: any, keys: any, isDesc?: boolean) => {
    const one = keys.length === 1
    return arr.sort((a: any, b: any) => {
      if (!a[keys[0]]) {
        return isDesc ? 1 : -1
      }
      if (!b[keys[0]]) {
        return isDesc ? -1 : 1
      }
      if ((one ? a[keys[0]] : (a[keys[0]] || a[keys[1]])) < (one ? b[keys[0]] : (b[keys[0]] || b[keys[1]]))) {
        return isDesc ? 1 : -1
      }
      if ((one ? a[keys[0]] : (a[keys[0]] || a[keys[1]])) > (one ? b[keys[0]] : (b[keys[0]] || b[keys[1]]))) {
        return isDesc ? -1 : 1
      }
      return 0
    })
  })
  nuxtApp.provide('CopyText', (txt: string) => {
    const tempTextarea = document.createElement("textarea");
    tempTextarea.setAttribute('class', 'copy-clipboard')
    tempTextarea.value = txt;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);
  })

  nuxtApp.provide('ArraySortByScoring', (arr: any, key: string, q: string) => {
    function calculateScore(item: any, query: string) {
      const regex = new RegExp(query, 'gi'); // Regex untuk mencari semua kemunculan 'bandung' (case insensitive)
      const matches = item[key].match(regex);
      return matches ? matches.length : 0; // Skor berdasarkan jumlah kemunculan
    }
    
    // Filter data yang mengandung query dan hitung skor
    const scoredData = arr
      .filter((obj: any) => obj[key].toUpperCase().includes(q.toUpperCase())) // Filter yang mengandung 'bandung'
      .map((obj: any) => ({
        ...obj, 
        score: calculateScore(obj, q), // Tambahkan skor kemunculan query
        startsWithQuery: obj[key].trim().toLowerCase().startsWith(q) })); // Tambahkan skor ke setiap objek

    // Sorting berdasarkan skor secara descending, kemudian by name ascending
    return scoredData.sort((a: any, b: any) => {
      if (a.startsWithQuery && !b.startsWithQuery) return -1; // Prioritas jika kata terdepan cocok dengan query
      if (!a.startsWithQuery && b.startsWithQuery) return 1;
      
      // Jika tidak, sorting by score descending
      if (b.score !== a.score) {
        return b.score - a.score;
      }
    
      // Jika skor sama, urutkan alphabetically by name ascending
      return a[key].localeCompare(b[key]);
    })
  })

  nuxtApp.provide('DayName', (dt: string, locale: string = 'en-US', s: any = 'short') => {
    const date = new Date(dt)
    return date.toLocaleDateString(locale, { weekday: s })
  })

  nuxtApp.provide('Weeks', (dt: any, locale: string = 'en-US', s: any = 'short') => {
    const baseDate = new Date(Date.UTC(dt[2], dt[1], dt[0]));
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(baseDate.toLocaleDateString(locale, { weekday: s }));
      baseDate.setDate(baseDate.getDate() + 1);       
    }
    return weekDays
  })

  nuxtApp.provide('DaysInMonth', (month: any, year: any) => {
    return new Date(parseInt(year), parseInt(month), 0).getDate()
  })

  nuxtApp.provide('NamedDate', (utcDate: any, opts: any = { day: true, month: false }) => {
    const localDate = utcDate ? new Date(utcDate) : new Date()
    const dayNames = opts.day ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const monthNames = opts.month ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] : ['January', 'February', 'Maret', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    // const year = localDate.getFullYear().toString().substr(-2)
    const year = localDate.getFullYear().toString()
    const month = monthNames[localDate.getMonth()]
    const dayName = dayNames[localDate.getDay()]

    return {
      day: dayName,
      month: month,
      year: year
    }
  })

  nuxtApp.provide('localDT', (utcDate: any, type: any) => {
    const localDate = utcDate ? new Date(utcDate) : new Date()
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // const year = localDate.getFullYear().toString().substr(-2)
    const year = localDate.getFullYear().toString()
    const month = monthNames[localDate.getMonth()]
    const day = localDate.getDate()
    const dayName = dayNames[localDate.getDay()]
  
    /* eslint-disable-next-line */
    const seconds = localDate.getSeconds()
    const minutes = localDate.getMinutes()
    const hour = localDate.getHours()
  
    if (type === 'daydate') {
      return dayName + ', ' + (day < 10 ? '0' + day : day) + ' ' + month + ' ' + year
    } else if (type === 'number') {
      return (day < 10 ? '0' + day : day) + '' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '' + year.substr(2, 2)
    } else if (type === 'date') {
      return dayName + ' ' + month + ', ' + year
    } else if (type === 'display') {
      return month + ' ' + (day < 10 ? '0' + day : day) + ', ' + year
    } else if (type === 'datelocal') {
      return (day < 10 ? '0' + day : day) + '/' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '/' + year
    } else if (type === 'monthyear') {
      return month + ', ' + year
    } else if (type === 'datedefault') {
      return year + '-' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '-' + (day < 10 ? '0' + day : day)
    } else if (type === 'datetimedefault') {
      return year + '-' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '-' + (day < 10 ? '0' + day : day) + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    } else if (type === 'datetimedefaultdb') {
      return year + '-' + ((localDate.getMonth() + 1) < 10 ? '0' + (localDate.getMonth() + 1) : (localDate.getMonth() + 1)) + '-' + (day < 10 ? '0' + day : day) + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
    } else if (type === 'time') {
      return (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    } else if (type === 'datetime') {
      return dayName + ', ' + day + ' ' + month + ' ' + year + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    } else {
      return localDate
    }
  })


  nuxtApp.provide('FormParseSelectTree', (opts: any) => {
    const data = []
    for (let d = 0; d < opts.length; d++) {
      const x = opts[d]
      if (x.subs && x.subs.length) {
        for (let y = 0; y < x.subs.length; y++) {
          if (x.subs[y].subs && x.subs[y].subs.length) {
            for (let z = 0; z < x.subs[y].subs.length; z++) {
              const zz = x.subs[y].subs[z]
              data.push({ title: `${x.value} -> ${x.subs[y].value} -> ${zz.value}`, value: `${x.value} -> ${x.subs[y].value} -> ${zz.value}` })
            }
          } else {
            data.push({ title: `${x.value} -> ${x.subs[y].value}`, value: `${x.value} -> ${x.subs[y].value}` })
          }
        }
      } else {
        data.push({ title: x.value, value: x.value })
      }
    }
    return data
  })
  nuxtApp.provide('Price', (price: any, sign?: any) => {
    if (!price) {
      return (sign || '') + (price)
    }
    price = new Intl.NumberFormat('en-US').format(parseFloat(price))
    return (sign || '') + price
  })

  nuxtApp.provide('FileParse', async (f: any) => {
    return new Promise((resolve: any) => {
      const reader: any = new FileReader();
      reader.onload = function() {
        try {
          const file = reader.result;
          const match = reader.result.match(/^data:([^/]+)\/([^;]+);/) || [];
          const type = match[1];
          const format = match[2];
          resolve({ status: true, data: { file, type, format } })
        } catch (err: any) {
          resolve({ status: false, data: null })
        }
      }
      reader.readAsDataURL(f)
    })
  })

  nuxtApp.provide('ImagesOne', (strImages: string) => JsonParse(strImages || '[]').length ? JsonParse(strImages)[0] : {})
  nuxtApp.provide('FilesOne', (strFiles: string) => JsonParse(strFiles || '[]').length ? JsonParse(strFiles)[0] : {})

  nuxtApp.provide('ImageConvert', (base64Str: string, maxWidth = 750, maxHeight = 750) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = base64Str
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
        canvas.width = width
        canvas.height = height
        const ctx: any = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  })

  nuxtApp.provide('SC_FORMAT', (str: string, labelFullText?: boolean) => {
    const splitter = (str || '').split('_')
    const type = splitter[0]
    const labelFull = splitter.length ? splitter.join(' ') : type
    const labelText = splitter.length ? (labelFull.replace(type + ' ', '')) : type
    return {
      type,
      label: labelFullText ? labelFull : labelText
    }
  })
  nuxtApp.provide('FOUND_KEY', (str: string, obj: object) => {
    return str in obj
  })
  nuxtApp.provide('DEEP_MERGE', (t: any, s: any, { imo = false, iss= false } = {}) => {
    function clone(obj: any, isStrictlySafe = false) {
      /* Clones an object. First attempt is safe. If it errors (e.g. from a circular reference),
            'isStrictlySafe' determines if error is thrown or an unsafe clone is returned. */
      try {
        return JSON.parse(JSON.stringify(obj));
      } catch(err: any) {
        if (isStrictlySafe) { throw new Error(err) }
        // console.warn(`Unsafe clone of object`, obj);
        return {...obj};
      }
    }
    
    function merge(target: any, source: any, {isMutatingOk = false, isStrictlySafe = false} = {}) {
      /* Returns a deep merge of source into target.
            Does not mutate target unless isMutatingOk = true. */
      target = isMutatingOk ? target : clone(target, isStrictlySafe);
      for (const [key, val] of Object.entries(source)) {
        if (val !== null && typeof val === `object`) {
          if (target[key] === undefined) {
            const v: any = val
            target[key] = new v.__proto__.constructor();
          }
          /* even where isMutatingOk = false, recursive calls only work on clones, so they can always
                safely mutate --- saves unnecessary cloning */
          target[key] = merge(target[key], val, {isMutatingOk: true, isStrictlySafe}); 
        } else {
          target[key] = val;
        }
      }
      return target;
    }
    return merge(t, s, { isMutatingOk: imo || false, isStrictlySafe: iss || false })
  })
  nuxtApp.provide('FileToBase64', function (event: any, callback: any) {
    function getType(ext: string) {
      if (ext === 'mov' || ext === 'mp4' || ext === 'avi' || ext === 'flv') {
        return 'video'
      } else if (ext === 'doc' || ext === 'docx' || ext === 'ppt' || ext === 'pptx' || ext === 'xls' || ext === 'xlsx' || ext === 'csv' || ext === 'txt' || ext === 'pdf' || ext === 'psd') {
        return 'doc'
      } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'png' || ext === 'svg') {
        return 'photo'
      } else if (ext === 'mp3' || ext === 'wav') {
        return 'audio'
      } else if (ext === 'csv') {
        return 'csv'
      } else {
        return 'unknown'
      }
    }
    const r: any = {
      status: false,
      ext: '',
      type: '',
      name: '',
      data: null
    }
    let f = event.target.files || event.dataTransfer.files
  
    const reader = new FileReader()
    if (f[0]) {
      const fname = event.target.files[0].name
      const lastDot = fname.lastIndexOf('.')
      r.ext = fname.substring(lastDot + 1)
      r.type = getType(r.ext)
      r.name = fname || ''
  
      const fSize = r.ext === 'mov' || r.ext === 'mp4' || r.ext === 'avi' || r.ext === 'pdf' || r.ext === 'flv' ? 20000000 : 1000000 // 10MB : 1MB
  
      if (f[0].size <= fSize) {
        reader.readAsDataURL(f[0])
        reader.onload = function (ev: any) {
          r.status = true
          r.data = ev.target.result
          callback(r)
        }
        reader.onerror = function (error) {
          r.status = false
          r.data = error
          callback(r)
        }
      } else {
        r.status = false
        r.data = 'file_size'
        callback(r)
      }
    } else {
      r.status = false
      r.data = 'canceled'
      callback(r)
    }
  })

  nuxtApp.provide('setChatDate', (dt: any, show: boolean = false) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const date: any = new Date(dt)
    const dateNow: any = new Date()
  
    const monthName = monthNames[date.getMonth()]
    const year = date.getFullYear()
    // const month = parseInt(date.getMonth()) < 10 ? '0' + (parseInt(date.getMonth()) + 1) : date.getMonth()
    const day = date.getDate().toString().padStart(2, '0')
    const newDate = day + ' ' + monthName + ' ' + year
  
    const monthNameNow = monthNames[dateNow.getMonth()]
    const yearNow = dateNow.getFullYear()
    // const monthNow = parseInt(dateNow.getMonth()) < 10 ? '0' + (parseInt(dateNow.getMonth()) + 1) : dateNow.getMonth()
    const dayNow = parseInt(dateNow.getDate()) < 10 ? '0' + parseInt(dateNow.getDate()) : dateNow.getDate()
    const nowDate = dayNow + ' ' + monthNameNow + ' ' + yearNow
    if (show) {
      if (nowDate === newDate) {
        return 'Today'
      } else {
        return newDate
      }
    }
    return null
  })

  nuxtApp.provide('DateReformat', (dt: any) => {
    const ndate = new Date(dt)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    const year = ndate.getFullYear().toString().substr(-2)
    const month = monthNames[ndate.getMonth()]
    const day = ndate.getDate()
    return day + ' ' + month + ' ' + year
  })
  
  nuxtApp.provide('DateRangeDays', (fromd: any, tod: any) => {
    const from: any = fromd ? (new Date(fromd)) : (new Date())
    const to: any = tod ? (new Date(tod)) : (new Date())
    // @ts-ignore
    return parseInt(((to - from) / 86400000))
  })
  
  nuxtApp.provide('TimesAgo', (dTime: any) => {
    const dt: any = new Date()
    var seconds: any = Math.floor((dt - (typeof dTime === 'string' ? new Date(dTime) : dTime)) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return {
        type: 'year',
        data: Math.floor(interval)
      }
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return {
        type: 'month',
        data: Math.floor(interval)
      }
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return {
        type: 'day',
        data: Math.floor(interval)
      }
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return {
        type: 'hour',
        data: Math.floor(interval)
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      return {
        type: 'minute',
        data: Math.floor(interval)
      }
    }
    return {
      type: 'second',
      data: Math.floor(interval)
    }
  })
  
  nuxtApp.provide('DateDiffDays', (dt: any, days: any) => {
    const date = new Date(dt)
    date.setDate(date.getDate() - days)
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    const ddt = yyyy + '-' + mm + '-' + dd
    return ddt
  })
  
  nuxtApp.provide('DateAddDays', (dt: any, days: any) => {
    const date = new Date(dt)
    date.setDate(date.getDate() + days)
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    const ddt = yyyy + '-' + mm + '-' + dd
    return ddt
  })

  nuxtApp.provide('MapDistance', (coords: any) => {
    function toRadians (degrees: number) {
      return degrees * (Math.PI / 180);
    }
    
    function haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
        const R = 6371; // Radius bumi dalam kilometer
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
    
        const a = 
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
            
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Jarak dalam kilometer
    }

    return haversineDistance(coords[0][0], coords[0][1], coords[1][0], coords[1][1])
  })

  nuxtApp.provide('VideoDuration', async (file: any) => {
    return await new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
  
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration); // Durasi dalam detik
      };
  
      video.onerror = () => {
        reject('Gagal memuat metadata video');
      };
  
      video.src = URL.createObjectURL(file);
    });
  })

  nuxtApp.provide('clearHTML', (str: any = '') => {
    let myDiv = document.createElement('div');
    myDiv.innerHTML = str
    return myDiv.innerText
  })

  nuxtApp.provide('parseHTML', (str: any = '', allowed_tags: any = []) => {
    // Make allowed tags array lower case
    str = str || ''
    allowed_tags = allowed_tags.map((c: any) => c.toLowerCase())
    // Output is the input, edited
    const input = str.replace(/style=\".*?"/gm,'')
    // console.log('x: ', input.replace(/<.*?\/>/gm,''))
    let output = input
    // Attempt to match an opening or closing HTML tag
    const reg = /<\/?([a-zA_Z0-9]*)[^>]*?>/g;
    const reg2 = /<?([a-zA_Z0-9]*)[^>]*?\/>/g;
    // An array that will contain all disallowed tags
    const disallowed_tags = []
    // For each tag in the input, if it's allowed, skip
    // Else, add it to the array.
    let match
    while ((match = reg.exec(input)) !== null) {
      if (allowed_tags.includes(match[1].toLowerCase())) continue
      disallowed_tags.push(match[0])
    }
    let match2
    while ((match2 = reg2.exec(input)) !== null) {
      if (allowed_tags.includes(match2[1].toLowerCase())) continue
      disallowed_tags.push(match2[0])
    }
    // Replace each disallowed tag with the "escaped" version
    disallowed_tags.forEach(tag => {
      const find = tag
      // const replace = tag.replace('<', '&lt;').replace('>', '&gt;')
      const replace = tag.split(tag).join('')
      output = output.replace(find, replace)
    })
    return output
  })

  nuxtApp.provide('BlobToFile', (theBlob: any, fileName: any) => {
    const myFile = new File([theBlob], fileName, {
      type: theBlob.type,
      lastModified: new Date().getTime()
    })
    return myFile
  })

  
  nuxtApp.provide('dataURItoBlob', (dataURI: any) => {
    if (!dataURI) {
      return null
    }
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  })
  nuxtApp.provide('blobToBase64', (blob: any) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  })
  nuxtApp.provide('toBase64', (event: any, callback: any) => {
    /* eslint-disable */
    function getType(ext: any) {
      if (ext === 'mov' || ext === 'mp4' || ext === 'avi' || ext === 'flv') {
        return 'video'
      } else if (ext === 'doc' || ext === 'docx' || ext === 'ppt' || ext === 'pptx' || ext === 'xls' || ext === 'xlsx' || ext === 'csv' || ext === 'txt' || ext === 'pdf' || ext === 'psd') {
        return 'doc'
      } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'gif' || ext === 'png' || ext === 'svg') {
        return 'photo'
      } else if (ext === 'mp3' || ext === 'wav') {
        return 'audio'
      } else if (ext === 'csv') {
        return 'csv'
      } else {
        return 'unknown'
      }
    }
    let r: any = {
      status: false,
      ext: '',
      type: '',
      name: '',
      data: null
    }

    let f = event.target.files || event.dataTransfer.files
  
    if (f.length > 1) {
      const d = []
      for (let g = 0; g < f.length; g++) {
        const reader = new FileReader()
        let rr: any = {
          status: false,
          ext: '',
          type: '',
          name: '',
          data: null
        }
        const fname = f[g].name
        const lastDot = fname.lastIndexOf('.')
        rr.ext = fname.substring(lastDot + 1)
        rr.type = getType(rr.ext)
        rr.name = fname || ''
    
        const fSize = (1024 * 1024 * 20)
    
        if (f[g].size <= fSize) {
          reader.readAsDataURL(f[g])
          reader.onload = function (ev: any) {
            rr.status = true
            rr.data = ev.target.result
            d.push(rr)
          }
          reader.onerror = function (error: any) {
            rr.status = false
            rr.data = error
            d.push(rr)
          }
        } else {
          rr.status = false
          rr.data = 'file_size'
          d.push(rr)
        }
      }
      callback(d)
    } else if (f[0]) {
      const reader = new FileReader()
      const fname = event.target.files[0].name
      const lastDot = fname.lastIndexOf('.')
      r.ext = fname.substring(lastDot + 1)
      r.type = getType(r.ext)
      r.name = fname || ''
  
      const fSize: any = (1024 * 1024 * 20)
  
      if (f[0].size <= fSize) {
        reader.readAsDataURL(f[0])
        reader.onload = function (ev: any) {
          r.status = true
          r.data = ev.target.result
          callback(r)
        }
        reader.onerror = function (error: any) {
          r.status = false
          r.data = error
          callback(r)
        }
      } else {
        r.status = false
        r.data = 'file_size'
        callback(r)
      }
    } else {
      r.status = false
      r.data = 'canceled'
      callback(r)
    }
  })

  nuxtApp.provide('TextLink', (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g
    const mentionPattern = /@(\w+)/g
    const hashtagPattern = /#(\w+)/g
    return text.replace(/\*_(.*?)_\*/g, '<strong><em>$1</em></strong>')
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
      .replace(/_(.*?)_/g, '<em>$1</em>')
      .replace(urlPattern, (url: any) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
      })
      .replace(mentionPattern, (mention, username) => {
        return `<a href="/${username}">@${username}</a>`;
      })
      .replace(hashtagPattern, (hashtag, tag) => {
        return `<a class="hashtag" href="/explore/${tag}">#${tag}</a>`;
      })
  })

  nuxtApp.provide('RestDayInMonth', (selectedDate = new Date()) => {
    // Convert parameter to Date object if string is provided
    const date = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    
    // Get the first day of the month
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    
    // Get the last day of the month
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
    // Find the Monday before the first day of month
    const firstMonday = new Date(firstDayOfMonth);
    while (firstMonday.getDay() !== 1) { // 1 represents Monday
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    
    // Find the Sunday after the last day of month
    const lastSunday = new Date(lastDayOfMonth);
    while (lastSunday.getDay() !== 0) { // 0 represents Sunday
      lastSunday.setDate(lastSunday.getDate() + 1);
    }
    
    // Get dates before the current month in the first week
    const before = [];
    let currentDate = new Date(firstMonday);
    
    function getDayName (dayIndex: any) {
      const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
      return days[dayIndex];
    }

    while (currentDate < firstDayOfMonth) {
      before.push({
        dt: `${currentDate.getFullYear()}-${((currentDate.getMonth() + 1) + '').padStart(2, '0')}-${(currentDate.getDate() + '').padStart(2, '0')}`,
        date: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        day: currentDate.getDay(),
        dayName: getDayName(currentDate.getDay())
      });
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    
    // Get dates after the current month in the last week
    const after = [];
    currentDate = new Date(lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1));
    
    while (currentDate <= lastSunday) {
      after.push({
        dt: `${currentDate.getFullYear()}-${((currentDate.getMonth() + 1) + '').padStart(2, '0')}-${(currentDate.getDate() + '').padStart(2, '0')}`,
        date: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        day: currentDate.getDay(),
        dayName: getDayName(currentDate.getDay())
      });
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    
    return {
      before,
      after
    };
  })

  nuxtApp.provide('ShiftLatLng', async (geojson: any, shiftLat: any, shiftLng: any) => {
    geojson.features.forEach((feature: any) => {
      if (feature.geometry && feature.geometry.coordinates) {
        const coords = feature.geometry.coordinates;
  
        // Jika tipe Point (1 koordinat)
        if (feature.geometry.type === 'Point') {
          coords[1] += shiftLat; // Geser latitude (koordinat vertikal)
          coords[0] += shiftLng; // Geser longitude (koordinat horizontal)
        }
  
        // Jika tipe LineString atau Polygon (banyak koordinat)
        if (feature.geometry.type === 'LineString' || feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates = coords.map((coord: any) => {
            return [coord[0] + shiftLng, coord[1] + shiftLat];
          });
        }
  
        // Jika tipe MultiPolygon (lebih banyak koordinat dalam array)
        if (feature.geometry.type === 'MultiPolygon') {
          feature.geometry.coordinates = coords.map((polygon: any) => {
            return polygon.map((ring: any) => {
              return ring.map((coord: any) => {
                return [coord[0] + shiftLng, coord[1] + shiftLat];
              });
            });
          });
        }
      }
    })
    return await (new Promise((resolve) => resolve(geojson)))
  })

  nuxtApp.provide('Num', async (number: number) => {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(1).replace(/\.0$/, '') + 'B'; // Billion
    }
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Million
    }
    if (number >= 1e3) {
      return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Thousand
    }
    return number.toString(); // Return as is if less than 1k
  })

  nuxtApp.provide('isNativeEmoji', (str: any) => {
    const emojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji})(\p{Emoji_Modifier_Base}|\p{Emoji_Component})?$/gu;
    return emojiRegex.test(str);
  })

  const { setDialogPrompt }: any = useModal()
  nuxtApp.provide('prompt', async (v: any) => {
    return await new Promise((resolve) => setDialogPrompt({ show: true, type: v.type || null, title: v.title || 'Confirm', description: v.description || '', form: v.form || null, act: { reverse: v.act?.reverse || false, cancel: v.act?.cancel || null, ok: v.act?.ok || null }, resolve }))
  })

  nuxtApp.provide('NumberFormat', (num: any) => {
    num = parseInt(num) || 0
    if (num === 0) return "0";
    const absNum = Math.abs(num); // Ambil nilai absolut untuk penanganan negatif
    let formatted;
  
    if (absNum >= 1_000_000_000) {
      formatted = (absNum / 1_000_000_000).toFixed(3) + "b"; // Miliar
    } else if (absNum >= 1_000_000) {
      formatted = (absNum / 1_000_000).toFixed(2) + "m"; // Jutaan
    } else if (absNum >= 1_000) {
      formatted = (absNum / 1_000).toFixed(1) + "k"; // Ribuan
    } else {
      formatted = absNum.toString(); // Angka kecil
    }
  
    // Tambahkan tanda negatif jika angka asli negatif
    return num < 0 ? `-${formatted}` : formatted;
  })

  nuxtApp.provide('generateBrowserID', () => {
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');
  
    // Deteksi jenis perangkat
    const userAgent = navigator.userAgent;
    let deviceType;
  
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      deviceType = 'iOS';
    } else if (/Android/.test(userAgent)) {
      deviceType = 'Android';
    } else if (/Win/.test(userAgent)) {
      deviceType = 'Windows';
    } else if (/Mac/.test(userAgent)) {
      deviceType = 'macOS';
    } else {
      deviceType = 'Other Desktop';
    }
  
    // Identifikasi browser
    let browser = 'unknown';
    if (userAgent.includes("Chrome")) {
      browser = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browser = "Safari";
    } else if (userAgent.includes("Firefox")) {
      browser = "Firefox";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      browser = "IE";
    } else if (userAgent.includes("Edge")) {
      browser = "Edge";
    }
  
    // Resolusi layar
    const screenResolution = `${screen.width}x${screen.height}`;
  
    // Fingerprint unik dari kanvas rendering
    ctx.textBaseline = 'top';
    ctx.font = '16px Arial';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(userAgent, 2, 15);
    const canvasFingerprint = canvas.toDataURL();
  
    // Gabungkan data untuk menghasilkan fingerprint yang konsisten
    const data = `${deviceType}-${browser}-${screenResolution}-${canvasFingerprint}`;
    
    function hashString(input: any) {
      let hash = 0;
      if (input.length === 0) return hash;
      for (let i = 0; i < input.length; i++) {
        const chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Ubah ke integer 32-bit
      }
      return hash.toString();
    }

    return hashString(data);
  })
  
  // Fungsi untuk mengonversi teks menjadi bold, italic, atau bold-italic
  function formatText(text: string) {
    text = text.replace(/\*_(.*?)_\*/g, '<strong><em>$1</em></strong>');  // Bold italic
    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');  // Bold
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');  // Italic
    text = text.replace(/```([\s\S]*?)```/g, (match, content) => {
      const trimmedContent = content.trim();
      return `<code class="block">${trimmedContent}</code>`;
    });
    text = text.replace(/`(.*?)`/g, '<code>$1</code>');  // Code
    text = text.replace(/{{(.*?):(.*?)}}/g, (_: any, key: any, value: any) => {
      // console.log(_, key, value)
      return `<span kosa-kata="${value.trim()}">${key.trim()}</span>`;
    });
    return text;
  }

  // Fungsi untuk mendeteksi URL dan mengubahnya menjadi clickable link
  function convertTextToLinks(text: string) {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  // Gabungkan formatText dan convertTextToLinks
  function formatAndLinkText(text: string) {
    let formattedText = formatText(text);  // Konversi bold dan italic
    return convertTextToLinks(formattedText);  // Konversi link
  }

  nuxtApp.provide('fText', (t: string) => {
    return formatAndLinkText(t || '')
  })
})
