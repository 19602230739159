<script setup lang="ts">
const { $JsonRaw, $NumberOnly }: any = useNuxtApp()
const { coin, coinLevel, coinBuy, coinHistory, coinModal, setCoinModal, coinReload } = useCoin()
const { lang } = useAuth()
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

const warlocCointAmount: any = ref(0)
const keyUp = (event: any) => {
  let e = event.target.value
  if (parseInt((event.target.value || '').split('.').join('') || 0) >= 20000) {
    e = '20000'
  }
  const cleanValue = (e + '').replace(/[^0-9]/g, '').replace(/^0+/, '');
  const formattedValue = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  warlocCointAmount.value = formattedValue;
}

const PaymentTotal = computed(() => {
  const t = (parseInt((warlocCointAmount.value || '').split('.').join('') + '') || 0)
  const tLevel = Object.keys(coinLevel.value)
  let chargeable = 0
  for (let l = 0; l < tLevel.length; l++) {
    if (parseFloat(tLevel[l]) < t) {
      chargeable = parseFloat(coinLevel.value[tLevel[l]])
    }
  }
  return t * chargeable
})

const BuyCoin = () => {
  const coin = (warlocCointAmount.value || '').split('.').join('')
  if (parseInt(coin) >= 10 && parseInt(coin) <= 20000) {
    coinBuy(parseInt(coin))
    setCoinModal()
  }
}

watch(() => coinModal.value, () => {
  coinReload()
  warlocCointAmount.value = '0'
}, { deep: true, immediate: true })
</script>

<template>
  <TransitionRoot appear :show="coinModal.show" as="template">
    <Dialog as="div" @close="setCoinModal()" class="relative z-[10000000001]">
      <div class="fixed inset-0 bg-black/40 dark:bg-black/90" aria-hidden="true" />
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div v-if="coinModal.show" class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="border-2 border-black dark:border-white/10 w-[97%] max-w-[375px] transform overflow-hidden rounded-3xl bg-[#fafafa] dark:bg-black text-black dark:text-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class=""
              >
                <div class="flex items-center justify-between text-black dark:text-white text-center font-bold">
                  <div class="flex items-center font-serif text-lg md:text-xl">
                    <WarlocCoin class="w-8 mr-2" />
                    Warloc Bubble
                  </div>
                  <div class="flex items-center justify-center pl-[8px] pr-[8px] py-[2px] rounded-full select-none cursor-pointer bg-warloc-profile">
                    <span class="text-white text-[.7rem] font-bold leading-4">
                      {{ (coin || 0).toLocaleString() }}
                    </span>
                    <WarlocCoin class="w-[16px] ml-[2px]" />
                  </div>
                </div>
              </DialogTitle>
              <div class="h-px bg-black/5 dark:bg-white/5 w-full mt-4" />
              <div class="pt-4 px-4 text-center font-bold text-red-500 italic">
                Only for simulation and testing!
              </div>
              <div class="px-2 text-center pt-8">
                <div class="flex flex-wrap justify-center w-full">
                  <span :class="[warlocCointAmount === '12' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '12'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    7 <span class="text-red-400 ml-1">+ 5</span>
                  </span>
                  <span :class="[warlocCointAmount === '125' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '125'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    75 <span class="text-red-400 ml-1">+ 50</span>
                  </span>
                  <span :class="[warlocCointAmount === '470' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '470'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    350 <span class="text-red-400 ml-1">+ 120</span>
                  </span>
                  <span :class="[warlocCointAmount === '1050' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '1050'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    750 <span class="text-red-400 ml-1">+ 300</span>
                  </span>
                  <span :class="[warlocCointAmount === '1775' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '1775'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    1450 <span class="text-red-400 ml-1">+ 325</span>
                  </span>
                  <span :class="[warlocCointAmount === '4920' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '4920'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    4300 <span class="text-red-400 ml-1">+ 620</span>
                  </span>
                  <span :class="[warlocCointAmount === '14050' ? 'bg-warloc-profile' : 'bg-black/10 text-black dark:text-white dark:bg-white/10']" class="flex items-center rounded-full mx-1 my-2 px-3 py-1 text-sm cursor-pointer font-bold" @click.prevent="warlocCointAmount = '14050'">
                    <WarlocCoin class="w-[14px] mr-1" />
                    12550 <span class="text-red-400 ml-1">+ 1500</span>
                  </span>
                </div>
                <div v-if="false" class="w-[100%] rounded-full mx-auto relative">
                  <input
                    v-model="warlocCointAmount"
                    type="text"
                    spellcheck="false"
                    autofocus
                    placeholder="Coin"
                    class="bg-warloc-profile placeholder:opacity-75 placeholder:font-thin placeholder:text-white text-white font-bold text-xl block w-full py-2 pl-6 pr-11 rounded-full shadow-md select-none"
                    style="color: #ffffff!important;"
                    @keyup="keyUp"
                  >
                  <WarlocCoin class="absolute top-[8px] right-2 w-8" />
                </div>
              </div>
              <div v-if="lang.lang === 'EN'" class="pt-8 w-full text-left text-orange-500 dark:text-orange-400 flex items-center justify-center text-sm font-bold">
                <InformationCircleIcon class="inline w-6 mr-2" />
                QRIS Payment!
              </div>
              <div v-else class="pt-8 w-full text-orange-500 dark:text-orange-400 text-center flex items-center justify-center text-sm">
                <InformationCircleIcon class="inline-flex w-6 mr-1" />
                Pembayaran menggunakan QRIS!
              </div>
              <div class="flex w-full justify-between items-center px-2 py-2 mt-6 border-t border-white/20">
                <span class="font-thin">Total : </span>
                <span class="font-bold text-lg mr-[2px]">
                  Rp{{ (PaymentTotal).toLocaleString() }}
                </span>
              </div>
              <div class="flex items-end justify-between w-full border-t border-white/20 pb-0 pt-6">
                <span class="text-black/75 dark:text-white/75 mb-1 text-sm cursor-pointer" @click.prevent="setCoinModal()">
                  Cancel
                </span>
                <div class="flex flex-col items-end justify-end">
                  <div class="rounded-full bg-primary dark:bg-primary/60 text-white py-1 px-4 font-bold shadow-md border-none hover:shadow-lg cursor-pointer" @click.prevent="BuyCoin()">
                    Buy Now
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
