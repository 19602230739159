<script setup lang="ts">
// https://github.com/infinitered/nsfwjs IMAGE SENSOR
const route: any = useRoute()
const { $parseHTML, $VideoDuration, $convertImage, $blobToBase64, $dataURItoBlob, $ImagesOne, $FileParse, $JsonRaw }: any = useNuxtApp()
const {
  handleKeyboardShortcut
}: any = useChatFormatter()
const { auth } = useAuth()
const { channelMapSelected } = useChannel()
const { authAccess } = useAccount()
const { postingProcess, setPostingProcess, showCreatePost, timelineRepost, setTimelineRepost }: any = useTimeline()
const router: any = useRouter()
const emit = defineEmits()

const UploadFileData: any = ref(null)
const UploadFileType: any = ref(null)
const UploadFileVideoDuration: any = ref(0)

const UploadFileContent: any = ref(null)
const UploadFileContentAttachments: any = ref([])
const UploadFileContentAttachmentsShow = ref(false)
const elModelContent: any = ref(null)

const switchAccount: any = ref({
  show: false,
  active: null
})

const miniSize = ref(false)

if (process.client) {
  watch(() => showCreatePost.value, (v: any) => {
    // miniSize.value = true
    switchAccount.value.show = false
    if (v) {
      if (elModelContent.value) {
        nextTick(() => {
          elModelContent.value.focus()
        })
      }
      UploadFileType.value = null
      UploadFileContent.value = null
      UploadFileContentAttachments.value = []
      // @ts-ignore
      document.body.style = 'overflow:hidden;'
      // setTimeout(() => {
      //   const ce: any = document.querySelector('.warloc-content-area')
      //   if (ce) {
      //     ce.addEventListener('paste', function (e: any) {
      //       e.preventDefault()
      //       var text = e.clipboardData.getData('text/plain')
      //       document.execCommand('insertText', false, $parseHTML(text, ['strong', 'code', 'i', 'em', 'u', 'b', 'div']))
      //       console.log('pasted!')
      //     })
      //   }
      // }, 300)

    } else {
      // @ts-ignore
      document.body.style = ''
      // setTimeout(() => {
      //   const ce: any = document.querySelector('.warloc-content-area')
      //   if (ce) {
      //     ce.addEventListener('paste', () => {})
      //   }
      // }, 300)
    }
  }, { immediate: true })
}

const ImageEditorShow = ref(false)
const UploadFile: any = (isAttachment?: boolean) => {
  UploadFileType.value = null
  if (TimelineLoading.value) {
    return
  }
  let u = document.getElementById('file-uploader')
  if (u) {
    u.remove()
  }
  if (!isAttachment) {
    UploadFileData.value = null
  }
  setTimeout(() => {
    u = document.createElement('input')
    u.setAttribute('type', 'file')
    u.setAttribute('id', 'file-uploader')
    u.setAttribute('class', 'file-uploader')
    // u.setAttribute('multiple', 'file-uploader')
    u.setAttribute('accept', isAttachment ? '.png,.jpeg,.jpg' : '.png,.jpeg,.jpg,.mp4,.avi,.flv,.mov')
    u.addEventListener('change', async (e: any) => {
      UploadFileData.value = null
      const f = e.target.files[0]
      const a = await $FileParse(e.target.files[0])
      if (a.data.type === 'video') {
        UploadFileVideoDuration.value = await $VideoDuration(f)
      }
      if (a && a.status && a.data) {
        UploadFileType.value = a.data
        if (a.data.type === 'image') {
          const result = await $convertImage(f, 1200, 1200)
          if (isAttachment) {
            const f = await $blobToBase64(result)
            UploadFileContentAttachments.value.push({
              type: 'image',
              src: f
            })
          } else {
            UploadFileContent.value = await $blobToBase64(result)
            UploadFileData.value = UploadFileContent.value
          }
        } else {
          UploadFileContent.value = a.data.file
        }
      }
    })
    u.click()
  }, 100)
}

const modelContent: any = ref(null)
watch(() => modelContent.value, (v: any) => {
  if (v) {
    modelContent.value = v
  } else {
    modelContent.value = null
  }
})

watch(() => route.query, (v: any) => {
  ImageEditorShow.value = false
  if (v && parseInt(v.edit) && UploadFileContent.value) {
    UploadFileData.value = UploadFileContent.value
    ImageEditorShow.value = true
  }
})

const TimelineType = ref('cover')
const TimelineColorData: any = ref({
  bg: '#fafafa',
  text: '#1F1F1F'
})

const HandlePaste = (e: any) => {
  e.preventDefault()
  var text = e.clipboardData.getData('text/plain')
  document.execCommand('insertText', false, text)
}

const SaveImage = (e: any) => {
  UploadFileContent.value = e
  UploadFileData.value = null
}

const TimelineLoading = ref(false)
const TimelinePost = async () => {
  if (TimelineLoading.value) {
    return
  }
  const file = $dataURItoBlob(UploadFileContent.value)
  const d: any = new FormData()
  d.append('file', file)
  if (UploadFileContentAttachments.value.length) {
    for (let f = 0; f < UploadFileContentAttachments.value.length; f++) {
      const ff = UploadFileContentAttachments.value[f]
      if (ff && ff.type === 'image') {
        d.append(`attachment`, $dataURItoBlob(ff.src))
      }
    }
  }
  d.append('file_type', UploadFileType.value?.type || '')
  d.append('file_format', UploadFileType.value?.format || '')
  if (parseFloat(UploadFileVideoDuration.value)) {
    d.append('file_duration', parseFloat(UploadFileVideoDuration.value))
  }
  d.append('content', modelContent.value)
  if (timelineRepost.value) {
    d.append('reply_to', timelineRepost.value.id)
  }
  if (switchAccount.value.active) {
    d.append('channel', switchAccount.value.active.channel)
  }
  TimelineLoading.value = true
  setPostingProcess(true)
  const req = await useApiPost('/timeline', d)
  if (req && req.status && req.data && req.data.id) {
    UploadFileData.value = null
    UploadFileContent.value = []
    UploadFileContentAttachments.value = []
    UploadFileVideoDuration.value = 0
    modelContent.value = null
    setTimelineRepost(null)
    emit('reload', true)
    router.go(-1)
  }
  setPostingProcess(false)
  TimelineLoading.value = false
}

</script>

<template>
  <div
    v-show="showCreatePost"
    id="timeline-create"
    class="fixed items-end z-[1001] origin-bottom transition-all duration-100 top-0 left-0 h-full flex w-full bg-gray-50 dark:bg-[#111111C2]"
    @click.prevent="$router.back()"
  >
    <div
      :class="[miniSize ? 'h-[50vh]' : 'h-full']"
      class="w-full md:pb-0 px-0 md:pl-2 lg:pl-4 relative flex items-center transition-all ease-in-out"
    >
      <div class="flex flex-col justify-start mx-auto my-auto min-h-full w-full md:w-[600px] max-w-[100%]  bg-white dark:bg-black max-h-full overflow-y-auto scroll-hidden" @click.stop>
        <div class="flex flex-col w-full overflow-auto scroll-hidden grow" style="height:calc(100% - 68px);">
          <div class="flex items-center justify-between py-4 pr-4 w-full">
            <div class="relative select-none">
              <div class="pl-4 flex items-center cursor-pointer" @click.prevent="switchAccount.show = !switchAccount.show">
                <div :style="{ backgroundImage: `url(${switchAccount.active?.channel === 'anonym' ? '/anonym.svg' : (switchAccount.active?.channel_logo || auth?.img)})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-5 h-5 bg-cover bg-cover-center mr-2" />
                <span class="grow truncate pr-2 text-xs text-black dark:text-white">
                  {{ (switchAccount.active?.channel_name ? ('w/' + switchAccount.active?.channel_name) : '') || auth?.name || 'No name' }}
                </span>
                <ChevronUpIcon v-if="switchAccount.show" class="w-5 text-gray-400" />
                <ChevronDownIcon v-else class="w-5 text-gray-400" />
              </div>
              <div v-show="switchAccount.show" class="absolute top-[35px] left-2 bg-gray-50 dark:bg-midnight-10 w-[275px] rounded-b-lg" @click-outside="switchAccount.show = false">
                <div class="text-black/40 dark:text-gray-400 text-xs font-bold px-4 pt-4 pb-4">
                  Send as :
                </div>
                <div class="h-px w-full bg-black/5 dark:bg-white/5"></div>
                <div class="max-h-[140px] overflow-y-auto scrollbar-mini w-full">
                  <div
                    class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-black w-full truncate"
                    @click.prevent="switchAccount.active = null, switchAccount.show = false"
                  >
                    <div :style="{ backgroundImage: `url(${auth?.img})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-6 h-6 bg-cover bg-cover-center mr-2" />
                    <span class="grow truncate pr-4 text-xs text-black dark:text-white">
                      {{ auth?.name || 'No name' }}
                    </span>
                  </div>
                  <div
                    class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-black w-full truncate"
                    @click.prevent="switchAccount.active = $JsonRaw({ channel: 'anonym', channel_name: 'Anonym' }), switchAccount.show = false"
                  >
                    <div :style="{ backgroundImage: `url(/anonym.svg)` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-6 h-6 bg-cover bg-cover-center mr-2" />
                    <span class="grow truncate pr-4 text-xs text-black dark:text-white">
                      {{ 'Anonym' }}
                    </span>
                  </div>
                  <div class="h-px w-full bg-black/5 dark:bg-white/5 my-2"></div>
                  <div
                    v-for="(a, aI) in authAccess"
                    :key="'auth-access-' + aI"
                    class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-black w-full truncate"
                    @click.prevent="switchAccount.active = $JsonRaw(a), switchAccount.show = false"
                  >
                    <div :style="{ backgroundImage: `url(${a?.channel_logo})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full w-6 h-6 bg-cover bg-cover-center mr-2" />
                    <span class="grow truncate pr-4 text-sm text-gray-800 dark:text-gray-300">
                      <span class="text-black/40 dark:text-white/40">w/</span>{{ a?.channel_name || 'No name' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span class="grow-1" />
            <div class="flex items-center justify-between pl-4 text-gray-500 dark:text-gray-300 rounded font-serif font-bold text-sm">
              <!-- <div class="cursor-pointer" @click="miniSize = !miniSize">
                <ChevronDownIcon v-if="!miniSize" class="text-gray-400 w-8" />
                <ChevronUpIcon v-else class="text-gray-400 w-8" />
              </div> -->
              <div class="flex items-center justify-between pl-4 text-black/40 dark:text-white/50 rounded font-serif font-bold text-sm cursor-pointer" @click.prevent="$router.go(-1)">
                <XMarkIcon class="w-7" />
              </div>
            </div>
          </div>
          <div v-if="timelineRepost" class="px-4 w-full">
            <div class="flex w-full items-center mb-4 rounded-xl bg-[#fafafa] dark:bg-[#181818] border border-black/5 dark:border-white/5 overflow-hidden">
              <div v-if="$ImagesOne(timelineRepost.content_media).src" class="bg-cover bg-cover-center w-20 h-20 min-w-20 min-h-20 overlow-hidden rounded-l-xl" :style="{ backgroundImage: `url(${$ImagesOne(timelineRepost.content_media).src || ''})` }" />
              <div class="p-4 grow">
                <div class="flex items-center">
                  <div :style="{ backgroundImage: `url(${timelineRepost.creator_channel_logo || timelineRepost.creator_img || ''})` }" class="block bg-gray-200 dark:bg-gray-800 rounded-full bg-cover bg-cover-center h-5 w-5 lg:h-6 lg:w-6 mr-1 lg:mr-2" />
                  <span class="grow truncate pr-4 text-xs lg:text-sm text-black/70 dark:text-white/50">
                    {{ timelineRepost.creator_channel_name || timelineRepost.creator_name || 'No name' }}
                  </span>
                </div>
                <div class="text-xs md:text-sm pt-2 md:pt-2 break-words w-full text-black/70 dark:text-white/50 line-clamp-3" v-html="$parseHTML(timelineRepost.content)" />
              </div>
            </div>
          </div>
          <div v-show="!miniSize" class="px-4">
            <div v-if="TimelineType === 'cover'" :class="[UploadFileContent ? '' : 'w-full']" class="rounded-xl bg-[#fafafa] dark:bg-[#181818] border border-black/5 dark:border-white/5 overflow-hidden">
              <div v-if="UploadFileContent" class="flex justify-center w-full max-h-[calc(100dvh-290px)] overflow-y-auto scroll-hidden">
                <div class="min-w-[70%] h-auto max-w-full">
                  <img v-if="UploadFileType?.type === 'image'" :src="UploadFileContent" class="block w-full">
                  <video v-else-if="UploadFileType?.type === 'video'" muted loop class="block w-full object-cover sm:min-h-unset">
                    <source :src="UploadFileContent" :type="`${UploadFileType?.type}/${UploadFileType?.format}`">
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
              <div v-else class="px-4">
                <div class="flex items-center text-sm justify-center w-full min-h-[50px] py-4 bg-transparent rounded text-center text-gray-400 hover:text-black/50 dark:hover:text-white/30 transition-all cursor-pointer italic" @click.prevent="UploadFile()">
                  add cover image or video
                </div>
              </div>
              <div v-if="UploadFileContent" class="flex items-center text-xs font-bold text-gray-600 bg-transparent dark:bg-midnight-10 border-b border-gray-200 dark:border-gray-800">
                <div :class="[UploadFileType?.type === 'image' ? 'w-1/2' : 'w-full']" class="flex justify-center align-center p-4 text-red-500 dark:text-red-500 cursor-pointer" @click.prevent="TimelineLoading ? false : UploadFileContent = null, UploadFileData = null">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="w-4 h-4 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                  REMOVE
                </div>
                <div v-if="UploadFileType?.type === 'image'" class="flex justify-center align-center w-1/2 p-3 border-l border-gray-200 dark:border-gray-700 cursor-pointer dark:text-gray-400" @click.prevent="TimelineLoading ? false : $router.push(`?post=${route.query.post}&edit=1`)">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                  </svg>
                  EDIT
                </div>
              </div>
            </div>
          </div>
          <div :class="[miniSize ? 'px-4 pt-0' : 'p-4']" class="grow w-full flex self-stretch">
            <textarea
              ref="elModelContent"
              v-model="modelContent"
              placeholder="Write here ..."
              class="w-full item-stretch rounded-xl bg-[#fafafa] dark:bg-[#181818] border border-black/5 dark:border-white/5 text-black/70 dark:text-white/70 scrollbar-mini p-4 outline-none"
              autofocus
              resize="false"
              spellcheck="false"
              @keyup="($event: any) => modelContent = $event.target.value"
              @keydown="handleKeyboardShortcut"
            />
          </div>
          <div v-show="UploadFileContentAttachmentsShow" class="bg-gray-100 dark:bg-midnight-10 flex items-center w-full h-[164px]">
            <HScroll :items="UploadFileContentAttachments" class="h-[164px] overflow-hidden">
              <template #default="{ item, index }: any">
                <div class="relative h-[164px] overflow-hidden">
                  <img :src="item.src" class="block max-w-full mx-1 h-[164px]">
                  <span class="absolute top-1 right-1 z-10 cursor-pointer" @click.prevent="UploadFileContentAttachments.splice(index, 1)">
                    <PlusIcon class="w-10 text-gray-300 rotate-45" />
                  </span>
                </div>
              </template>
            </HScroll>
            <div class="h-full min-h-full flex items-center px-[32px] py-10 cursor-pointer" @click="UploadFile(true)">
              <PlusIcon class="w-8 text-primary dark:text-gray-300" />
            </div>
          </div>
        </div>
        <div class="h-px bg-gray-200 dark:bg-gray-900 w-full" />
        <div class="flex w-full justify-between p-4">
          <span class="w-12 flex items-center justify-end cursor-pointer opacity-90 hover:opacity-100" @click="UploadFileContentAttachmentsShow = !UploadFileContentAttachmentsShow">
            <span v-if="UploadFileContentAttachments.length" class="text-black dark:text-white font-bold w-8">
              {{ UploadFileContentAttachments.length }}
            </span>
            <ChevronDownIcon v-else-if="UploadFileContentAttachmentsShow" class="text-gray-400 w-8" />
            <ChevronUpIcon v-else class="text-gray-400 w-8" />
          </span>
          <div class="flex items-center">
            <!-- <div class="flex items-center text-xs pr-4">
              <span class="text-gray-500 dark:text-gray-300 cursor-pointer pr-2" @click.prevent="sendAnonymPost = !sendAnonymPost">
                Anonym
              </span>
              <div class="cursor-pointer opacity-1 flex flex-col items-center justify-center rounded-xl w-8 h-7 text-center">
                <label class="flex items-center w-full justify-center cursor-pointer outline-none">
                  <input v-model="sendAnonymPost" type="checkbox" class="sr-only peer">
                  <div class="relative w-8 h-5 bg-gray-200 dark:bg-gray-700 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[0px] after:bg-gray-100 after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-200 peer-checked:bg-primary"></div>
                </label>
              </div>
            </div> -->
            <div
              :class="[!UploadFileContent && !modelContent ? 'bg-gray-300 dark:bg-gray-700' : 'bg-warloc-profile']"
              class="flex items-center justify-between px-6 py-2 rounded-3xl shadow-sm text-white text-sm cursor-pointer"
              @click.prevent="(TimelineLoading || (!UploadFileContent && !modelContent)) ? false : TimelinePost()"
            >
              <svg v-if="TimelineLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ TimelineLoading ? 'Processing ...' : (timelineRepost ? (modelContent ? 'Reply' : 'Reply') : 'Create Post') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimelineEditorImage v-if="UploadFileData && UploadFileType?.type === 'image'" :show="ImageEditorShow" :original-image="UploadFileData" @save="SaveImage" />
  </div>
</template>

<style lang="scss">
</style>
