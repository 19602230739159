import { createVuetify } from 'vuetify'
import { VVirtualScroll, VCarousel, VCarouselItem } from 'vuetify/components'
import { VueMasonryPlugin } from 'vue-masonry'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components: {
      VVirtualScroll, // Import hanya komponen VVirtualScroll
      VCarousel,
      VCarouselItem
    },
    theme: {
      themes: {
        dark: {
          colors: {
            primary: '#6A91FE',
            secondary: '#32E3A5',
            accent: '#FF6D6D'
          }
        },
        light: {
          colors: {
            primary: '#6A91FE',
            secondary: '#32E3A5',
            accent: '#FF6D6D'
          }
        }
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
  nuxtApp.vueApp.use(VueMasonryPlugin)
})
