import { VueDraggableNext } from 'vue-draggable-next'
import { Cropper } from 'vue-advanced-cropper'
import { readAndCompressImage } from '@misskey-dev/browser-image-resizer'
import VueEmojiPicker from 'vue3-emoji-picker';
// import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker'
// @ts-ignore
import At from 'vue-at' // for content-editable

import 'vue3-emoji-picker/css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

// https://github.com/tylergreason/knave-mobile-app/blob/master/touchEvents.js
// https://www.npmjs.com/package/@misskey-dev/browser-image-resizer

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('VueEmojiPicker', VueEmojiPicker)

  nuxtApp.vueApp.component('cropper', Cropper)
  // nuxtApp.vueApp.component('Vue3ColorPicker', Vue3ColorPicker)
  nuxtApp.vueApp.component('At', At)
  nuxtApp.vueApp.component('draggable', VueDraggableNext)
  return {
    provide: {
      convertImage: async (file: File, maxWidth: number = 1920, maxHeight: number = 1920) => {
        try {
          const config = { name: file.name, argorithm: null, quality: 1, mimeType: file.type, maxWidth, maxHeight }
          return await readAndCompressImage(file, config)
        } catch (error) {
          console.error(error);
          throw(error);
        }
      }
    }
  }
})
