<script setup lang="ts">
// https://github.com/infinitered/nsfwjs IMAGE SENSOR
const { $JsonRaw, $convertImage, $blobToBase64, $dataURItoBlob }: any = useNuxtApp()
const { channelDetail, channelMapSelected, showCreateChannel, ChannelUsernameCheck }: any = useChannel()
const router: any = useRouter()
const route: any = useRoute()
const emit = defineEmits()

const channelLoading = ref(false)
const ChannelPayloadTemp = {
  channel_mode: 0,
  channel_mode_kyc: 0,
  parent_master: '',
  parent_channel: '',
  name: null,
  fullname: null,
  description: null,
  type: 'ngo',
  typesub: 'community',
  coverage: '0',
  location_id: null,
  location_lat: null,
  location_lng: null,
  logo: null,
  banner: null
}
const ChannelPayload: any = ref({
  channel_mode: 0,
  channel_mode_kyc: 0,
  parent_master: '',
  parent_channel: '',
  name: null,
  fullname: null,
  description: null,
  type: 'ngo',
  typesub: 'community',
  coverage: '0',
  location_id: null,
  location_lat: null,
  location_lng: null,
  logo: null,
  banner: null
})

const ChannelLocationSelected: any = ref({
  show: false,
  data: { lat: null, lng: null }
})
const ChannelLocationSelectedUpdate = (v: any) => {
  ChannelPayload.value.location_lat = v.lat
  ChannelPayload.value.location_lng = v.lng
}

watch(() => showCreateChannel.value, (v: any) => {
  ChannelLocationSelected.value.show = false
  ChannelLocationSelected.value.data = { lat: null, lng: null }
  if (v) {
    if (channelMapSelected.value[channelMapSelected.value.selected] && route.name === 'maps') {
      if (parseInt(route.query?.sub)) {
        ChannelLocationSelected.value.data.lat = null
        ChannelLocationSelected.value.data.lng = null
        ChannelLocationSelected.value.show = true
        const c = $JsonRaw(ChannelPayloadTemp)
        c.parent_master = channelMapSelected.value.selected
        c.parent_channel = channelMapSelected.value.selected
        ChannelPayload.value = c
      } else {
        const c = channelMapSelected.value[channelMapSelected.value.selected]
        ChannelPayload.value.id = c.id || null
        ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
        ChannelPayload.value.channel_mode_kyc = parseInt(c.channel_mode_kyc) || 0
        ChannelPayload.value.parent_master = c.parent_master || null
        ChannelPayload.value.parent_channel = c.parent_channel || null
        ChannelPayload.value.id = c.id || null
        ChannelPayload.value.name = c.name
        ChannelPayload.value.fullname = c.fullname
        ChannelPayload.value.description = c.description
        ChannelPayload.value.type = c.type
        ChannelPayload.value.typesub = c.typesub
        ChannelPayload.value.coverage = c.coverage
        ChannelPayload.value.location_id = c.location_id
        ChannelPayload.value.location_lat = parseFloat(c.location_lat) || 0
        ChannelPayload.value.location_lng = parseFloat(c.location_lng) || 0
        ChannelPayload.value.logo = c.logo
        ChannelPayload.value.banner = c.banner
        ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
        ChannelLocationSelected.value.data.lat = parseFloat(c.location_lat) || -3.0150618
        ChannelLocationSelected.value.data.lng = parseFloat(c.location_lng) || 119.5509895
        if (parseFloat(c.location_lat) && parseFloat(c.location_lng)) {
          ChannelLocationSelected.value.show = true
        }
      }
    } else if (channelDetail.value[route.params.channelid] && route.name === 'w-channelid') {
      const c = channelDetail.value[route.params.channelid]
      ChannelPayload.value.id = c.id || null
      ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
      ChannelPayload.value.channel_mode_kyc = parseInt(c.channel_mode_kyc) || 0
      ChannelPayload.value.parent_master = c.parent_master || null
      ChannelPayload.value.parent_channel = c.parent_channel || null
      ChannelPayload.value.id = c.id || null
      ChannelPayload.value.name = c.name
      ChannelPayload.value.fullname = c.fullname
      ChannelPayload.value.description = c.description
      ChannelPayload.value.type = c.type
      ChannelPayload.value.typesub = c.typesub
      ChannelPayload.value.coverage = c.coverage
      ChannelPayload.value.location_id = c.location_id
      ChannelPayload.value.location_lat = parseFloat(c.location_lat) || 0
      ChannelPayload.value.location_lng = parseFloat(c.location_lng) || 0
      ChannelPayload.value.logo = c.logo
      ChannelPayload.value.banner = c.banner
      ChannelPayload.value.channel_mode = parseInt(c.channel_mode) || 0
      ChannelLocationSelected.value.data.lat = parseFloat(c.location_lat) || -3.0150618
      ChannelLocationSelected.value.data.lng = parseFloat(c.location_lng) || 119.5509895
      if (parseFloat(c.location_lat) && parseFloat(c.location_lng)) {
        ChannelLocationSelected.value.show = true
      }
    } else {
      ChannelLocationSelected.value.data.lat = null
      ChannelLocationSelected.value.data.lng = null
      ChannelLocationSelected.value.show = false
      ChannelPayload.value = $JsonRaw(ChannelPayloadTemp)
    }
  }
}, { immediate: true })

const UploadImage: any = (isBanner: boolean) => {
  if (channelLoading.value) {
    return
  }
  let u = document.getElementById('file-uploader')
  if (u) {
    u.remove()
  }
  setTimeout(() => {
    u = document.createElement('input')
    u.setAttribute('type', 'file')
    u.setAttribute('id', 'file-uploader')
    u.setAttribute('class', 'file-uploader')
    // u.setAttribute('multiple', 'file-uploader')
    u.setAttribute('accept', '.png,.jpeg,.jpg')
    u.addEventListener('change', async (e: any) => {
      if (isBanner) {
        const result = await $convertImage(e.target.files[0], 1200, 1200)
        ChannelPayload.value.banner = await $blobToBase64(result)
      } else {
        const result = await $convertImage(e.target.files[0], 350, 350)
        ChannelPayload.value.logo = await $blobToBase64(result)
      }
    })
    u.click()
  }, 100)
}

const ChannelProcess = async () => {
  if (channelLoading.value) {
    return
  }
  if (!ChannelPayload.value.name) {
    return
  }
  const valid = /^[a-zA-Z0-9]+$/
  if (valid.test(ChannelPayload.value.name)) {
    return
  }
  const d: any = new FormData()
  if (ChannelPayload.value.id) {
    d.append('id', ChannelPayload.value.id)
  }
  d.append('channel_mode', ChannelPayload.value.channel_mode || 0)
  d.append('channel_mode_kyc', ChannelPayload.value.channel_mode_kyc || 0)
  if (ChannelPayload.value.logo && !ChannelPayload.value.logo.match('http')) {
    d.append('logo', $dataURItoBlob(ChannelPayload.value.logo))
  }
  if (ChannelPayload.value.banner && !ChannelPayload.value.banner.match('http')) {
    d.append('banner', $dataURItoBlob(ChannelPayload.value.banner))
  }
  d.append('name', (ChannelPayload.value.name || '').toLowerCase())
  d.append('fullname', (ChannelPayload.value.fullname || ''))
  d.append('parent_master', ChannelPayload.value.parent_master || '')
  d.append('parent_channel', ChannelPayload.value.parent_channel || '')
  d.append('type', ChannelPayload.value.type || '')
  d.append('typesub', ChannelPayload.value.typesub || '')
  d.append('coverage', ChannelPayload.value.coverage || '')
  d.append('location_id', ChannelPayload.value.location_id || 0)
  d.append('location_lat', (ChannelLocationSelected.value.show && parseFloat(ChannelPayload.value.location_lat)) ? parseFloat(ChannelPayload.value.location_lat) : 0)
  d.append('location_lng', (ChannelLocationSelected.value.show && parseFloat(ChannelPayload.value.location_lng)) ? parseFloat(ChannelPayload.value.location_lng) : 0)
  d.append('description', ChannelPayload.value.description || '')
  channelLoading.value = true
  const req = await useApiPost('/channel', d)
  if (req && req.status && req.data && req.data.id) {
    ChannelPayload.value.name = null
    ChannelPayload.value.description = null
    ChannelPayload.value.type = 'ngo'
    ChannelPayload.value.coverage = ''
    ChannelPayload.value.logo = null
    ChannelPayload.value.banner = null
    emit('reload', true)
    router.go(-1)
    // ChatRoomsGet()
  }
  channelLoading.value = false
}

let unameCheckTimeout: any = null
const CheckUsernameResult = ref(1)
const CheckUsername = async () => {
  if (unameCheckTimeout) {
    clearTimeout(unameCheckTimeout)
  }
  unameCheckTimeout = setTimeout(async () => {
    const a = await ChannelUsernameCheck(ChannelPayload.value.id || '0', ChannelPayload.value.name)
    if (a === 'true') {
      CheckUsernameResult.value = 2
    } else {
      CheckUsernameResult.value = 3
    }
  }, 500)
}

const accountUsername = computed(() => {
  return ChannelPayload.value.name || null
})

watch(() => ChannelPayload.value.name, (v1, v0) => {
  CheckUsernameResult.value = 0
  const valid = /^[a-zA-Z0-9]+$/
  if (v1) {
    if (valid.test(v1)) {
      ChannelPayload.value.name = v1
      CheckUsername()
    } else {
      CheckUsernameResult.value = 3
      // CheckUsername()
    }
    if (v1.split(' ').length > 1) {
      ChannelPayload.value.name = v1.split(' ').join('')
    }
  } else {
    ChannelPayload.value.name = null
  }
})

</script>

<template>
  <div v-show="showCreateChannel" id="channel-create" class="fixed z-[501] top-0 left-0 h-full flex items-center w-full bg-[#FFFFFFFE] dark:bg-[#000000EE] md:px-4" @click.prevent="channelLoading ? false : $router.go(-1)">
    <div v-if="showCreateChannel" class="w-full md:pb-0 relative h-full flex items-center">
      <div class="flex flex-col justify-start mx-auto my-auto w-full md:w-[600px] max-w-[100%] bg-gray-50 dark:bg-midnight-10 max-h-full overflow-y-auto scroll-hidden min-h-screen" @click.stop>
        <div class="overflow-auto scroll-hidden px-4 pb-4 pt-4">
          <div
            class="flex justify-center items-center px-4 py-10 mb-4 min-h-[125px] lg:min-h-[155px] w-full hover:bg-black/5 dark:hover:bg-white/5 rounded-xl cursor-pointer transition-all bg-cover-center"
            :style="{ backgroundImage: ChannelPayload.banner ? `url(${ChannelPayload.banner || ''})` : '' }"
            @click.prevent="UploadImage(true)"
          >
            <div class="bg-black/5 dark:bg-white/5 bg-cover-center overflow-hidden flex justify-center items-center w-20 h-20 rounded-full text-gray-400 cursor-pointer hover:scale-110 transition-all" :style="{ backgroundImage: ChannelPayload.logo ? `url(${ChannelPayload.logo || ''})` : '' }" @click.stop @click.prevent="UploadImage(false)">
              {{ ChannelPayload.logo ? '' : 'Logo' }}
            </div>
          </div>
          <div class="h-px bg-gray-100 dark:bg-white/5 mb-8" />
          <div v-if="ChannelPayload.parent_master" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/30 dark:text-white/30 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Master Channel
              </label>
            </div>
            <div v-if="channelMapSelected && channelMapSelected[channelMapSelected.selected]" class="flex items-center justify-end md:w-2/3 text-sm text-gray-400 font-bold">
              <div class="truncate" style="max-width:calc(100% - 52px);">
                {{ channelMapSelected[channelMapSelected.selected].name }}
              </div>
              <div class="bg-cover-center bg-gray-400 w-7 h-7 rounded-full ml-2" :style="{ backgroundImage: `url(${channelMapSelected[channelMapSelected.selected].logo})` }" />
            </div>
          </div>
          <div v-if="ChannelPayload.parent_master && ChannelPayload.parent_channel && ChannelPayload.parent_master !== ChannelPayload.parent_channel" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/30 dark:text-white/30 md:text-right mb-2 md:mb-0 pr-4" for="channel-parent">
                Parent Channel
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.parent_channel" placeholder="Channel parent" class="bg-gray-100 dark:bg-black/70 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="channel-parent" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="channel-description">
                Channel Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.fullname" placeholder="Channel name" class="placeholder:opacity-40 bg-black/5 dark:bg-white/5 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="channel-description" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Channel Path
              </label>
            </div>
            <div class="md:w-2/3">
              <div class="rounded-lg bg-black/5 dark:bg-white/5 text-black dark:text-white flex items-center grow px-2">
                <strong class="text-black/50 dark:text-white/50 font-bold">w/</strong><input v-model="ChannelPayload.name" type="text" spellcheck="false" class="placeholder:opacity-40 outline-none text-black bg-transparent grow dark:text-white py-1 lowercase" @click.prevent="(e: any) => e.target.select()">
                <span v-if="ChannelPayload.name" class="w-5 h-5 min-w-5">
                  <span v-if="CheckUsernameResult === 1" />
                  <CheckCircleIcon v-else-if="CheckUsernameResult === 2" class="w-5 fill-blue-500" />
                  <svg v-else-if="CheckUsernameResult === 3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 fill-none stroke-red-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  <svg v-else class="animate-spin h-4 w-4 text-gray-800 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="channel-description">
                Channel Description
              </label>
            </div>
            <div class="md:w-2/3">
              <input v-model="ChannelPayload.description" placeholder="Channel description" class="placeholder:opacity-40 bg-black/5 dark:bg-white/5 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" id="channel-description" type="text">
            </div>
          </div>
          <div class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Is Private Channel ?
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model.number="ChannelPayload.channel_mode" placeholder="Fixed location" class="bg-black/5 dark:bg-white/5 cursor-pointer appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option :value="0" class="bg-white dark:bg-black text-black/90 dark:text-white/90">No</option>
                <option :value="1" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Yes</option>
              </select>
              <!-- <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div> -->
            </div>
          </div>
          <div class="h-px bg-gray-100 dark:bg-white/5 mb-4" />
          <!-- <div class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/30 dark:text-white/30 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Channel Type
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.type" placeholder="Channel type" class="cursor-pointer bg-gray-100 dark:bg-black/70 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded w-full py-3 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none" @change="ChannelPayload.type === 'gov' ? ChannelPayload.typesub = 'executive' : ChannelPayload.typesub = 'community'">
                <option value="ngo">Non Governmental Organization</option>
                <option value="gov">Government</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div> -->
          <div v-if="ChannelPayload.type === 'gov'" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/30 dark:text-white/30 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Channel Category
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.typesub" placeholder="Channel type" class="cursor-pointer bg-gray-100 dark:bg-black/70 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-2 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="executive">Executive</option>
                <option value="legislative">Legislative</option>
                <option value="judicial">Judicial</option>
                <option value="''">Other</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-else-if="ChannelPayload.type === 'ngo'" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Channel Category
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.typesub" placeholder="Channel type" class="bg-black/5 dark:bg-white/5 cursor-pointer appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="community" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Community/Group/NGO</option>
                <option value="business" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Business</option>
                <option value="education" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Education (School/College)</option>
                <option value="health" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Health</option>
                <option value="political-party" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Political Party</option>
                <option value="foundation" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Foundation (Non-Profit)</option>
                <option value="cooperative" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Cooperative (Koperasi)</option>
                <option value="multi-cooperative" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Multi Cooperative (Koperasi Multipihak)</option>
                <!-- <option value="local">Local Group / RT / RW / Village</option> -->
                <option value="''" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Other</option>
              </select>
              <!-- <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div> -->
            </div>
          </div>
          <div v-if="false" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/30 dark:text-white/30 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Channel Coverage
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelPayload.coverage" placeholder="Channel coverage" class="bg-black/5 dark:bg-white/5 cursor-pointer appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option value="0">National</option>
                <option value="1">Province</option>
                <option value="2">City (Kota/Kabupaten)</option>
                <option value="3">District (Kecamatan)</option>
                <option value="4">Sub-District (Desa/Kelurahan)</option>
                <option value="5">RW (Rukun Warga)</option>
                <option value="6">RT (Rukun Tetangga)</option>
                <option value="7">House / Family</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div v-if="ChannelPayload.coverage !== '0'" class="md:flex md:items-center mb-4 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                <!-- Location -->
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <input v-model.number="ChannelPayload.location_id" type="text" placeholder="Search ..." class="bg-gray-100 dark:bg-black/70 appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-2 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
            </div>
          </div>
          <div class="h-px bg-gray-100 dark:bg-white/5 mb-4" />
          <div class="md:flex md:items-center mb-2 w-full">
            <div class="md:w-1/3">
              <label class="text-xs block text-black/50 dark:text-white/50 md:text-right mb-2 md:mb-0 pr-4" for="inline-full-name">
                Set Map Location :
              </label>
            </div>
            <div class="md:w-2/3 relative">
              <select v-model="ChannelLocationSelected.show" placeholder="Fixed location" class="bg-black/5 dark:bg-white/5 cursor-pointer appearance-none border-2 border-gray-200 dark:border-gray-800 rounded-lg w-full py-1 px-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none">
                <option :value="true" class="bg-white dark:bg-black text-black/90 dark:text-white/90">Yes</option>
                <option :value="false" class="bg-white dark:bg-black text-black/90 dark:text-white/90">No</option>
              </select>
              <!-- <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div> -->
            </div>
          </div>
          <div v-if="ChannelLocationSelected.show" class="w-full h-[300px] pt-2">
            <WarlocMapSelect v-model="ChannelLocationSelected.data" :show="ChannelLocationSelected.show" :icon="ChannelPayload.logo || ''" @selected="ChannelLocationSelectedUpdate" />
          </div>
        </div>
        <div class="grow"></div>
        <div class="h-px bg-gray-100 dark:bg-white/5" />
        <div class="flex w-full justify-between p-4">
          <div class="flex items-center justify-between px-4 py-2 text-red-500 dark:text-red-700 rounded text-sm cursor-pointer" @click.prevent="channelLoading ? false : $router.go(-1)">
            Cancel
          </div>
          <div
            class="flex items-center justify-between px-6 py-2 rounded-3xl shadow-sm bg-warloc-profile text-white text-sm cursor-pointer"
            @click.prevent="channelLoading ? false : ChannelProcess()"
          >
            <svg v-if="channelLoading" class="animate-spin -ml-1 mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ channelLoading ? 'Processing ...' : ((ChannelPayload.id ? 'Update' : 'Create New') + ' Channel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.warloc-editor-is-empty {
  .atwho-wrap {
    >.warloc-content-area {
      &::after {
        content: 'Write here ...';
        position: absolute;
        top:0px;
        left:20px;
        height:100%;
        display: flex;
        align-items: center;
        color: #aaa;
      }
    }
  }
}
</style>
