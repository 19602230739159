export const useWarloc = () => {
  const { $JsonRaw }: any = useNuxtApp()
  const router = useRouter()
  
  const version = useState<any>('version', () => {
    return 0
  })
 
  const initLoad = useState<any>('initLoad', () => {
    return true
  })

  const modalLoading = useState<any>('setModalLoadign', () => {
    return false
  })

  const setModalLoading = (show?: boolean) => {
    modalLoading.value = show || false
  }

  const modalProject = useState<any>('modalProject', () => {
    return {
      show: false,
      active: null,
      data: null
    }
  })

  const setModalProject = (data: any, index: number) => {
    if (data) {
      router.push('?modal=1')
      modalProject.value.show = true
      modalProject.value.data = $JsonRaw(data)
      modalProject.value.active = $JsonRaw(data[index])
    } else {
      modalProject.value.show = false
      modalProject.value.data = null
      modalProject.value.active = null
    }
  }

  const WarlocCoinCount = (t: any, locale?: boolean) => {
    if (t) {
      const reply = (parseInt(t.count_reply) || 0) * 2
      const views_unique = (parseInt(t.count_views_unique) || 0) * 1
      const likes = (parseInt(t.count_likes) || 0) * 3
      const bookmarks = (parseInt(t.count_bookmarks) || 0) * 5
      const total = (reply + views_unique + likes + bookmarks)
      return locale ? (21 * total).toLocaleString() : (11 * total)
    }
    return 0
  }
  
  const warlocTrending = useState<any>('warlocTrending', () => {
    return []
  })
  
  const warlocTrendingSet = (d: any) => {
    warlocTrending.value = d || []
  }

  return {
    version,
    initLoad,
    modalLoading,
    setModalLoading,
    modalProject,
    setModalProject,
    WarlocCoinCount,
    warlocTrending,
    warlocTrendingSet
  }
}
