<template>
  <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Bubble Body -->
    <circle cx="150" cy="150" r="100" fill="#0a8cf9" stroke="#4A90E2" stroke-width="8" />
    <!-- Bubble Shine -->
    <circle cx="120" cy="120" r="30" fill="rgba(255, 255, 255, 0.7)" />
    <circle cx="165" cy="95" r="15" fill="rgba(255, 255, 255, 0.5)" />
    
    <!-- Koin Character Face -->
    <!-- Koin body -->
    <circle cx="100" cy="220" r="50" fill="#FFC107" stroke="#E0A800" stroke-width="6" />
    <circle cx="100" cy="220" r="40" fill="#FFD54F" />
    <!-- Eyes -->
    <circle cx="85" cy="210" r="8" fill="#4A4A4A" />
    <circle cx="115" cy="210" r="8" fill="#4A4A4A" />
    <!-- Smile -->
    <path d="M85 235 Q100 245 115 235" stroke="#4A4A4A" stroke-width="4" fill="none" stroke-linecap="round" />

    <!-- Bubble Character Face -->
    <!-- Eyes -->
    <circle cx="130" cy="140" r="12" fill="#FFFFFF" />
    <circle cx="170" cy="140" r="12" fill="#FFFFFF" />
    <circle cx="135" cy="140" r="6" fill="#000000" />
    <circle cx="175" cy="140" r="6" fill="#000000" />
    
    <!-- Mouth -->
    <path d="M140 180 Q150 190 160 180" stroke="#000000" stroke-width="4" fill="none" stroke-linecap="round" />
    
    <!-- Koin in Bubble -->
    <circle cx="150" cy="240" r="12" fill="#FFC107" stroke="#E0A800" stroke-width="3" />
    <circle cx="150" cy="240" r="9" fill="#FFD54F" />
    <path d="M150 235 v10" stroke="#E0A800" stroke-width="2" />
    <path d="M145 240 h10" stroke="#E0A800" stroke-width="2" />
  </svg>
</template>