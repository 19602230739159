<script setup lang="ts">
const { dialogPrompt, setDialogPrompt } = useModal()
const { $JsonRaw }: any = useNuxtApp()
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

const modelValue = ref(null)

const formSubdistrict = ref(null)
const formNotes = ref(null)

const Act = (t: any) => {
  if (dialogPrompt.value.resolve) {
    if (dialogPrompt.value.type === 'prompt') {
      if (t && !modelValue.value) {
        return false
      } else if (!t) {
        modelValue.value = null
      }
      dialogPrompt.value.resolve(modelValue.value || '')
    } else {
      if (t === 'ok') {
        if (dialogPrompt.value.form && dialogPrompt.value.form.fields && dialogPrompt.value.form.fields.length) {
          const validForms = dialogPrompt.value.form.fields.filter((r: any, i: number) => {
            if (r.type === 'subdistrict') {
              if (formSubdistrict.value) {
                dialogPrompt.value.form.fields[i].value = $JsonRaw(formSubdistrict.value)
                return true
              } else {
                return false
              }
            } else if (r.type === 'notes') {
              if (formNotes.value) {
                dialogPrompt.value.form.fields[i].value = formNotes.value
                return true
              } else {
                return false
              }
            }
          })
          if (validForms.length !== dialogPrompt.value.form.fields.length) {
            return false
          }
          dialogPrompt.value.resolve({
            response: true,
            form: dialogPrompt.value.form
          })
          dialogPrompt.value.show = false
          return true
        }
      }
      dialogPrompt.value.resolve(t)
    }
    dialogPrompt.value.show = false
  }
}

const toggleEye = ref(false)

watch(() => dialogPrompt.value.show, (v: boolean) => {
  if (v) {
    modelValue.value = null
  }
})
</script>

<template>
  <TransitionRoot appear :show="dialogPrompt.show" as="template">
    <Dialog as="div" @close="Act(null)" class="relative z-[10000000001]">
      <div class="fixed inset-0 bg-black/30 dark:bg-black/30" aria-hidden="true" />
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div v-if="dialogPrompt.show" class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[90%] max-w-[540px] transform overflow-hidden rounded-2xl bg-white dark:bg-midnight-10 dark:text-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-base font-medium leading-6 text-gray-400"
              >
                {{ dialogPrompt.title }}
              </DialogTitle>
              <div v-if="dialogPrompt.description" class="mt-2">
                <p class="text-sm">
                  {{ dialogPrompt.description }}
                </p>
              </div>
              <div v-if="dialogPrompt.form" class="mt-4 mb-8">
                <div class="h-px w-full bg-gray-100 dark:bg-gray-900 mb-4" />
                <div v-for="(f) in dialogPrompt.form.fields" class="mb-4">
                  <div class="text-sm px-0">
                    {{ f.title }}
                  </div>
                  <div v-if="f.type === 'subdistrict'" class="text-sm">
                    <AutocompleteSubdistrict v-model="formSubdistrict" />
                  </div>
                  <div v-if="f.type === 'notes'" class="text-sm">
                    <textarea v-model="formNotes" spellcheck="false" class="block bg-transparent w-full outline-none ml-[2px]" rows="2" placeholder="Write notes" />
                  </div>
                </div>
                <div class="h-px w-full bg-gray-100 dark:bg-gray-900 my-4" />
              </div>

              <div class="flex w-full items-center justify-end mt-4">
                <button
                  v-if="dialogPrompt.act.cancel !== 'disabled'"
                  type="button"
                  class="inline-flex justify-center rounded-full border border-transparent bg-transparent px-4 py-1 text-sm font-medium text-gray-500 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100 focus:outline-none mr-4"
                  @click="Act(null)"
                >
                  {{ dialogPrompt.act.cancel || 'Close' }}
                </button>
                <button
                  type="button"
                  class="inline-flex justify-center rounded-full border border-transparent bg-primary px-4 py-1 text-sm font-medium text-white hover:bg-teal-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="Act('ok')"
                >
                  {{ dialogPrompt.act.ok || 'Confirm' }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
