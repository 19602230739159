<script setup>
const { $Leaflet, $nt, $localDT, $SecondsAgo } = useNuxtApp()
const { auth } = useAuth()
const emit = defineEmits()
const { MyLocation, SetMyLocation } = useMap()

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  modelValue: {
    type: Object,
    default: () => {}
  }
})

const mapSelect = ref(null)
const tileLayerEl = ref(null)
const zoom = ref(5)
const centerPos = ref([-3.0150618,119.5509895])

const MarkerShow = computed(() => {
  return zoom.value > 13
})

watch(() => props.show, (v) => {
  if (v) {
    if (props.modelValue.lat && props.modelValue.lng) {
      const leafletCoord = $Leaflet().latLng(parseFloat(props.modelValue.lat),parseFloat(props.modelValue.lng))
      if (leafletCoord) {
        setTimeout(() => {
          mapSelect.value.leafletObject.flyTo(leafletCoord, 18)
        }, 500)
      }
    }
  }
}, { immediate: true })

function UpdateCenter (v) {
  centerPos.value = v
  emit('selected', v)
}

const CenterLocation = async () => {
  if (MyLocation.value && typeof MyLocation.value === 'object') {
    const leafletCoord = $Leaflet().latLng(MyLocation.value[0],MyLocation.value[1])
    if (leafletCoord) {
      mapSelect.value.leafletObject.flyTo(leafletCoord, 18)
    }
  } else {
    MyLocationLoading.value = true
    const location = await SetMyLocation()
    if (location && typeof location === 'object') {
      const leafletCoord = $Leaflet().latLng(location[0],location[1])
      if (leafletCoord) {
        mapSelect.value.leafletObject.flyTo(leafletCoord, 18)
      }
    }
    MyLocationLoading.value = false
  }
}

const UpdateZoom = (v) => {
  zoom.value = v
}

const MyLocationLoading = ref(false)

</script>

<template>
  <section v-if="props.show" class="warloc-map-select w-full h-full overflow-hidden relative">
    <LMap
      ref="mapSelect"
      :zoom="zoom"
      :min-zoom="4"
      :max-zoom="18"
      :center="centerPos"
      @update:center="UpdateCenter"
      @update:zoom="UpdateZoom"
    >
      <LTileLayer
        ref="tileLayerEl"
        layer-type="base"
        name="Google"
        url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        attribution="&amp;copy; <a href=&quot;https://map.google.com>Google Map</a>"
        :subdomains="['mt0','mt1','mt2','mt3']"
      />
      <l-marker v-if="MarkerShow" :lat-lng="centerPos">
        <l-icon
          :icon-anchor="[35,78]"
          class-name="warloc-marker"
        >
          <div class="warloc-marker-name truncate text-secondary-500">
            Warloc Pin
          </div>
          <span class="warloc-marker-icon" :style="{ backgroundImage: `url(${props.icon || '/warloc-icon.png'})` }" />
        </l-icon>
      </l-marker>
    </LMap>
    <div :class="[MyLocation ? (typeof MyLocation === 'string' ? 'text-red-500' : 'text-primary-500') : 'text-gray-400']" class="absolute bottom-5 right-4 z-[1000] w-8 h-8 rounded-full bg-white dark:bg-gray-900 flex items-center justify-center cursor-pointer shadow-xl" @click.prevent="CenterLocation">
      <svg v-if="MyLocationLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <svg v-else-if="!MyLocation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
        <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
      </svg>
    </div>
  </section>
</template>

<style lang="scss">
.wl-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  .wl-earthquake {
    display: block;
    border-radius: 50%;
    // background: red;
    width: 100%;
    height: 100%;
    // box-shadow: 4px -40px 60px 5px rgb(26, 117, 206) inset;
    &.wl-pulse {
      animation: wl-pulse 1s infinite;
    }
  }
}

@keyframes wl-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.min-h-10 {
  min-height: 2.5rem!important;
}
.warloc-map-select {
  margin: 0px;
  box-sizing: border-box;
  .warloc-marker {
    position: relative!important;
    width: unset!important;
    height: unset!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .warloc-marker-content {
      position: absolute;
      top:25px;
      left: 100%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all .2s ease-in-out;
    }
    &:hover {
      z-index: 1000!important;
      >.warloc-marker-content {
        pointer-events: all;
        opacity: 1;
        visibility: visible;
      }
    }
    >.warloc-marker-name {
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 10px;
      width: 70px;
      max-width: 70px;
      text-align: center;
      background-color: #fafafa;
      border-radius: 20px;
      padding: 1px 2px;
      box-shadow: -3px 3px 7px rgba(0, 0, 0, .3);
      // -webkit-text-stroke: .05rem aqua;
    }
    >.warloc-marker-icon {
      display: flex;
      width: 45px;
      height: 45px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border: solid 3px #17cfa7;
      box-shadow: -3px 3px 7px rgba(0, 0, 0, .3);
      border-radius: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      width: 7px;
      height: 7px;
      border-radius: 8px;
      background-color: #17cfa7;
      box-shadow: -3px 3px 7px rgba(0, 0, 0, .75);
    }
  }
  .leaflet-interactive {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    box-shadow: none;
  }
  .leaflet-container {
    background: #a4ccc6;
  }
  .leaflet-tooltip {
    position: absolute;
    padding: 0px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    /* border: 1px solid transparent;
    border-radius: 3px; */
    color: #FFF;
    text-shadow: 0px 0px 2px #000;
    white-space: nowrap;
    // -webkit-text-stroke: 1px #FFF; /* width and color */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
    font-weight: bold;
    pointer-events: none;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.4); */
  }
  .leaflet-top {
    &.leaflet-top {
      display: none;
    }
  }
  .leaflet-bottom {
    &.leaflet-right {
      display: none;
    }
  }
}
</style>
