export const useChannel = () => {
  const { $JsonRaw }: any = useNuxtApp()

  const showCreateChannel = useState<any>('showCreateChannel', () => false)
  const setShowCreateChannel = (t: boolean) => {
    showCreateChannel.value = t || false
  }

  const channelDetail = useState<any>('channelDetail', () => {
    return {}
  })
  const setChannelDetail = (key: any, data: any) => {
    if (!channelDetail.value) {
      channelDetail.value = {}
    }
    channelDetail.value[key] = data
  }

  const ChannelGetDetail = async (id: string) => {
    const req = await useApiGet(`/channel/detail/${id}`)
    if (req && req.status && req.data && req.data.id) {
      setChannelDetail(req.data.id, $JsonRaw(req.data))
      // setChannelMapSelected(req.data.id, $JsonRaw(req.data))
      // setChannelMapSelected('selected', req.data.id)
      useHead({
        title: (req.data.name || '').substring(0, 75) + ' - Warloc Channel'
      })
      return $JsonRaw(req.data)
    }
    return null
  }
  
  const ChannelData = useState<any>('ChannelData', () => {
    return {
      index: {
        loading: false,
        total: 0,
        data: []
      }
    }
  })
  const ChannelGet = async (key: string, q: string) => {
    if (!ChannelData.value[key]) {
      ChannelData.value[key] = {
        loading: true,
        total: 0,
        data: []
      }
    }
    ChannelData.value[key].loading = true
    const req = await useApiGet(`/channel${q || ''}`)
    if (req && req.status && req.data) {
      const rd = $JsonRaw(req.data)
      ChannelData.value[key].total = parseInt(rd.total) || 0
      ChannelData.value[key].data = rd.data || []
    }
    ChannelData.value[key].loading = false
  }

  const channelMapSelected = useState<any>('channelMapSelected', () => {
    return {
      selected: null
    }
  })
  const setChannelMapSelected = (key: any, data: any) => {
    if (!channelMapSelected.value) {
      channelMapSelected.value = {
        selected: null
      }
    }
    channelMapSelected.value[key] = data || null
  }
  
  const ChannelUsernameCheck = async (channel: string, uname: string) => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/channel/check/${channel}/${uname}`
    return await new Promise(async (resolve: any) => {
      try {
        const username: any = await $fetch(url, {
          method: 'get',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          }
        })
        resolve(username || null)
      } catch {
        resolve(null)
      }
    })
  }

  return {
    ChannelGetDetail,
    ChannelGet,
    ChannelData,
    showCreateChannel,
    setShowCreateChannel,
    channelDetail,
    setChannelDetail,
    channelMapSelected,
    setChannelMapSelected,
    ChannelUsernameCheck
  }
}
