<script setup lang="ts">
const { modalLoading }: any = useWarloc()
</script>

<template>
  <WarlocModal v-if="modalLoading" :show="modalLoading">
    <div class="warloc-loader-container flex items-center justify-center relative w-full h-full bg-gray-50 dark:bg-black">
      <div class="warloc-loader text-center select-none">
        <div id="l16"></div>
      </div>
    </div>
  </WarlocModal>
</template>

<style lang="scss">
.warloc-loader-container {
  .warloc-loader {
    #l16 {
      width: 35px;
      aspect-ratio: 1;
      --c:no-repeat linear-gradient(#000 0 0);
      background: var(--c), var(--c), var(--c);
      animation: 
        l16-1 1s infinite,
        l16-2 1s infinite;
    }
    @keyframes l16-1 {
      0%,100% {background-size:20% 100%}
      33%,66% {background-size:20% 40%}
    }
    @keyframes l16-2 {
      0%,33%   {background-position: 0 0   ,50% 100%,100% 0}
      66%,100% {background-position: 0 100%,50% 0   ,100% 100%}
    }
  }
  &.dark\:bg-black:where(.dark, .dark *) {
    #l16 {
      --c:no-repeat linear-gradient(rgb(255,109, 109) 0 0);
    }
  }
}
</style>