export const useScroll = () => {
  const route = useRoute()
  const scrollData: any = useState<any>('scrollData', () => {
    return {}
  })
  const scrollTop = useState<any>('scrollTop', () => 0)
  const setScrollTop = (t: number) => {
    scrollData[route.fullPath] = t
    scrollTop.value = t || 0
  }

  return {
    scrollTop,
    scrollData,
    setScrollTop
  }
}

export const useWindowSize = () => {
  const windowSize = useState<any>('windowSize', () => {
    return { width: 0, height: 0 }
  })
  const setWindowSize = (t: any) => {
    windowSize.value.width = t?.width || 0
    windowSize.value.height = t?.height
  }

  return {
    windowSize,
    setWindowSize
  }
}
