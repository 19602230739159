<script setup lang="ts">
const { $GoogleDecodeCredential, $generateBrowserID }: any = useNuxtApp()
const { authGoogle } = useAuth()
const { setModalLoading }: any = useWarloc()

const callbackGoogleAuth: any = async (response: any) => {
  // This callback will be triggered when the user selects or login to
  // his Google account from the popup
  setModalLoading(true)
  const userData = $GoogleDecodeCredential(response.credential)
  if (response && response.credential) {
    await authGoogle({
      d: $generateBrowserID(),
      response,
      userData
    })
  }
  setModalLoading(false)
}

</script>

<template>
  <div>
    <GoogleLogin :callback="callbackGoogleAuth"/>
  </div>
</template>