import axios from 'axios'

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig()
  // const dibsx = useCookie('dibsx')
  // const url = useRequestURL()
  // const route = useRoute()
  const baseUrl: string = config.public.baseURL

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      common: {}
    },
    // timeout: 5000,  // Timeout dalam 5 detik
  })
  api.interceptors.request.use(async (config: any) => {
    let value = null
    if (process.client) {
      value = localStorage.getItem('warloc')
    }
    config.headers.Authorization = 'Bearer ' + value
    return config
  }, function (error: any) {
    return Promise.reject(error)
  })

  api.interceptors.response.use(async (response: any) => {
    try {
      const buffer = response.data;
      if (buffer instanceof ArrayBuffer) {
        const decodedData = JSON.parse(new TextDecoder().decode(buffer));
        response.data = decodedData;
      }
    } catch (error) {
      console.error('Error decoding buffer response:', error);
    }
    return response;
  })
return {
    provide: {
      api: api
    }
  }
})

// import axios from 'axios'

// export default defineNuxtPlugin((nuxtApp: any) => {
//   const config = useRuntimeConfig()
//   const baseUrl: string = config.public.baseURL

//   const api = axios.create({
//     baseURL: baseUrl,
//     headers: {
//       common: {}
//     }
//   })

//   // Store abort controllers for active requests
//   const abortControllers = new Map()

//   api.interceptors.request.use(async function (config: any) {
//     let value = null
//     if (process.client) {
//       value = localStorage.getItem('warloc')
//     }
//     config.headers.Authorization = 'Bearer ' + value

//     // Generate unique key for each request
//     const requestKey = `${config.method}:${config.url}`
//     if (abortControllers.has(requestKey)) {
//       // Cancel previous request with the same key
//       abortControllers.get(requestKey).abort()
//     }

//     const abortController = new AbortController()
//     config.signal = abortController.signal
//     abortControllers.set(requestKey, abortController)

//     return config
//   }, function (error: any) {
//     return Promise.reject(error)
//   })

//   api.interceptors.response.use(
//     function (response) {
//       // Remove abort controller after request completes
//       const requestKey = `${response.config.method}:${response.config.url}`
//       abortControllers.delete(requestKey)
//       return response
//     },
//     function (error) {
//       if (axios.isCancel(error)) {
//         console.warn('Request canceled:', error.message)
//       }
//       return Promise.reject(error)
//     }
//   )

//   return {
//     provide: {
//       api: api
//     }
//   }
// })
