<script setup lang="ts">
const { $Chat, $prompt }: any = useNuxtApp()
const { lang, authLoading, auth, authReload, authLogout, setWindowActive }: any = useAuth()
const { splash, setSplash }: any = useSplash()
const { scrollTop }: any = useScroll()
const { setTimelineRepost, setShowCreatePost, setTimelineDetail, TimelineGet }: any = useTimeline()
const { setShowCreateChannel, channelMapSelected, ChannelGet, ChannelGetDetail }: any = useChannel()
const { notificationUnread }: any = useNotification()

const { sidebar, setSidebar }: any = useSidebar()
const { SetMessage, countReads, ChatRoomsGet, chatSyncLoading }: any = useChat()
const { setRoomShow }: any = useRoom()
const route: any = useRoute()
const router: any = useRouter()

let d: any = {}

const warlocSidebar: any = ref([
  {
    title: 'menuHome',
    to: '/',
    class: '',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 md:w-6 md:h-6">
            <path fill-rule="evenodd" d="M12.963 2.286a.75.75 0 0 0-1.071-.136 9.742 9.742 0 0 0-3.539 6.176 7.547 7.547 0 0 1-1.705-1.715.75.75 0 0 0-1.152-.082A9 9 0 1 0 15.68 4.534a7.46 7.46 0 0 1-2.717-2.248ZM15.75 14.25a3.75 3.75 0 1 1-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 0 1 1.925-3.546 3.75 3.75 0 0 1 3.255 3.718Z" clip-rule="evenodd" />
          </svg>`
  },
  {
    title: 'menuWarloc',
    to: '/explore',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6 md:w-6 md:h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>`
  },
  {
    title: 'menuNotification',
    to: '/notification',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 md:w-6 md:h-6">
            <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clip-rule="evenodd" />
          </svg>`
  },
  {
    title: 'menuMaps',
    to: '/maps',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 md:w-6 md:h-6">
            <path fill-rule="evenodd" d="M8.161 2.58a1.875 1.875 0 0 1 1.678 0l4.993 2.498c.106.052.23.052.336 0l3.869-1.935A1.875 1.875 0 0 1 21.75 4.82v12.485c0 .71-.401 1.36-1.037 1.677l-4.875 2.437a1.875 1.875 0 0 1-1.676 0l-4.994-2.497a.375.375 0 0 0-.336 0l-3.868 1.935A1.875 1.875 0 0 1 2.25 19.18V6.695c0-.71.401-1.36 1.036-1.677l4.875-2.437ZM9 6a.75.75 0 0 1 .75.75V15a.75.75 0 0 1-1.5 0V6.75A.75.75 0 0 1 9 6Zm6.75 3a.75.75 0 0 0-1.5 0v8.25a.75.75 0 0 0 1.5 0V9Z" clip-rule="evenodd" />
          </svg>`
  },
  {
    title: 'menuSchedule',
    to: '/schedule',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 md:w-6 md:h-6">
        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
        <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
      </svg>`
  },
  {
    title: 'menuChat',
    to: '/chat',
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 md:w-6 md:h-6">
        <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
        <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
      </svg>`
  }
])

const firstLoad: any = ref(true)
if (firstLoad.value) {
  if (route.name === 'chat-chatid') {
    router.replace('/')
  }
  if (parseInt(route.query.post) && (parseInt(route.query.editor) || parseInt(route.query.edit))) {
    router.go(-2)
  } else if (parseInt(route.query.post)) {
    router.go(-1)
  }
  firstLoad.value = false
}

document.addEventListener("visibilitychange", (e: any) => {
  authReload()
}, false)
window.addEventListener('focus', function () {
  // console.log('window is active!')
})
if (window.matchMedia('(display-mode: standalone)').matches) {
  // PWA INSTALLED ?
}
onBeforeMount(async () => {
  await authReload()
})

watch(() => route.name, (v: any, v2: any) => {
  if (v === 'index' || v === 'notification' || v === 'maps' || v === 'username' || v === 'setting' || v === 'account' || v === 'chat' || v === 'explore' || v === 'schedule') {
    const tt = v.charAt(0).toUpperCase() + v.slice(1);
    useHead({
      title: `${v === 'index' ? 'Warloc' : (v === 'username' ? 'Profile' : tt)} - Level Up : Unlock The Chance`
    })
  }
  if (v2 && v2.name === 'timeline-id') {
    setTimelineDetail(null)
  }
}, { immediate: true, deep: true })

watch(() => route.query, (v: any, v2: any) => {
  setShowCreatePost(false)
  setShowCreateChannel(false)
  setRoomShow(false)
  if (v && parseInt(v.room)) {
    setRoomShow(true)
  }
  if (v && parseInt(v.post)) {
    setShowCreatePost(true)
  }
  if (v && parseInt(v.channel)) {
    setShowCreateChannel(true)
  }
  // console.log(v)
  if (parseInt(v?.chat) || parseInt(v?.post) || parseInt(v?.channel) || parseInt(v?.room) || parseInt(v?.contact) || parseInt(v?.modal)) {
    d.style = 'overflow:hidden;'
  } else {
    d.style = ''
  }
  if (v2 && parseInt(v2.chat) && screen.width > 768) {
    setSidebar(false)
  }
}, { immediate: true })
watch(() => sidebar.value, (v: any) => {
  if (v) {
    d.style = 'overflow:hidden;'
  } else {
    d.style = ''
  }
})

const CreatePostOpen = ref(false)
const authPrepared = computed(() => {
  const a = auth?.value || null
  if (a) {
    if (!a.gender) {
      // if (!a.username) {
      // if (!a.username || !parseInt(a.v1) || !parseInt(a.v2) || !parseInt(a.v3) || !parseInt(a.v4)) {
      return true
    }
  }
  return false
})

const Visibility = () => {
  if (!$Chat.isConnected()) {
    $Chat.Init()
  }
  // console.log('visibility!')
  setWindowActive()
  // if (auth.value) {
  //  ChannelGet('me', '?me=1')
  // }
}

onActivated(() => {
  const theme: any = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('class', theme === 'dark' ? 'dark' : 'light')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('class', 'dark')
  } else {
    document.documentElement.removeAttribute('class')
  }
})

onBeforeMount(() => {
  const theme: any = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('class', theme === 'dark' ? 'dark' : 'light')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('class', 'dark')
  } else {
    document.documentElement.removeAttribute('class')
  }
})
onMounted(() => {
  d = document.body
  if (!$Chat.isConnected()) {
    $Chat.Init()
  }
})

watch(() => auth.value, async (v: any) => {
  if (v) {
    if (!$Chat.isConnected()) {
      $Chat.Init()
    }
    ChannelGet('me', '?me=1')
    ChatRoomsGet(true)
  }
}, { deep: true, immediate: true })

if (process.client) {
  document.addEventListener("visibilitychange", (e: any) => {
    Visibility()
  }, false)
  window.addEventListener('focus', function () {
    // console.log('window is active!')
  })
}

const ReloadChannel = () => {
  ChannelGet('me', '?me=1')
  ChatRoomsGet()
  // ChatSync()
  if (route.params.channelid) {
    ChannelGetDetail(route.params.channelid)
  }
}
const DoLogout = async () => {
  const c = await $prompt({
    title: 'Confirm Logout',
    description: `Are you sure want to logout ?`,
    form: null,
    act: {
      reverse: true,
      ok: 'Logout',
      cancel: 'Cancel'
    }
  })
  if (c) {
    authLogout()
    router.replace('/')
  }
}

const TimelineGetReload = () => {
  TimelineGet('index', `?page=1&limit=25&preference=For You&q=`, false)
}
const scrollCurrentPos = ref(0)
const toggleNav = ref(true)
watch(() => scrollTop.value, (v: any) => {
  if (scrollCurrentPos.value > (v || 0)) {
    toggleNav.value = true
  } else {
    toggleNav.value = false
  }
  scrollCurrentPos.value = v || 0
}, { immediate: true })

</script>

<template>
  <main v-if="auth" :class="scrollTop > 64 ? 'scrolled-64' : ''" class="w-full min-h-full bg-gray-50 dark:bg-[#000]" v-scroll>
    <!-- <div class="pointer-events-none z-[100000000000000] fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <span class="text-5xl lg:text-[4rem] font-medium text-black dark:text-white opacity-5">
        TEST MODE
      </span>
    </div> -->
    <WarlocInitLoader />
    <WarlocModalLoading />
    <WarlocCoinBuy />
    <!-- <Feedback v-if="auth" class="fixed left-6 top-6 z-40" /> -->
    <PageLanding v-if="(!auth || (auth && !auth.gender)) && !(route.name === 'terms-and-conditions' || route.name === 'privacy-policy')" />
    <!-- <PageLanding v-if="(!auth || (auth && !auth.username)) && !(route.name === 'terms-and-conditions' || route.name === 'privacy-policy')" /> -->
    <!-- <GlobalSidebar v-if="route?.name === 'maps' && auth" /> -->
    <GlobalSidebar v-if="auth && route.name !== 'terms-and-conditionsqq' && route.name !== 'privacy-policy'" />
    <channel-create v-if="route?.name === 'maps' && auth && route.name !== 'terms-and-conditions' && route.name !== 'privacy-policy'" @reload="ChannelGet(`map-${channelMapSelected.selected}`, `?pm=${channelMapSelected.selected}`)" />
    <!-- <ClientOnly>
      <ScrollSnap v-if="false" :model-value="sidebar" @update:model-value="setSidebar">
        <SidebarContent @close="setSidebar(false)" />
      </ScrollSnap>
    </ClientOnly> -->
    <section v-if="route.name !== 'terms-and-conditions' && route.name !== 'privacy-policy'" :class="[CreatePostOpen ? '' : 'overflow-y-auto']" class="fixed hidden md:flex flex-col justify-between warloc-sidebar left-0 top-0 scroll-hidden pl-2 pr-2 h-full bg-gradient-to-r from-gray-200 to-gray-50 dark:from-black dark:to-[#151515] py-2 w-[64px] z-[1000]">
      <div class="flex flex-col items-center">
        <NuxtLink to="/" class="hidden text-black dark:text-white grow items-center font-serif font-bold text-[2.3rem] logo-font pl-20 pt-4">
          Warloc
        </NuxtLink>
        <NuxtLink
          v-for="(ws) in warlocSidebar"
          :replace="$route?.name !== 'index'"
          :to="ws.to"
          :class="[ws.class || 'flex']"
          class="warloc-sidebar-item relative flex-col items-center justify-center rounded-xl w-11 h-11 text-center my-2"
        >
          
          <div v-if="ws.title === 'menuHome'" :style="{ backgroundImage: `url(/warloc-icon.png)` }" class="inline-block w-5 h-5 bg-cover-center bg-cover mb-[2px]" />
          <div v-else v-html="ws.icon" class="text-black dark:text-white" />
          <span v-if="ws.title === 'menuChat' && countReads" class="chat-badge absolute flex items-center h-4 rounded-full px-1 -top-1 right-[2px] text-[10px] bg-red-600 text-white transition-all ease-in-out">
            {{ countReads }}
          </span>
          <span v-if="ws.title === 'menuNotification' && parseInt(notificationUnread)" class="chat-badge absolute flex items-center h-4 rounded-full px-1 -top-1 right-[2px] text-[10px] bg-red-600 text-black dark:text-white transition-all ease-in-out">
            {{ notificationUnread }}
          </span>
          <small class="text-[9px] text-black dark:text-white">
            {{ lang[ws.title] }}
          </small>
        </NuxtLink>
      </div>
      <div v-if="auth" class="flex flex-col items-center">
        <NuxtLink to="?post=1" class="warloc-sidebar-item flex flex-col items-center justify-center rounded-xl w-11 h-11 text-center my-2" @click="setTimelineRepost(null)">
          <div class="text-black dark:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="8" fill="currentColor" class="w-5 h-5">
              <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" stroke-width="8" />
            </svg>
          </div>
          <small class="text-[9px] text-black dark:text-white pt-[2px]">
            {{ lang.createPost.menuTitle }}
          </small>
        </NuxtLink>

        <NuxtLink :replace="route?.name !== 'index'" :to="`/${auth?.username || auth?.id}`" class="warloc-sidebar-item flex flex-col items-center justify-center rounded-xl w-12 h-12 text-center my-2" style="opacity:1!important;">
          <div class="rounded-full w-8 h-8">
            <div class="flex items-center justify-center rounded-full w-full h-full bg-warloc-profile p-[2px] bg-white dark:bg-black">
              <div
                class="bg-cover-center bg-gray-200 dark:bg-gray-800 w-full h-full rounded-full border-[1px] border-white dark:border-black"
                :style="{ backgroundImage: `url(${auth.img || '/no-image.jpg'})` }"
              />
            </div>
          </div>
          <small class="text-[9px] text-black dark:text-white pt-[2px]">
            {{ lang.menuProfile }}
          </small>
        </NuxtLink>
        <div v-if="auth" class="h-[1px] bg-gray-400/20 dark:bg-gray-100/20 my-2 w-full" />
        <!-- <div v-if="auth" class="cursor-pointer opacity-1 flex flex-col items-center justify-center rounded-xl w-11 h-11 text-center mt-2">
          <label class="flex items-center w-full min-w-[120px] justify-center cursor-pointer outline-none">
            <input v-model="themeDark" type="checkbox" class="sr-only peer">
            <div class="relative w-7 h-4 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-gray-100 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-700 peer-checked:bg-gray-700"></div>
          </label>
          <small class="text-[9px] text-black dark:text-white mt-[3px]" @click.prevent="themeDark = !themeDark">
            {{ lang.menuTheme }}
          </small>
        </div> -->
        <!-- <div v-if="auth" class="h-[1px] bg-gray-200 dark:bg-gray-700 my-2 w-full" /> -->
        <div v-if="auth" class="cursor-pointer flex flex-col items-center justify-center rounded-xl w-11 h-11 text-center my-4" @click.prevent="DoLogout()">
          <div class="text-gray-600 dark:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-red-700 dark:text-red-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
            </svg>
          </div>
          <small class="text-[9px] text-red-600 dark:text-red-500">
            {{ lang.menuLogout }}
          </small>
        </div>
      </div>
    </section>
    <section
      v-if="route.name !== 'thread-thread' && route.name !== 'chat-chatid' && route.name !== 'terms-and-conditions' && route.name !== 'privacy-policy'"
      :class="[toggleNav ? 'bottom-0' : '-bottom-16']"
      class="warloc-nav-mobile fixed transition-all flex justify-between sm:hidden warloc-sidebar left-0 h-[54px] z-[498] w-full bg-white dark:bg-black text-black dark:text-white border-t border-black/5 dark:border-white/5"
    >
      <NuxtLink v-for="(ws, iWs) in warlocSidebar.filter((r: any) => r.title !== 'menuNotification')" :replace="route?.name !== 'index'" :to="ws.to" :class="[ws.class || 'flex']" class="w-[20%] h-full items-center justify-center" @click="!ws.title ? setTimelineRepost(null) : true">
        <span class="warloc-sidebar-item relative flex flex-col justify-center items-center rounded-xl w-12 h-full text-center">
          <span v-html="ws.icon" class="text-black dark:text-white" />
          <span v-if="ws.title === 'menuChat' && countReads" class="chat-badge absolute flex items-center h-4 rounded-full px-1 top-1 right-[2px] text-[10px] bg-red-600 text-black dark:text-white transition-all ease-in-out">
            {{ countReads }}
          </span>
          <small class="text-[9px] text-gray-900 dark:text-gray-100">
            {{ lang[ws.title] || 'Warloc' }}
          </small>
        </span>
      </NuxtLink>
    </section>
    <section v-if="(auth && !authPrepared) || route.name === 'terms-and-conditions' || route.name === 'privacy-policy'" :class="[route.name !== 'schedule' && route.name !== 'maps' && route.name !== 'chat-chatid' ? 'lg:pr-[64px]' : '']" class="px-0 md:pl-[64px] warloc-page w-full min-h-full">
      <NuxtPage keepalive />
    </section>
    <channel-create v-if="auth && route?.name !== 'maps'" @reload="ReloadChannel()" />
    <timeline-create v-if="auth && route.name" @reload="TimelineGetReload()" />
    <WarlocModalProject />
    <WarlocConfirm />
  </main>
</template>