export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, interactive-widget=resizes-content"},{"charset":"utf-8"},{"name":"theme-color","media":"(prefers-color-scheme: light)","content":"#FFFFFF"},{"name":"theme-color","media":"(prefers-color-scheme: dark)","content":"#101010"},{"name":"background-color","media":"(prefers-color-scheme: light)","content":"#FFFFFF"},{"name":"background-color","media":"(prefers-color-scheme: dark)","content":"#101010"}],"link":[{"rel":"icon","type":"image/png","href":"/warloc-250.png"},{"rel":"manifest","href":"/manifest.webmanifest"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"}],"style":[],"script":[{"src":"/socket.io.js"},{"src":"/turf.min.js"}],"noscript":[],"title":"Warloc: Level Up! Unlock The Chance","charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, interactive-widget=resizes-content"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false