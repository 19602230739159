export const useAuth = () => {
  const { $JsonRaw, $sde, $Chat, $dbm, $generateBrowserID }: any = useNuxtApp()
  const { loadNotifications, notificationData } = useNotification()
  const { authAccess, authAccessRequest } = useAccount()
  const { coinReload } = useCoin()
  const router = useRouter()
  const authLoading = useState<any>('authLoading', () => true)
  const auth = useState<any>('auth', () => null)
  const setAuth = (t: any) => {
    auth.value = t || null
    if (!t) {
      // localStorage.removeItem('warloc')
    }
  }
  const windowActive = useState<any>('windowActive', () => 0)
  const setWindowActive = (t: number) => {
    windowActive.value++
  }

  const lang = computed(() => {
    const lng = auth.value?.pref_language
    if (lng === 'ID') {
      return {
        menuHome: 'Beranda',
        menuNotification: 'Notifikasi',
        menuChat: 'Obrolan',
        menuSchedule: 'Jadwal',
        menuWarloc: 'Jelajah',
        menuMaps: 'Peta',
        menuProfile: 'Profil',
        menuTheme: 'Tema',
        menuLogout: 'Keluar',
        createPost: {
          menuTitle: 'Buat',
          fabTitle: 'Buat Postingan',
          uploadFile: 'Unggah Foto atau Video',
          writeHere: 'Tulis disini',
          createButton: 'Buat Postingan',
          cancelButton: 'Batal'
        },
        page: {
          index: {
            filterForYou: 'Untuk Anda',
            filterClips: 'Klip',
            filterBookmark: 'Markah',
            filterLikes: 'Suka'
          },
          chat: {
            search: 'Cari',
            preparingMessages: 'Menyiapkan Obrolan',
            noRoomSelected: 'Tidak ada ruang obrolan yang dipilih!',
            noMessage: 'Tidak ada pesan!',
            writeHere: 'Tulis pesan ...',
            privateSpace: {
              title: 'Ruang Pribadi',
              caption: 'Hanya anda yang dapat mengakses!'
            },
            menu: {
              reply: 'Balas',
              delete: 'Hapus'
            }
          },
          warloc: {
            exploreWarloc: 'Jelajahi Warloc',
            localResident: 'Kanal Lokal',
            channel: 'Saluran',
            channelCreate: 'Buat Saluran',
            graph: 'Warloc Graph',
            trendChannel: 'Trending Saluran',
            trendTopic: 'Trending Topik'
          },
          maps: {
            search: 'Cari',
            layer: 'Lapisan',
            menu: {
              myChannel: 'Saluran Saya',
              newChannel: 'Buat Saluran'
            }
          }
        }
      }
    }
    return {
      lang: lng,
      menuHome: 'Home',
      menuNotification: 'Notification',
      menuChat: 'Chat',
      menuSchedule: 'Schedule',
      menuWarloc: 'Explore',
      menuMaps: 'Maps',
      menuProfile: 'Profile',
      menuTheme: 'Theme',
      menuLogout: 'Logout',
      createPost: {
        menuTitle: 'Create',
        fabTitle: 'Create Post',
        uploadFile: 'Upload Image or Video',
        writeHere: 'Write here',
        createButton: 'Create Post',
        cancelButton: 'Cancel'
      },
      page: {
        index: {
          filterForYou: 'For You',
          filterClips: 'Clips',
          filterBookmark: 'Bookmark',
          filterLikes: 'Likes'
        },
        chat: {
          search: 'Search',
          preparingMessages: 'Preparing messages',
          noRoomSelected: 'No chatroom selected!',
          noMessage: 'No messages!',
          writeHere: 'Write message ...',
          privateSpace: {
            title: 'Private Space',
            caption: 'Only you can access!'
          },
          menu: {
            reply: 'Reply',
            delete: 'Delete'
          }
        },
        warloc: {
          exploreWarloc: 'Explore Warloc',
          localResident: 'Local Resident',
          channel: 'Channel',
          channelCreate: 'Create channel',
          graph: 'Warloc Graph',
          trendChannel: 'Channel Trends',
          trendTopic: 'Topic Trends'
        },
        maps: {
          search: 'Search',
          layer: 'Layer',
          menu: {
            myChannel: 'My Channel',
            newChannel: 'Create Channel'
          }
        }
      }
    }
  })

  const authReload = async () => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/account/w`
    try {
      const account: any = await $fetch(url, {
        method: 'get',
        server: false,
        // @ts-ignore
        headers: {
          'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
        }
      })

      if (account && account.a) {
        const a = await $sde(account.a)
        if (!auth.value) {
          loadNotifications()
        }
        if (a && a.x) {
          const x = {
            id: a.x,
            username: a.u,
            name: a.n,
            email: a.e,
            gender: a.g,
            birthday: a.b,
            pref_language: a.lng,
            followings: a.followings,
            followers: a.followers,
            warloc_cake: parseInt(a.ck) || 0,
            img: a.i,
            v0: a.l[0] || 0,
            v1: a.l[1] || 0,
            v2: a.l[2] || 0,
            v3: a.l[3] || 0,
            v4: a.l[4] || 0,
            v5: a.l[5] || 0,
            v6: a.l[6] || 0,
            v00: a.l[0] || 0,
            v11: a.l[1] || 0,
            v22: a.l[2] || 0,
            v33: a.l[3] || 0,
            v44: a.l[4] || 0,
            v55: a.l[55] || 0,
            v66: a.l[66] || 0
          }
          setAuth(x)
          await $dbm.connect()
        } else {
          setAuth(null)
        }
      } else {
        setAuth(null)
      }
      authAccessRequest()
      coinReload()
    } catch {}
    if (authLoading.value) {
      setTimeout(() => {
        authLoading.value = false
      }, 750)
    }
  }

  const AccountGet = async (uname: string) => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/account/${uname}`
    return await new Promise(async (resolve: any) => {
      try {
        const account: any = await $fetch(url, {
          method: 'get',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          }
        })
        resolve(account || null)
      } catch {
        resolve(null)
      }
    })
  }

  const UsernameCheck = async (uname: string) => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/check/${uname}`
    return await new Promise(async (resolve: any) => {
      try {
        const username: any = await $fetch(url, {
          method: 'get',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          }
        })
        resolve(username || null)
      } catch {
        resolve(null)
      }
    })
  }

  const LocationSearch = async (uname: string) => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/map/search?q=${uname}&page=0&limit=30`
    return await new Promise(async (resolve: any) => {
      try {
        const area: any = await $fetch(url, {
          method: 'get',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          }
        })
        resolve(area || null)
      } catch {
        resolve(null)
      }
    })
  }

  const authGoogle = async (body: any) => {
    const config = useRuntimeConfig()

    authLoading.value = true
    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/auth/google`
    const response: any = await $fetch(url, {
      method: 'post',
      body
    })
    if (response && response.response && response.access_token) {
      localStorage.setItem('warloc', response.access_token)
      authReload()
      router.replace('/')
    }
    setTimeout(() => {
      authLoading.value = false
    }, 500)
    return await response
  }

  const authRegister = async (body: any) => {
    const config = useRuntimeConfig()
  
    authLoading.value = true
    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/auth/register`
    const response: any = await $fetch(url, {
      method: 'post',
      server: false,
      // @ts-ignore
      headers: {
        'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
      },
      body
    })
    if (response && response.response && response.access_token) {
      localStorage.setItem('warloc', response.access_token)
      authReload()
    }
    authLoading.value = false
    return await response
  }

  const authUpdate = async (body: any) => {
    const config = useRuntimeConfig()
  
    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/auth/update`
    const response: any = await $fetch(url, {
      method: 'post',
      server: false,
      // @ts-ignore
      headers: {
        'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
      },
      body
    })
    if (response) {
      authReload()
    }
    return await response
  }

  const authLogout = async () => {
    notificationData.value.loading = true
    notificationData.value.data = []
    await UnsubscribePush()
    router.replace('/')
    setAuth(null)
    $Chat.disconnect()
    localStorage.removeItem('warloc')
    localStorage.removeItem('s')
    localStorage.removeItem('theme')
    await $dbm.clear()
    await $dbm.close()
  }

  const SubscribePush = async (body: string) => {
    const bid = $generateBrowserID()
    const payload = {
      subscription: body,
      device: bid
    }
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/notification/subscribe`
    return await new Promise(async (resolve: any) => {
      try {
        const r: any = await $fetch(url, {
          method: 'post',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          },
          body: payload
        })
        resolve(r || null)
      } catch {
        resolve(null)
      }
    })
  }
  
  const UnsubscribePush = async () => {
    const config = useRuntimeConfig()

    const baseUrl: any = config.public.baseURL
    const url = `${baseUrl}/notification/unsubscribe`
    return await new Promise(async (resolve: any) => {
      try {
        const r: any = await $fetch(url, {
          method: 'post',
          server: false,
          // @ts-ignore
          headers: {
            'Authorization': 'Bearer ' + (localStorage.getItem('warloc') || '')
          },
          body: {
            device: $generateBrowserID()
          }
        })
        resolve(r || null)
      } catch {
        resolve(null)
      }
    })
  }


  const authInterests = useState<any>('authInterests', () => ['Design', 'Technology', 'Business', 'Economic', 'Shopping', 'Arts', 'History', 'Finance', 'Movie', 'Education', 'Music', 'Politic', 'Culture', 'Society', 'K-Pop', 'K-Drama'])

  return {
    authRegister,
    authUpdate,
    authInterests,
    LocationSearch,
    UsernameCheck,
    AccountGet,
    authLoading,
    auth,
    setAuth,
    authReload,
    authGoogle,
    authLogout,
    windowActive,
    setWindowActive,
    lang,
    SubscribePush
  }
}
