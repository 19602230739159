<script setup lang="ts">
const emit = defineEmits()
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  }
})

const warlocModal: any = ref(null)
const closeModal = () => {
  warlocModal.value.classList.add('animate-out');
  warlocModal.value.addEventListener('animationend', () => {
    warlocModal.value.style.display = 'none'
    warlocModal.value.classList.remove('animate-out')
    emit('close', true)
  }, { once: true })
}

watch(() => props.show, (v: boolean) => {
  if (v) {
    setTimeout(() => {
      warlocModal.value.style.display = "flex";
    }, 300)
  } else {
    closeModal()
  }
})
</script>
<template>
  <div ref="warlocModal" v-if="props.show" class="warloc-modal justify-center items-center flex-col scrollbar-mini">
    <slot />
  </div>
</template>

<style lang="scss">
.warloc-modal {
  /* The Modal (background) */
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  &:not(.w-screen) {
    // padding-left: 4rem;
    height: 100%;
    width: 100%;
    overflow: auto;
    // background-color: rgb(0, 0, 0);
    // background-color: rgba(0, 0, 0, 0.4);
    animation-name: fadeIn;
    animation-duration: 0.05s;
    // transform-origin: translateX(100% 100%);
  }

  &.animate-out {
    animation-name: fadeOut;
  }

  >.modal-content {
    position: fixed;
    bottom: 0;
    width: 100%;
    animation-duration: 0.15s;
    height: 100vh;
    opacity: 1;
    &.rtl {
      animation-name: slideInModalRight;
    }
    &.btt {
      animation-name: slideInModalBottom;
    }
  }

  &.animate-out {
    >.modal-content {
      animation-duration: 0.15s;
      &.rtl {
        animation-name: slideOutModalRight;
      }
      &.btt {
        animation-name: slideOutModalBottom;
      }
    }
    &:not(.w-screen) {
      >.modal-content {
        opacity: 0;
      }
    }
  }
}
@keyframes slideInModalRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutModalRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideInModalBottom {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutModalBottom {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeInModal {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeOutModal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>