<template>
  <div
    class="overflow-x-scroll whitespace-nowrap scrollbar-hide select-none touch-pan-y"
    ref="scrollContainer"
    @mousedown="startDrag"
    @touchstart="startDrag"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="inline-block text-white mr-[2px] cursor-pointer"
    >
      <div class="relative overflow-hidden">
        <slot :item="item" :index="index">{{ item }}</slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const scrollContainer = ref(null);
let isDragging = false;
let startX = 0;
let scrollLeft = 0;
let startY = 0;
let isHorizontalScroll = false;

// Menginisialisasi event listeners untuk drag
const startDrag = (e) => {
  const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;
  if (!isTouchDevice) {
    e.preventDefault(); // Mencegah scroll default pada touch devices
  }
  isDragging = true;
  startX = e.pageX || e.touches[0].pageX;
  startY = e.pageY || e.touches[0].pageY;
  scrollLeft = scrollContainer.value.scrollLeft;
  isHorizontalScroll = false;
  
  // Menambahkan event listeners pada window
  window.addEventListener('mousemove', drag);
  window.addEventListener('mouseup', endDrag);
  window.addEventListener('touchmove', drag);
  window.addEventListener('touchend', endDrag);
};

// Fungsi untuk menghentikan drag
const endDrag = () => {
  isDragging = false;
  isHorizontalScroll = false;
  // Membersihkan event listeners pada window
  window.removeEventListener('mousemove', drag);
  window.removeEventListener('mouseup', endDrag);
  window.removeEventListener('touchmove', drag);
  window.removeEventListener('touchend', endDrag);
};

// Fungsi untuk melakukan drag
const drag = (e) => {
  if (!isDragging) return;

  const x = e.pageX || e.touches[0].pageX;
  const y = e.pageY || e.touches[0].pageY;

  // Tentukan apakah gerakan horizontal lebih dominan dari vertikal
  if (!isHorizontalScroll) {
    const deltaX = Math.abs(x - startX);
    const deltaY = Math.abs(y - startY);
    if (deltaX > deltaY) {
      isHorizontalScroll = true;
    } else {
      endDrag();
      return;
    }
  }

  // Jika horizontal scroll diaktifkan, lakukan scroll horizontal
  const walk = (x - startX) * 1.5; // Sesuaikan kecepatan
  scrollContainer.value.scrollLeft = scrollLeft - walk;
};

onBeforeUnmount(() => {
  // Bersihkan event listeners saat komponen dilepaskan
  endDrag();
});
</script>

<style>
/* Hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
