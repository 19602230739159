export const useMap = () => {
  const MyLocation: any = useState<any>('MyLocation', () => 0)
  const SetMyLocation = async () => {
    return await new Promise((resolve: any) => {
      if (navigator.geolocation) {
        function showPosition (position: any) {
          MyLocation.value = [position.coords.latitude, position.coords.longitude]
          resolve([position.coords.latitude, position.coords.longitude])
          // alert(`${position.coords.latitude}|${position.coords.longitude}|${(position.coords.accuracy || '?')}`)
        }
        
        function showError (error: any) {
          switch(error.code) {
            case error.PERMISSION_DENIED:
              MyLocation.value = "User denied the request for Geolocation."
              resolve("User denied the request for Geolocation.")
              break;
            case error.POSITION_UNAVAILABLE:
              MyLocation.value = "Location information is unavailable."
              resolve("Location information is unavailable.")
              break;
            case error.TIMEOUT:
              MyLocation.value = "The request to get user location timed out."
              resolve("The request to get user location timed out.")
              break;
            case error.UNKNOWN_ERROR:
              MyLocation.value = "An unknown error occurred."
              resolve("An unknown error occurred.")
              break;
            default:
              MyLocation.value = error.code
              resolve("Something went wrong!")
              break;
          }
        }
        navigator.geolocation.getCurrentPosition(showPosition,showError,{maximumAge:60000,enableHighAccuracy: true,timeout:10000})
      } else {
        MyLocation.value = 0
        resolve(0)
      }
    })
  }
  return {
    MyLocation,
    SetMyLocation
  }
}
