export const useChatFormatter = () => {
  const chatText = ref('');

  // Fungsi untuk menambahkan bold, ita;lic, atau bold-italic pada teks yang dipilih
  function wrapSelectedText(wrapperChar: any) {
    const textarea: any = document.activeElement; // Mengambil elemen aktif (textarea)
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start !== end) {
      const selectedText = textarea.value.substring(start, end);
      const beforeText = textarea.value.substring(0, start);
      const afterText = textarea.value.substring(end);

      // Wrap text yang dipilih dengan karakter wrapper
      textarea.value = `${beforeText}${wrapperChar}${selectedText}${wrapperChar}${afterText}`;

      // Kembalikan focus ke textarea dan atur posisi kursor setelah wrap
      textarea.focus();
      textarea.setSelectionRange(start + 1, end + 1); // Menyesuaikan panjang wrapper
      chatText.value = textarea.value;
      return textarea.value
    }
  }

  // Fungsi untuk mengonversi teks menjadi bold, italic, atau bold-italic
  function formatText(text: string) {
    text = text.replace(/\*_(.*?)_\*/g, '<strong><em>$1</em></strong>');  // Bold italic
    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');  // Bold
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');  // Italic
    text = text.replace(/{{(.*?):(.*?)}}/g, (_: any, key: any, value: any) => {
      console.log(_, key, value)
      return `<span text="${value.trim()}">${key.trim()}</span>`;
    });
    return text;
  }

  // Fungsi untuk mendeteksi URL dan mengubahnya menjadi clickable link
  function convertTextToLinks(text: string) {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  // Gabungkan formatText dan convertTextToLinks
  function formatAndLinkText(text: string) {
    let formattedText = formatText(text);  // Konversi bold dan italic
    return convertTextToLinks(formattedText);  // Konversi link
  }

  // Fungsi untuk menambahkan keyboard shortcut Ctrl+B dan Ctrl+I
  function handleKeyboardShortcut(event: any) {
    if (event.ctrlKey && event.key === 'b') {
      event.preventDefault();  // Mencegah fungsi default Ctrl+B browser
      return wrapSelectedText('*');   // Tambahkan asterisk untuk bold
    }

    if (event.ctrlKey && event.key === 'i') {
      event.preventDefault();  // Mencegah fungsi default Ctrl+I browser
      return wrapSelectedText('_');   // Tambahkan underscore untuk italic
    }
  }

  // Event listener untuk textarea keyboard input
  function addKeyboardListener(textarea: any) {
    textarea.addEventListener('keydown', handleKeyboardShortcut);
  }

  const attachedFile: any = ref(null);  // Menyimpan file yang diunggah
  const filePreview: any = ref(null);   // Menyimpan preview file

  // Fungsi untuk menangani file upload
  function formatFileSize(size: number) {
    const kbSize = size / 1024;
    if (kbSize > 1024) {
      return (kbSize / 1024).toFixed(2) + ' MB';
    }
    return kbSize.toFixed(2) + ' KB';
  }

  function handleFileUpload (event: any) {
    const file = event.target.files[0];
    if (file) {
      attachedFile.value = file;
      const fileSize = formatFileSize(file.size); // Hitung ukuran file

      // Preview untuk gambar
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          filePreview.value = { isFile: true, name: file.name, size: file.size, sizeF: fileSize, value: e.target.result }
        };
        reader.readAsDataURL(file);
      } else if (file.type === 'application/pdf') {
        filePreview.value = { isFile: true, size: file.size, sizeF: fileSize, name: file.name }
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        filePreview.value = { isFile: true, size: file.size, sizeF: fileSize, name: file.name }
      } else if (file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        filePreview.value = { isFile: true, size: file.size, sizeF: fileSize, name: file.name }
      } else {
        filePreview.value = { isFile: true, size: file.size, sizeF: fileSize, name: file.name }
      }
    }
  }

  function resetFileInput () {
    attachedFile.value = null;
    filePreview.value = null;
  }

  return {
    handleKeyboardShortcut,
    chatText,
    formatAndLinkText,
    addKeyboardListener,
    attachedFile,
    filePreview,
    handleFileUpload,
    resetFileInput
  }
}
