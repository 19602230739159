<script setup lang="ts">
const router: any = useRouter()
const route: any = useRoute()

const { modalProject, setModalProject }: any = useWarloc()
</script>

<template>
  <WarlocModal v-if="parseInt(route.query.modal)" :show="modalProject.show">
    <!-- text-black dark:text-white bg-gray-50 dark:bg-midnight-10  -->
    <div class="relative w-full h-full bg-black/80">
      <div class="modal-content mx-auto flex flex-col h-full w-full max-w-[720px] min-h-screen" @click.stop>
        <div class="flex items-center justify-between h-[54px] px-5">
          <h2 class="font-serif text-lg text-black dark:text-white"></h2>
          <span class="close text-4xl cursor-pointer text-white" @click="router.back()">&times;</span>
        </div>

        <div v-if="modalProject.active && modalProject.data" class="flex justify-center items-center grow w-full h-full px-4 pb-4 pt-4">
          <v-carousel
            :model-value="modalProject.data.findIndex((itm: any) => itm.src === modalProject.active.src)"
            hide-delimiters
            :show-arrows="false"
            height="100%"
            @click="router.back()"
          >
            <v-carousel-item
              v-for="(item, i) in modalProject.data"
              :key="'modal-item-' + i"
              :value="i"
              cover
            >
              <div class="relative h-full flex items-center w-full">
                <img class="block w-full pointer-events-none" :src="item.src" @contextmenu.prevent>
              </div>
            </v-carousel-item>
          </v-carousel>
          <!-- <img :src="modalProject.active.src" class="block w-full"> -->
        </div>
        
        <div class="flex items-center justify-between h-[54px]" />

      </div>
    </div>
  </WarlocModal>
</template>