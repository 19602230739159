<script setup lang="ts">
const { authReload, authLoading }: any = useAuth()

document.addEventListener("visibilitychange", (e: any) => {
  authReload()
}, false)
window.addEventListener('focus', function () {
  // console.log('window is active!')
})

onActivated(() => {
  const theme: any = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('class', theme === 'dark' ? 'dark' : 'light')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('class', 'dark')
  } else {
    document.documentElement.removeAttribute('class')
  }
})

onMounted(async () => {
  const theme: any = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('class', theme === 'dark' ? 'dark' : 'light')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('class', 'dark')
  } else {
    document.documentElement.removeAttribute('class')
  }
  await authReload()
})

const textTitle = ref(null)
const setTitle = (t: any) => {
  textTitle.value = null
  if (t) {
    textTitle.value = t
  }
}
</script>

<template>
  <div class="w-screen min-h-screen">
    <WarlocInitLoader />
    <div
      v-if="!($route.name === 'terms-and-conditions' || $route.name === 'privacy-policy')"
      class="relative bg-[#fafafa] dark:bg-black min-h-screen h-auto lg:h-screen w-screen lg:py-0 flex flex-col overflow-hidden"
    >
      <div class="fixed top-0 left-0 w-screen h-screen z-10 px-10 lg:px-20 xl:px-40 pb-10 md:pb-40 lg:pb-0 flex items-center justify-center">
        <Map @updated="setTitle" />
      </div>
      <IconWarlocApps
        class="absolute -top-[110px] -right-[250px] md:-top-[300px] md:-right-[50px] w-[450px] md:w-[450px] lg:w-[450px] opacity-20 md:opacity-40 -rotate-45"
      />
      <!-- <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke-red-700 absolute -bottom-20 left-0 w-[250px] opacity-40">
        <path d="M3 12H7L9 19L12 5L15 17L17 12H21" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->
      <div class="w-full self-baseline h-[64px] flex items-center justify-between z-10">
        <div class="flex items-center text-2xl py-4 px-8">
          <img src="/warloc-icon.png" class="block rounded-md w-7 mr-3">
          <span class="block text-warloc-gradient font-semibold">Warloc</span>
        </div>
      </div>
      <div class="grow flex flex-col lg:flex-row items-center py-8 md:py-16 justify-end lg:justify-start z-20 pointer-events-none">
        <div class="text-black dark:text-white/90 w-full self-stretch flex flex-col items-start justify-center px-14 md:px-40">
          <div class="font-bold text-3xl md:text-5xl lg:text-6xl outlined-line">
            Collaborate<span class="text-accent">.</span><br>
            Innovate<span class="text-accent">.</span> Elevate<span class="text-accent">.</span>
          </div>
          <div class="lg:pl-[6px]">
            <div class="pt-2 pb-2 my-8 font-thin transition-all ease-out text-lg md:text-xl pl-4 border-l-2 border-black/30 dark:border-white/50 min-w-[320px] w-auto">
              <div>
                <div class="tracking-wider flex items-center flex-nowrap whitespace-nowrap">
                  <strong>Level Up{{ textTitle ? ':' : '!' }}</strong> <span :class="[textTitle ? (textTitle === 'Unlock The Chance.' ? 'transition-w' : '') : 'transition-w active']" class="inline-block whitespace-nowrap pl-1">{{ textTitle || 'Unlock The Chance.' }}</span>
                </div>
              </div>
            </div>
            <div v-if="authLoading" class="pl-4 flex items-center justify-start relative overflow-hidden text-black/50 dark:text-white/50 tracking-widest text-sm">
              <svg class="animate-spin h-4 w-4 text-black/70 dark:text-white/70 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="5"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Please wait ...
            </div>
            <div v-else class="relative overflow-hidden pointer-events-auto">
              <span class="absolute left-0 top-0 inline-block px-10 py-2 h-[40px] -ml-[2px] rounded-l-md rounded-r-3xl bg-warloc-profile font-bold text-white cursor-pointer z-10 pointer-events-none min-w-[186px]">
                Just In !
              </span>
              <Auth class="rounded-full overflow-hidden" />
            </div>
          </div>
        </div>
        <!-- <div class="w-full lg:w-1/2 self-stretch">
        </div> -->
      </div>
      <div class="md:self-end md:w-auto text-warloc-gradient flex flex-row items-center md:items-center justify-center md:justify-end px-1 md:px-8 py-4 md:py-4 text-xs md:text-sm z-10">
        <a href="#" class="mx-1 md:mx-2">About Us</a>&sdot;
        <a href="#" class="mx-1 md:mx-2">Contact Us</a>&sdot;
        <NuxtLink href="/terms-and-conditions" target="_blank" class="mx-1 md:mx-2">Terms & Conditions</NuxtLink>&sdot;
        <NuxtLink href="/privacy-policy" target="_blank" class="mx-1 md:mx-2">Privacy Policy</NuxtLink>
      </div>
      <!-- By clicking the login authentication button below, you have agreed to our <a href="/privacy-policy" target="_blank" class="underline italic">privacy policy</a>, as well as our <a href="/terms-and-conditions" target="_blank" class="underline italic">terms and conditions</a>. -->
    </div>
    <NuxtPage v-else />
  </div>
</template>

<style class="scss">
html {
  .outlined-line {
    @media screen and (max-width:768px) {
      -webkit-filter: drop-shadow(1px 1px 0 white)
                      drop-shadow(-1px 1px 0 white)
                      drop-shadow(1px -1px 0 white)
                      drop-shadow(-1px -1px 0 white);

      filter: drop-shadow(1px 1px 0 white)
              drop-shadow(-1px 1px 0 white)
              drop-shadow(1px -1px 0 white)
              drop-shadow(-1px -1px 0 white); 
    }
  }
  &.dark {
    .outlined-line {
      @media screen and (max-width:768px) {
        -webkit-filter: drop-shadow(1px 1px 0 black)
                        drop-shadow(-1px 1px 0 black)
                        drop-shadow(1px -1px 0 black)
                        drop-shadow(-1px -1px 0 black);

        filter: drop-shadow(1px 1px 0 black)
                drop-shadow(-1px 1px 0 black)
                drop-shadow(1px -1px 0 black)
                drop-shadow(-1px -1px 0 black); 
      }
    }
  }
}

.transition-w {
  width: 1px!important;
  overflow-x: hidden;
  transition: all 1s ease-in-out!important;
  &.active {
    width: 200px!important;
  }
}
</style>