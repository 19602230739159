<script setup lang="ts">
const { lang, authLoading, auth, authReload, authLogout, setWindowActive }: any = useAuth()
const route: any = useRoute()
const router: any = useRouter()
const authPrepared = computed(() => {
  const a = auth?.value || null
  if (a) {
    if (!a.username) {
      // if (!a.username || !parseInt(a.v1) || !parseInt(a.v2) || !parseInt(a.v3) || !parseInt(a.v4)) {
      return true
    }
  }
  return false
})


const authShow = ref(false)

watch(() => route.query, () => {
  authShow.value = false
  if (parseInt(route.query.auth)) {
    authShow.value = true
  }
}, { deep: true })
</script>
<template>
  <div class="page-landing min-h-screen bg-white fixed top-0 left-0 w-screen h-screen z-[1000000000000000] overflow-auto scrollbar-mini">
    <!-- Minimal Navigation -->
    <nav class="fixed w-full bg-white/50 backdrop-blur-md z-50">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16 items-center">
          <div class="flex items-center gap-2">
            <!-- <div class="w-10 h-10 rounded-xl bg-cover flex items-center justify-center" style="background-image: url(/warloc-icon-round.png)" /> -->
            <span class="text-3xl font-black bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700 logo-font">
              Warloc
            </span>
          </div>
          
          <div class="flex items-center gap-4">
            <span class="px-5 py-2 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-xl hover:bg-green-800 transition-all font-medium cursor-pointer" @click="router.push('?auth=1')">
              Let's Join
            </span>
          </div>
        </div>
      </div>
    </nav>

    <!-- Hero Section -->
    <div class="pt-32 pb-20 px-4 bg-gradient-to-b from-gray-50 via-gray-100 to-gray-200 text-black">
      <div class="max-w-6xl mx-auto">
        <div class="text-center space-y-6">
          <h1 class="text-4xl md:text-7xl font-bold">
            <span class="logo-font">
              Warloc
            </span>
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-green-600">
              Super App
            </span>
          </h1>
          <p class="text-base md:text-xl text-gray-600 px-8 max-w-2xl mx-auto font-thin pt-0 pb-8">
            Connect, Elevate, Collaborate with Warloc Digital Life
          </p>
          <div class="flex justify-center gap-4 pt-4">
            <button class="px-8 py-3 bg-green-500 text-white rounded-xl hover:bg-green-600 transition-all font-bold shadow-lg" @click="router.push('?auth=1')">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Features Grid -->
    <div class="py-8 md:py-10 lg:py-20 bg-gray-50">
      <div class="max-w-6xl mx-auto px-4">
        <div class="grid md:grid-cols-2 gap-8">
          <!-- Social Card -->
          <div class="bg-white p-8 rounded-2xl hover:shadow-xl transition-all group cursor-pointer">
            <div class="w-14 h-14 bg-green-100 rounded-xl flex items-center justify-center mb-6 group-hover:bg-green-500 transition-all">
              <svg class="w-7 h-7 text-green-500 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a2 2 0 01-2-2v-6a2 2 0 012-2h8z" />
              </svg>
            </div>
            <h3 class="text-2xl font-bold mb-4">Social Space</h3>
            <p class="text-gray-600 text-lg">Share moments, connect with friends, and build your community. All in your style.</p>
          </div>

          <!-- Collaboration Card -->
          <div class="bg-white p-8 rounded-2xl hover:shadow-xl transition-all group cursor-pointer">
            <div class="w-14 h-14 bg-green-100 rounded-xl flex items-center justify-center mb-6 group-hover:bg-green-500 transition-all">
              <svg class="w-7 h-7 text-green-500 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
            <h3 class="text-2xl font-bold mb-4">Collaborate</h3>
            <p class="text-gray-600 text-lg">Collaborate on projects, manage teams, share files, and get things done together. Easy peasy.</p>
          </div>

          <!-- Chat Card -->
          <div class="bg-white p-8 rounded-2xl hover:shadow-xl transition-all group cursor-pointer">
            <div class="w-14 h-14 bg-green-100 rounded-xl flex items-center justify-center mb-6 group-hover:bg-green-500 transition-all">
              <svg
                  class="w-7 h-7 text-green-500 group-hover:text-white" 
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <!-- Handle -->
                  <path
                      d="M8 10L12 2L16 10"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <!-- Basket body -->
                  <path
                      d="M3 10H21L20 21H4L3 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <!-- Wheels or bottom circles -->
                  <circle cx="8" cy="19" r="1" fill="currentColor" />
                  <circle cx="16" cy="19" r="1" fill="currentColor" />
              </svg>

            </div>
            <h3 class="text-2xl font-bold mb-4">Social Commerce</h3>
            <p class="text-gray-600 text-lg">Where Shopping Meets Connection. Explore, Connect, Shop Together, Shop Smarter!</p>
          </div>

          <!-- Maps Card -->
          <div class="bg-white p-8 rounded-2xl hover:shadow-xl transition-all group cursor-pointer">
            <div class="w-14 h-14 bg-green-100 rounded-xl flex items-center justify-center mb-6 group-hover:bg-green-500 transition-all">
              <svg class="w-7 h-7 text-green-500 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <h3 class="text-2xl font-bold mb-4">Interactive Map</h3>
            <p class="text-gray-600 text-lg">Discover cool spots, meet friends nearby, and never get lost with smart maps.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Social Proof -->
    <div class="py-20">
      <div class="max-w-6xl mx-auto px-4 text-center">
        <h2 class="text-xl md:text-3xl font-bold mb-12">- Loved by people like you -</h2>
        <div class="flex justify-center gap-8 flex-wrap">
          <div class="text-4xl font-bold">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700">10M+</span>
            <p class="text-base text-gray-600 font-normal mt-2">Active Users</p>
          </div>
          <div class="text-4xl font-bold">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700">4.9</span>
            <p class="text-base text-gray-600 font-normal mt-2">App Rating</p>
          </div>
          <div class="text-4xl font-bold">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700">80k+</span>
            <p class="text-base text-gray-600 font-normal mt-2">Districts</p>
          </div>
        </div>
      </div>
    </div>

    <!-- CTA -->
    <div class="bg-gradient-to-r from-green-500 to-green-700 text-white py-20">
      <div class="max-w-6xl mx-auto px-4 text-center">
        <h2 class="text-2xl md:text-4xl font-bold mb-6">Excited to dive into the excitement?</h2>
        <p class="text-md md:text-lg text-gray-200 mb-8 max-w-xl mx-auto pb-8">
          Join Warloc to connect, explore, and collaborate across Indonesia through an interactive & dynamic map experience!
        </p>
        <button class="px-8 py-4 bg-gradient-to-r from-green-500 to-green-700 hover:bg-green-600 text-white rounded-xl transition-all font-medium text-lg" @click="router.push('?auth=1')">
          Join for Free →
        </button>
      </div>
    </div>

    <!-- Simple Footer -->
    <footer class="py-10 bg-white">
      <div class="max-w-6xl mx-auto px-4">
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-2">
            <span class="text-2xl font-black bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700 logo-font">
              Warloc
            </span>
          </div>
          <div class="text-xs text-gray-400 tracking-widest">
            WARLOC © 2024
          </div>
        </div>
      </div>
    </footer>
    <SplashScreen v-if="(!auth || authLoading || authPrepared) && route.name !== 'terms-and-conditions' && route.name !== 'privacy-policy'" @logout="authLogout" />
    <ToastAcceptCookies v-if="(!auth || authLoading || authPrepared) && route.name !== 'terms-and-conditions' && route.name !== 'privacy-policy'" />
  </div>
</template>

<style lang="scss">
.page-landing {
  &.scrollbar-mini {
    @media screen and (max-width: 767px) {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */ 
    }
  }
}
</style>